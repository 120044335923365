import React from "react";
import clsx from "clsx";
import { CommonComponentProps } from "../types";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<CommonComponentProps<InputProps>> = ({
    className,
    style,
    ...other
}: CommonComponentProps<InputProps>) => {
    const classes = clsx("form-input", {
        [className ?? ""]: !!className,
    });

    return <input className={classes} style={style} type="text" {...other} />;
};

export default Input;
