import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateProductReviewDto {
    @Expose()
    product: number;

    @Expose()
    rates: number;

    @Expose()
    text: string;
}
