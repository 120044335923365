import { PageType } from "@/modules/pages/registry";
import { Exclude, Type, Expose, Transform } from "class-transformer";
import Breadcrumb from "./Breadcrumb.model";
import Component from "./Component.model";

@Exclude()
class EntityTypes {
    @Expose() id: number;

    @Expose() title: string;

    @Expose({ name: "entity_type_id" }) entityTypeId: number;
}

class Attr {
    [key: string]: any;
}

@Exclude()
class InnerContent {
    @Expose()
    title: string;

    @Expose({ name: "seo_og_type" }) seoOgType: string;

    @Expose({ name: "seo_title" }) seoTitle: string;

    @Expose({ name: "seo_keywords" }) seoKeywords: string;

    @Expose({ name: "seo_author" }) seoAuthor: string;

    @Expose({ name: "seo_description" }) seoDescription: string;

    @Expose({ name: "seo_image" }) seoImage: string;
}

@Exclude()
class PageContext {
    @Expose() id: number;

    @Expose() title: string;

    @Expose() slug: string;
    @Expose() attr: Attr;
    @Expose({ name: "page_type" }) pageType: PageType;

    @Expose() parent: number | null;

    @Type(() => Breadcrumb)
    @Expose()
    breadcrumbs: Breadcrumb[];

    @Type(() => Component)
    @Expose()
    components: Component[];

    @Type(() => InnerContent)
    @Expose()
    context: InnerContent;

    @Expose()
    @Transform(({ obj }) => {
        return obj?.context?.sale_price ?? 0;
    })
    price: number;

    @Expose() content: string;

    @Expose({ name: "seo_og_type" }) seoOgType: string;

    @Expose({ name: "seo_title" }) seoTitle: string;

    @Expose({ name: "seo_keywords" }) seoKeywords: string;

    @Expose({ name: "seo_author" }) seoAuthor: string;

    @Expose({ name: "seo_description" }) seoDescription: string;

    @Expose({ name: "seo_image" }) seoImage: string;

    @Expose({ name: "entity_types" })
    @Type(() => EntityTypes)
    entityTypes: EntityTypes[];

    @Expose({ name: "is_active" }) isActive: boolean;
}

export default PageContext;
