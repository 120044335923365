import clsx from "clsx";
import React from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { CommonComponentProps } from "../types";
import { stringify } from "query-string";

export type TagProps = Partial<{
    queryParam: string;
    value: string;
    selected: boolean;
    variant: "filled" | "underline";
}>;

export type TagComponentProps = Partial<LinkProps> & CommonComponentProps<TagProps>;

const Tag: React.FunctionComponent<TagComponentProps> = ({
    value,
    queryParam = "",
    children,
    className,
    style,
    selected = false,
    variant = "filled",
    href,
    ...other
}: TagComponentProps) => {
    const { pathname } = useLocation();

    const classes = clsx({
        "popmenu-tags__item": variant === "filled",
        "popmenu-tags__item-active": variant === "filled" && selected,
        blog__tag: variant === "underline",
        "blog__tag-active": variant === "underline" && selected,
        [className ?? ""]: !!className,
    });

    const link = href ?? pathname;
    const param = queryParam ? `?${stringify({ [queryParam]: value })}` : "";

    return (
        <Link className={classes} style={style} to={link + param} {...other}>
            {children}
        </Link>
    );
};

export default Tag;
