import clsx from "clsx";
import React, { CSSProperties } from "react";
import { OverridableComponentProps, OverridableComponent } from "../types";

type FormLabelProps = Partial<{ required: boolean }>;

type FormLabelComponent = OverridableComponent<FormLabelProps>;

export type FormLabelComponentProps = OverridableComponentProps<FormLabelComponent>;

const FormLabel: React.FunctionComponent<FormLabelComponentProps> = ({
    component: Component = "div",
    children,
    required = false,
    className,
    style,
}: FormLabelComponentProps) => {
    const classes = clsx("form-label", {
        [className ?? ""]: !!className,
    });

    const customStyle: CSSProperties = {
        ...style,
    };

    return (
        <Component className={classes} style={customStyle}>
            {children} {required && <span>*</span>}
        </Component>
    );
};

export default FormLabel;
