import React, { PropsWithChildren } from "react";

export type TextProps = PropsWithChildren<Partial<{ item: boolean }>>;

const Text: React.FunctionComponent<TextProps> = ({ children, item }: TextProps) => {
    if (item) return <p className="product-desc__item_text">{children}</p>;
    return <p className="product-desc__text">{children}</p>;
};

export default Text;
