import React, { PropsWithChildren } from "react";

export type LinkProps = PropsWithChildren<Partial<{ href: string; type: "shops" | "brand" | "phone" }>>;

const Link: React.FC<LinkProps> = ({ children, href, type }: LinkProps) => {
    return (
        <a className={`header-toplink header-toplink--${type}`} href={href}>
            {children}
        </a>
    );
};

export default Link;
