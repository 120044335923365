import { Exclude, Expose } from "class-transformer";

@Exclude()
class EntityType {
    @Expose() id: number;

    @Expose() name: string;

    @Expose() note: string;
}

export default EntityType;
