import React, { PropsWithChildren } from "react";

type BrandProps = Partial<{ title: string; image: string; href: string }>;

const Brand: React.FC<BrandProps> = ({ children, title, image, href }: PropsWithChildren<BrandProps>) => {
    return (
        <div className="product-brandtechs">
            {href ? (
                <a className="product-brand" href={href} title={"Бренд: " + title}>
                    <img src={image} alt={title} />
                </a>
            ) : null}
            {children}
        </div>
    );
};

export default Brand;
