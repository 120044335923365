import React from "react";
import { BrowserRouter } from "react-router-dom";
import { hydrate } from "react-dom";
import { StoreContext } from "storeon/react";
import { HelmetProvider } from "react-helmet-async";
import store from "@/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Config } from "./modules/constants";
import App from "./App";
import useTheme from "./modules/hooks/useTheme";

const dehydratedState = window.__REACT_QUERY_STATE__;

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const BaseApp = () => {
    const { page } = window.__INITIAL_DATA__;
    useTheme({ autoSetup: true });

    return (
        <GoogleReCaptchaProvider reCaptchaKey={Config.CAPTCHA_KEY} language="ru">
            <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                    <Hydrate state={dehydratedState}>
                        <StoreContext.Provider value={store}>
                            <BrowserRouter>
                                <App page={page} />
                            </BrowserRouter>
                        </StoreContext.Provider>
                    </Hydrate>
                </QueryClientProvider>
            </HelmetProvider>
        </GoogleReCaptchaProvider>
    );
};

hydrate(<BaseApp />, document.getElementById("root"));

if (module.hot) {
    module.hot.accept();
}
