import * as React from "react";

function SvgMain(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0)">
                <path
                    d="M10.0296 0.544922C9.03044 0.544922 8.02884 0.897188 7.23778 1.60477L0.381844 7.73785C-0.0279608 8.10453 -0.063703 8.73375 0.301961 9.14449C0.668641 9.55871 1.30239 9.59547 1.71446 9.22629L8.56259 3.09125C9.3997 2.3425 10.6498 2.3425 11.4869 3.09125L18.335 9.22629C18.7471 9.59543 19.3809 9.55871 19.7475 9.14449C20.1132 8.73375 20.0775 8.10457 19.6677 7.73785L12.8117 1.60477C12.0206 0.897188 11.0288 0.544922 10.0296 0.544922V0.544922ZM9.02626 11.4707C7.38536 11.4707 6.03763 12.8282 6.03763 14.4691V17.4577H3.04122C2.45098 17.4577 1.99501 17.0037 1.99501 16.4135V12.5988C1.99716 12.0479 1.55231 11.5995 1.00141 11.5974C0.447469 11.5954 -0.00217955 12.0448 7.94779e-06 12.5988V16.4134C7.94779e-06 18.0819 1.37282 19.4547 3.04122 19.4547H16.9576C18.5985 19.4547 19.954 18.0972 19.954 16.4563V12.4741C19.9573 11.9193 19.5073 11.4685 18.9526 11.4707C18.4009 11.4727 17.9557 11.9224 17.959 12.4741V16.4563C17.959 17.019 17.5203 17.4577 16.9576 17.4577H14.0118V14.4691C14.0118 12.8282 12.6641 11.4707 11.0232 11.4707H9.02626ZM9.02626 13.4677H11.0232C11.5859 13.4677 12.0246 13.9064 12.0246 14.4691V17.4577H8.03266V14.4691C8.03266 13.9064 8.46356 13.4677 9.02626 13.4677Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={20} height={20} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

const MemoSvgMain = React.memo(SvgMain);
export default MemoSvgMain;
