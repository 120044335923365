import { ModalProps } from "@/components/Core/Modal";

/** @internal */
enum MODAL_TYPE {
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    SIGNUP_AND_SIGNIN = "SIGNUP_AND_SIGNIN",
    FEEDBACK = "FEEDBACK",
    MESSAGE = "MESSAGE",
    REVIEW = "REVIEW",
    TABLE = "TABLE",
    PAYMENT = "PAYMENT",
    IMAGE = "IMAGE",
}

type ModalType = keyof typeof MODAL_TYPE;

type ModalStateProps = Record<string, any>;

// type AppModalComponentProps = { modalType: ModalType; onClose(): void };
// src/modules/modals/index.ts
type AppModalComponentProps = {
    modalType: ModalType;
    onClose(): void;
    // total?: number; // Добавлено как необязательное
    photos?: string[]; // Новое поле для ImageModal
    initialIndex?: number; // Новое поле для ImageModal
};

type WithAppModalComponentProps<P = unknown> = P & AppModalComponentProps;

interface AppModal {
    open: boolean;
    type: ModalType;
    options?: ModalProps;
    component: React.ComponentType<WithAppModalComponentProps>;
    props?: ModalStateProps;
}

export type { ModalType, AppModal, AppModalComponentProps, WithAppModalComponentProps, ModalStateProps };
export { MODAL_TYPE };
