import { Exclude, Expose, Transform } from "class-transformer";
import dayjs from "dayjs";
import { Gender } from "../models/User.model";

@Exclude()
export class CreateUserDto {
    @Expose() username: string;

    @Expose() readonly email: string;

    @Expose() readonly password: string;
}

@Exclude()
export class UpdateUserDto {
    @Expose()
    @Transform(({ value }) => value || undefined)
    readonly password: string;

    @Expose({ name: "lastName" }) readonly last_name: string;

    @Expose({ name: "firstName" }) readonly first_name: string;

    @Expose()
    @Transform(({ value }) => dayjs(value).format("DD.MM.YYYY"))
    readonly birthday: string;

    @Expose() readonly phone: string;

    @Expose() readonly sex: Gender;

    @Expose({ name: "addressId" }) readonly address: number;
}
