import React from "react";
import clsx from "clsx";
import { OverridableComponent, OverridableComponentProps } from "../types";

export type TabsGroupProps = React.HTMLAttributes<HTMLElement> &
    Partial<{
        variant: "ambatabs" | "cattabs" | "btabs" | "producttabs";
        /** use this prop to combine group variants */
        variants: TabsGroupProps["variant"][];
        removeList: boolean;
    }>;

type TabsGroupComponent = OverridableComponent<TabsGroupProps>;

export type TabsGroupComponentProps = OverridableComponentProps<TabsGroupComponent>;

const TabsGroup: React.FC<TabsGroupComponentProps> = ({
    component: Component = "div",
    className,
    variant = "btabs",
    variants = [],
    removeList = false,
    children,
    ...other
}: TabsGroupComponentProps) => {
    const wrapClasses = clsx({
        btabs__wrap: variant === "btabs" || variants.includes("btabs"),
        cattabs__wrap: variant === "cattabs" || variants.includes("cattabs"),
        amba_tabs__wrap: variant === "ambatabs" || variants.includes("ambatabs"),
        "product-tabs__wrap": variant === "producttabs" || variants.includes("producttabs"),
        [className ?? ""]: !!className,
    });

    const listClasses = clsx("nav", {
        btabs__list: variant === "btabs" || variants.includes("btabs"),
        amba_tabs__list: variant === "ambatabs" || variants.includes("ambatabs"),
        "product-tabs-list": variant === "producttabs" || variants.includes("producttabs"),
    });

    return (
        <Component className={wrapClasses} {...other}>
            {!removeList && <ul className={listClasses}>{children}</ul>}
            {removeList && children}
        </Component>
    );
};

export default TabsGroup;
