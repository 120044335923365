import clsx from "clsx";
import React, { CSSProperties } from "react";
import { OverridableComponentProps, OverridableComponent } from "../types";

type FormGroupProps = Partial<{ error: boolean; end: boolean; flex: "rc" | "sc" }>;

type FormGroupComponent = OverridableComponent<FormGroupProps>;

export type FormGroupComponentProps = OverridableComponentProps<FormGroupComponent>;

const FormGroup: React.FunctionComponent<FormGroupComponentProps> = ({
    component: Component = "div",
    children,
    className,
    style,
    end = false,
    flex,
    error = false,
}: FormGroupComponentProps) => {
    const classes = clsx("form-group", {
        "form-group--error": error,
        "form-groupend": end,
        [`form-group${flex}`]: !!flex,
        [className ?? ""]: !!className,
    });

    const customStyle: CSSProperties = {
        ...style,
    };

    return (
        <Component className={classes} style={customStyle}>
            {children}
        </Component>
    );
};

export default FormGroup;
