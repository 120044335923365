import { User } from "@/api/services/user/models";
import { StoreonModule } from "storeon";

export interface AuthModuleState {
    isAuthenticated: boolean;
    user: Custom.Maybe<User>;
}

export interface AuthModuleEvents {
    "auth/set/user": User | null;
    "auth/set/is_authenticated": boolean;
}

export const authModule: StoreonModule<AuthModuleState, AuthModuleEvents> = (store) => {
    store.on("@init", () => ({
        isAuthenticated: false,
        user: null,
    }));

    store.on("auth/set/user", (state, user) => ({ user }));

    store.on("auth/set/is_authenticated", (state, isAuthenticated) => ({ isAuthenticated }));
};
