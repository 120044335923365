import React, { PropsWithChildren } from "react";

export type HeaderProps = PropsWithChildren<
    Partial<{
        title: string;
    }>
>;

const Header: React.FC<HeaderProps> = ({ title = "Новинки", children }: HeaderProps) => {
    return (
        <div className="catslider-top">
            <div className="catslider-heading">{title}</div>
            {children && <div className="catslider-topright">{children}</div>}
        </div>
    );
};

export default Header;
