import { favoriteApiService } from "@/api/services";
import { useStoreon } from "@/store";
import { IFavouriteItem } from "@/store/StoreModules/FavouriteModule";
import { useQuery } from "react-query";
import useAuth from "../useAuth";

interface UseFavouriteOptions {
    autoLoad?: boolean;
}

const useFavourite = (options: UseFavouriteOptions = {}) => {
    const { autoLoad = false } = options;
    const { dispatch, favouriteItems } = useStoreon("favouriteItems");
    const { isAuthenticated } = useAuth();

    const { refetch } = useQuery(["favourites", autoLoad, isAuthenticated], favoriteApiService.getAll, {
        enabled: autoLoad,
        onSuccess: (data) => {
            dispatch("favourite/set", data.results);
        },
    });

    const toggleFavorite = async (obj: IFavouriteItem) => {
        await favoriteApiService.toggle(obj.entityId);
        await refetch();
    };

    const isFavorite = (obj: IFavouriteItem | number) => {
        if (typeof obj === "number") return !!favouriteItems.find((item) => item.productId === obj);

        return favouriteItems.findIndex((item) => item.productId === obj.entityId) !== -1;
    };

    return {
        toggleFavorite,
        isFavorite,
        favouriteItems,
    };
};

export default useFavourite;
