import React, { memo } from "react";

export type TableProps = Partial<{}>;

const Table: React.FunctionComponent<TableProps> = () => {
    return (
        <table className="product-availability__table">
            <tr>
                <th className="product-availability__head"> </th>
                <th className="product-availability__head"> XS</th>
                <th className="product-availability__head">S</th>
                <th className="product-availability__head">M </th>
                <th className="product-availability__head">L</th>
                <th className="product-availability__head">XL</th>
                <th className="product-availability__head">XXL</th>
                <th className="product-availability__head">XXXL</th>
            </tr>
            <tr>
                <td className="product-availability__count">
                    <p className="product-availability__text">Соответствие РФ размеров</p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">40</p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">42-44</p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">46-48 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">50-52 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">54-56</p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">50-52 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">54-56</p>
                </td>
            </tr>
            <tr>
                <td className="product-availability__count">
                    <p className="product-availability__text">Грудь, см</p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">76-81 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">81-86 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">89-94 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">97-102 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">104-109</p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">97-102 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">104-109</p>
                </td>
            </tr>
            <tr>
                <td className="product-availability__count">
                    <p className="product-availability__text">Рукав (от основания шеи до запястья), см </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">74-76 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">76-79 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">81-84 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">86-89 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">91-94</p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">86-89 </p>
                </td>
                <td className="product-availability__count">
                    <p className="product-availability__text">91-94 </p>
                </td>
            </tr>
        </table>
    );
};

export default memo(Table);
