import React, { PropsWithChildren } from "react";
import CommonView from "@/views/Common";
import Card from "./Card";
import Slider from "./Slider";
import Carousel from "./Carousel";
import Header from "./Header";
import Tabs from "./Tabs";
import ShowAll from "./ShowAll";

type WrapperProps = PropsWithChildren<Partial<{ disableWrapper: boolean }>>;

const Wrapper: React.FC<WrapperProps> = ({ disableWrapper = false, children }: WrapperProps) => {
    return disableWrapper ? (
        <div className="catslider">{children}</div>
    ) : (
        <CommonView.SectionContainer className="catslider">{children}</CommonView.SectionContainer>
    );
};

export default Object.assign(Wrapper, { Card, Slider, Carousel, Header, Tabs, ShowAll });
