import clsx from "clsx";
import React, { forwardRef, PropsWithChildren } from "react";

import Top from "./Top";
import Middle from "./Middle";
import Message from "./Message";
import Bottom from "./Bottom";
import MobilePanel from "./MobilePanel";
import PopupMenu from "./PopupMenu";

export type WrapperProps = PropsWithChildren<
    Partial<{
        menuOpen: boolean;
        catalogOpen: boolean;
        fixed: boolean;
        withMessage: boolean;
    }>
>;

const Wrapper = forwardRef<HTMLElement, WrapperProps>(
    ({ fixed, withMessage, catalogOpen, menuOpen, children }: WrapperProps, ref) => {
        return (
            <header
                ref={ref}
                className={clsx([
                    "header",
                    { "header--withmessage": withMessage },
                    { "header--fixed": fixed },
                    { "header--menushowed": catalogOpen },
                    { "header--infoshowed": menuOpen },
                ])}
            >
                <div className="header-wrap">{children}</div>
            </header>
        );
    }
);

export default Object.assign(Wrapper, { Top, Middle, Message, Bottom, MobilePanel, PopupMenu });
