import clsx from "clsx";
import React from "react";
import RcPagination, { PaginationProps as RcPaginationProps } from "rc-pagination";
import { stringify } from "query-string";
import { Link } from "react-router-dom";
import Button from "../Button";
import { OverridableComponentProps, OverridableComponent } from "../types";

type PaginationProps = RcPaginationProps &
    Partial<{
        pageQueryParam: string;
        pageSizeQueryParam: string;
        pageSize: number;
        page: number;
        onChange(page: number): void;
        count: number;
        params: Record<string, any>;
    }>;

type PaginationComponent = OverridableComponent<PaginationProps>;

export type PaginationComponentProps = OverridableComponentProps<PaginationComponent>;

const Pagination: React.FC<PaginationComponentProps> = ({
    component: Component = "div",
    className,
    pageQueryParam = "page",
    pageSizeQueryParam = "page_size",
    params = {},
    count,
    page,
    pageSize,
    onChange,
    style,
    ...other
}: PaginationComponentProps) => {
    const currentPage = page || +params[pageQueryParam];
    const currentPageSize = pageSize || +params[pageSizeQueryParam];

    const classes = clsx("pagi", {
        [className ?? ""]: !!className,
    });

    const handleChange = (page: number) => {
        if (onChange) {
            onChange(page);
        }
    };

    const itemRender = (current: number, type, element) => {
        if (type === "page") {
            const pageQuery = pageQueryParam ? { [pageQueryParam]: current } : {};

            return (
                <Link
                    className={clsx("pagi-item", { "pagi-item--active": current === currentPage })}
                    to={`?${stringify({ ...params, ...pageQuery })}`}
                >
                    {current}
                </Link>
            );
        }
        if (type === "jump-prev") {
            return <Button className="pagi-item">...</Button>;
        }
        if (type === "jump-next") {
            return <Button className="pagi-item">...</Button>;
        }
        if (type === "prev") {
            return null;
        }
        if (type === "next") {
            return null;
        }
        return element;
    };

    return (
        <Component className={classes} style={style}>
            <RcPagination
                className="pagi-items"
                hideOnSinglePage
                total={count}
                itemRender={itemRender}
                current={currentPage}
                pageSize={currentPageSize}
                showTitle={false}
                onChange={handleChange}
                {...other}
            />
        </Component>
    );
};

export default Pagination;
