import React, { PropsWithChildren } from "react";

type ItemProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Item: React.FC<ItemProps> = ({ children, ...other }: PropsWithChildren<ItemProps>) => {
    return (
        <div className="footer-menu__item">
            <a className="footer-menu__link" {...other}>
                {children}
            </a>
        </div>
    );
};

export default Item;
