import { Rating } from "@/components/Core";
import React from "react";

export type Review = { name: string; city: string; date: string; stars: number; text: string };

export type CardProps = Partial<Review>;

const Card: React.FunctionComponent<CardProps> = ({ name, city, date, stars = 0, text }: CardProps) => {
    return (
        <div className="reviews-card">
            <div className="reviews-card-left">
                <p className="reviews-card__name">{name}</p>
                <p className="reviews-card__city">{city}</p>
                <p className="reviews-card__date">{date}</p>
            </div>
            <div className="reviews-card-right">
                <div className="catrating">
                    <div className="catrating__stars">
                        <Rating disabled value={stars} />
                    </div>
                </div>
                <p className="reviews-card__text">{text}</p>
            </div>
        </div>
    );
};

export default Card;
