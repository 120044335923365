import { Entity } from "../models";
import { MainCarouselSchema } from "../schemas";
import EntityModel from "./EntityModel";

class MainCarouselEntity extends EntityModel<MainCarouselSchema> {
    readonly title: string;
    readonly image: string;
    readonly hasDescription: boolean;
    readonly description: string;
    readonly url: string;

    constructor(entity: Entity<MainCarouselSchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.image = entity.getFirstAttribute("image")?.getFirstValue()?.display || "";
        this.hasDescription = !!entity.getFirstAttribute("has_description")?.getFirstValue()?.value ?? false;
        this.description = entity.getFirstAttribute("description")?.getFirstValue()?.value || "";
        this.url = entity.getFirstAttribute("url")?.getFirstValue()?.value || "";
    }
}

export default MainCarouselEntity;
