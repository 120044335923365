import dayjs from "@/modules/utils/dayjs";
import { Entity } from "../models";
import { NewsSchema } from "../schemas";
import EntityModel from "./EntityModel";

class NewsEntity extends EntityModel<NewsSchema> {
    readonly title: string;
    readonly image: string;
    private readonly _date: string;
    readonly date: string;
    readonly summary: string;
    readonly html: string;

    constructor(entity: Entity<NewsSchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.image = entity.getFirstAttribute("image")?.getFirstValue()?.display || "";
        this._date = entity.getFirstAttribute("date")?.getFirstValue()?.value || "";
        this.date = this._date ? dayjs(this._date, "YYYY-MM-DD").format("D MMMM YYYY") : "";
        this.summary = entity.getFirstAttribute("summary")?.getFirstValue()?.value || "";
        this.html = entity?.getFirstAttribute("html")?.getFirstValue()?.value || "";
    }
}

export default NewsEntity;
