import React, { PropsWithChildren } from "react";
import { CatalogProps } from "../Middle/Catalog";

import Col from "./Col";
import Mobile from "./Mobile";
import Categories from "./Categories";
import Menu from "./Menu";

type PopupMenuInternalProps = Pick<CatalogProps, "onToggleCatalog">;

export type PopupMenuProps = PropsWithChildren<unknown>;

const PopupMenu: React.FC<PopupMenuInternalProps & PopupMenuProps> = ({
    children,
    onToggleCatalog,
}: PopupMenuProps & PopupMenuInternalProps) => {
    return (
        <div className="popmenu">
            <div className="container">
                <div className="popmenu-close" onClick={onToggleCatalog} />
                <div className="popmenu-row">{children}</div>
            </div>
        </div>
    );
};

export default Object.assign(PopupMenu, { Col, Mobile, Categories, Menu });
