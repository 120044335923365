import { Button, FormGroup, FormLabel, Input, PasswordInput, Checkbox, FormHelperText } from "@/components/Core";
import { Formik, FormikProps, Form, FormikConfig } from "formik";
import * as Yup from "yup";
import React, { forwardRef } from "react";

interface RegisterUser {
    // username: string;
    email: string;
    password: string;
    passwordRepeat: string;
    isAgree: boolean;
}

const RegisterSchema = Yup.object().shape({
    // username: Yup.string().required("Пожалуйста, укажите ваше имя пользователя"),
    email: Yup.string().email("Электронная почта указана неверно").required("Пожалуйста, укажите вашу эл. почту"),
    password: Yup.string().required("Пожалуйста, придумайте пароль"),
    passwordRepeat: Yup.string()
        .required("Пожалуйста, повторите ваш пароль")
        .oneOf([Yup.ref("password"), null], "Пароли не совпадают"),
    isAgree: Yup.boolean().required().oneOf([true], "Вы должны согласиться с условиями!"),
});

export type RegisterFormProps = Pick<FormikConfig<RegisterUser>, "onSubmit">;

const RegisterForm = forwardRef<FormikProps<RegisterUser>, RegisterFormProps>(
    ({ onSubmit }: RegisterFormProps, ref) => {
        return (
            <Formik<RegisterUser>
                initialValues={{
                    // username: "",
                    email: "",
                    password: "",
                    passwordRepeat: "",
                    isAgree: false,
                }}
                innerRef={ref}
                onSubmit={onSubmit}
                validationSchema={RegisterSchema}
                validateOnMount
            >
                {({ touched, errors, handleChange, setFieldValue, values, isSubmitting }) => {
                    return (
                        <Form className="form mlogin-tabs__form" noValidate>
                            {/* <FormGroup error={touched.username && !!errors.username}>
                                <FormLabel required>Ваше имя пользователя</FormLabel>
                                <Input
                                    name="username"
                                    required
                                    value={values.username}
                                    onChange={handleChange}
                                    placeholder="Введите свое имя пользователя"
                                />
                                <FormHelperText variant="error">{touched.username && errors.username}</FormHelperText>
                            </FormGroup> */}
                            <FormGroup error={touched.email && !!errors.email}>
                                <FormLabel required>Электронная почта</FormLabel>
                                <Input
                                    name="email"
                                    required
                                    value={values.email}
                                    onChange={handleChange}
                                    placeholder="Введите свою электронную почту"
                                />
                                <FormHelperText variant="error">{touched.email && errors.email}</FormHelperText>
                            </FormGroup>
                            <FormGroup error={touched.password && !!errors.password}>
                                <FormLabel required>Пароль</FormLabel>
                                <PasswordInput
                                    name="password"
                                    required
                                    value={values.password}
                                    onChange={handleChange}
                                    placeholder="Придумайте пароль"
                                />
                                <FormHelperText variant="error">{touched.password && errors.password}</FormHelperText>
                            </FormGroup>
                            <FormGroup error={touched.passwordRepeat && !!errors.passwordRepeat}>
                                <FormLabel required>Повторите пароль</FormLabel>
                                <PasswordInput
                                    name="passwordRepeat"
                                    value={values.passwordRepeat}
                                    required
                                    onChange={handleChange}
                                    placeholder="Повторите пароль"
                                />
                                <FormHelperText variant="error">
                                    {touched.passwordRepeat && errors.passwordRepeat}
                                </FormHelperText>
                            </FormGroup>
                            <FormGroup>
                                <Checkbox
                                    id="pcp"
                                    checkboxSize="sm"
                                    name="isAgree"
                                    required
                                    checked={values.isAgree}
                                    onChange={(e) => setFieldValue(e.target.name, e.target.checked)}
                                >
                                    Я принимаю условия <a href="!#">Пользовательского соглашения</a> и даю согласие на
                                    обработку <a href="!#">персональных данных</a>
                                </Checkbox>
                                <FormHelperText variant="error">{touched.isAgree && errors.isAgree}</FormHelperText>
                            </FormGroup>
                            <FormGroup end>
                                <Button fullWidth type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "Регистрируемся..." : "Зарегистрироваться"}
                                </Button>
                            </FormGroup>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default RegisterForm;
