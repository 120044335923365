import React from "react";
import { authApiService, userApiService } from "@/api/services";
import useAuth from "@/modules/hooks/useAuth";
import { useQuery } from "react-query";
import useCart from "@/modules/hooks/useCart";
import Loader from "@/views/Loader";

const withAuth = (WrappedComponent) => (props) => {
    const {
        setUser,
        getRefreshToken,
        refresh,
        isAuthenticated,
        getAccessTokenExpires,
        setCustomerToken,
        getCustomerToken,
        removeCustomerToken,
    } = useAuth();
    const { loadCart } = useCart();

    const accessTokenExpires = getAccessTokenExpires();
    const refreshToken = getRefreshToken();
    const customerToken = getCustomerToken();

    const { isFetching, isFetched } = useQuery(
        ["refresh", refreshToken, accessTokenExpires],
        () => {
            if (!refreshToken) return null;
            return authApiService.refresh(refreshToken);
        },

        {
            enabled: !!refreshToken,
            onSuccess: (data) => data?.result && refresh(data),
            /** for refetch */
            // refetchInterval: accessTokenExpires || false,
        }
    );

    const { isFetching: isUserFetching } = useQuery(
        ["user", isAuthenticated, isFetched],
        userApiService.getCurrentUser,
        {
            enabled: isAuthenticated && isFetched,
            onSuccess: (user) => {
                if (user) {
                    setUser(user);
                }
            },
        }
    );

    const { isFetching: isCartFetching } = useQuery(["cart", isAuthenticated], loadCart, {
        enabled: isAuthenticated && isFetched,
    });

    const { isFetching: isCustomerFetching } = useQuery(["customer"], userApiService.createCustomer, {
        enabled: !isAuthenticated && !isFetching && !customerToken,
        onSuccess: (data) => setCustomerToken(data),
    });

    const { isFetching: isCustomerAssociateFetching } = useQuery(
        ["associate_customer", isAuthenticated, isFetched],
        userApiService.associateCustomer,
        {
            enabled: Boolean(customerToken) && isFetched && isAuthenticated,
            onSuccess: removeCustomerToken,
        }
    );

    return (
        <>
            {isFetching || isUserFetching || isCartFetching || isCustomerFetching || isCustomerAssociateFetching ? (
                <Loader />
            ) : null}
            <WrappedComponent {...props} />
        </>
    );
};

export default withAuth;
