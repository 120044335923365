import { Exclude, Expose } from "class-transformer";

@Exclude()
class CartItem {
    @Expose({ name: "count" })
    quantity: number;
    @Expose()
    sku: number;
    @Expose()
    id: number;
}

export default CartItem;
