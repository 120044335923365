import React from "react";
import clsx from "clsx";
import { CommonComponentProps } from "../types";

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> &
    Partial<{
        inputClassName: string;
        labelClassName: string;
        checkboxSize: "md" | "sm";
    }>;

const Checkbox: React.FC<CommonComponentProps<CheckboxProps>> = ({
    id = "example",
    children,
    checkboxSize = "md",
    className,
    inputClassName,
    labelClassName,
    style,
    ...other
}: CommonComponentProps<CheckboxProps>) => {
    const classes = clsx("form-checkbox", {
        "form-checkbox-sm": checkboxSize === "sm",
        [className ?? ""]: !!className,
    });

    const inputClasses = clsx("form-checkbox__input", {
        [inputClassName ?? ""]: !!inputClassName,
    });

    const labelClasses = clsx("form-checkbox__label", {
        [labelClassName ?? ""]: !!labelClassName,
    });

    return (
        <div className={classes} style={style}>
            <input className={inputClasses} type="checkbox" id={id} {...other} />
            <label className={labelClasses} htmlFor={id}>
                {children}
            </label>
        </div>
    );
};

export default Checkbox;
