import React, { Component } from "react";

type State = {
    component: React.ComponentType | null;
};

const asyncComponent = (importComponent: () => Promise<any>): React.ComponentClass<any, State> => {
    return class extends Component<unknown, State> {
        constructor(props) {
            super(props);
            this.state = {
                component: null,
            };
        }

        componentDidMount() {
            importComponent().then((cmp) => {
                this.setState({ component: cmp.default });
            });
        }

        render() {
            const C = this.state.component;
            return C ? <C {...this.props} /> : null;
        }
    };
};

export default asyncComponent;
