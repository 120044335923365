import React, { useState } from "react";

type UseTabsOptions<Tabs extends Array<string>> = { initialTab: Tabs[number] };

const useTabs = <Tabs extends Array<string>>(options: UseTabsOptions<Tabs>) => {
    const [activeTab, setActiveTab] = useState<Tabs[number]>(options.initialTab);

    const handleChangeTab = (e: React.MouseEvent<HTMLElement>, tab: Tabs[number]) => {
        setActiveTab(tab as Tabs[number]);
    };

    return { activeTab, handleChangeTab, setActiveTab };
};

export default useTabs;
