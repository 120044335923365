import React, { PropsWithChildren } from "react";

export type ColProps = PropsWithChildren<Partial<{ position: "left" | "right"; wrap: boolean }>>;

const Col: React.FC<ColProps> = ({ children, position, wrap = false }: ColProps) => {
    if (wrap) {
        return (
            <div className={`popmenu-${position}`}>
                <div className="popmenu-left-wrap">{children}</div>
            </div>
        );
    }
    return <div className={`popmenu-${position}`}>{children}</div>;
};

export default Col;
