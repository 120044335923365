import React from "react";

type BannerProps = Partial<{ href: string; image: string; subImage: string; title: string }>;

const Banner: React.FC<BannerProps> = ({ href, image, subImage, title }: BannerProps) => {
    return (
        <a className="popmenu-brandbanner" href={href}>
            <img className="popmenu-brandbanner__img" src={image} alt={"Фон: " + title} />
            <div className="popmenu-brandbanner__wrap">
                <img className="popmenu-brandbanner__logo" src={subImage} alt={title} />
            </div>
        </a>
    );
};

export default Banner;
