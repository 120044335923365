import Modal from "@/components/Core/Modal";
import { WithAppModalComponentProps } from "@/modules/modals";
import React from "react";
import { Button, Typography } from "@/components/Core";

export type MessageModalProps = WithAppModalComponentProps<Partial<{ title: string; message: string }>>;

const MessageModal: React.FunctionComponent<MessageModalProps> = ({ onClose, title, message }: MessageModalProps) => {
    return (
        <Modal.Content>
            <Modal.Header onClose={onClose} />
            <Modal.Body>
                <Typography component="h3">{title}</Typography>
                <Typography component="p">{message}</Typography>
                <Button variant="default" fullWidth onClick={onClose}>
                    Ок
                </Button>
            </Modal.Body>
        </Modal.Content>
    );
};

export default MessageModal;
