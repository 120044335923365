import clsx from "clsx";
import React from "react";
import { OverridableComponentProps, OverridableComponent } from "../types";

export type TagsProps = Partial<{}>;

type TagsComponent = OverridableComponent<TagsProps>;

export type TagsComponentProps = React.HTMLAttributes<HTMLElement> & OverridableComponentProps<TagsComponent>;

const Tags: React.FunctionComponent<TagsComponentProps> = ({
    component: Component = "div",
    children,
    className,
    style,
    ...other
}: TagsComponentProps) => {
    const classes = clsx("popmenu-tags", {
        [className ?? ""]: !!className,
    });

    return (
        <Component className={classes} style={style} {...other}>
            {children}
        </Component>
    );
};

export default Tags;
