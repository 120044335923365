import React from "react";
import Layout from "@/components/Common/Layout";
import Product from "@/components/Product";
import ProductDetail from "@/components/ProductDetail";
import ProductView from "@/views/Product";
import { WithPageProps } from "@/modules/pages";
import { useLocation } from "react-router-dom";
import ProductRecommendations from "@/components/ProductRecommendations";
import { ProductStructuredDataModel } from "@/api/services/common/models/ProductStructuredData.model";
import { Helmet } from "react-helmet-async";

const ProductPage: React.FunctionComponent<WithPageProps> = (props: WithPageProps) => {
    const { pathname } = useLocation();
    const location = pathname;

    const commonProps = { id: props.context.id };
    const product = props.context;

    const StructuredDataModel: ProductStructuredDataModel = {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: product?.title,
        // aggregateRating: {
        //     "@type": "AggregateRating",
        //     reviewCount: String(product?.review?.count || 0),
        //     ratingValue: String(product?.review?.avg || 0),
        // },
        brand: {
            "@type": "Brand",
            name: product?.attr.brand?.value || "",
        },
        description: product?.attr.description?.value || "",
        // sku: currentSku?.attr?.sku?.value || "",
        image: product?.attr?.images?.value || "",
        review: {
            "@type": "Review",
            reviewRating: {
                "@type": "Rating",
                // ratingValue: reviews?.results.length ? reviews?.results[0].rates.toString() : "0",
                bestRating: "5",
            },
            author: {
                "@type": "Person",
                name: "День сурка",
            },
        },
        offers: {
            "@type": "Offer",
            price: String(product?.price),
            priceCurrency: "RUB",
            // availability: product?.skus.length ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
            url: location,
        },
    };
    return (
        <Layout wrap {...props.context}>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(StructuredDataModel)}</script>
            </Helmet>
            <ProductView>
                <Product {...commonProps} />
                <ProductDetail {...commonProps} />
            </ProductView>
            <ProductRecommendations {...commonProps} />
        </Layout>
    );
};

export default ProductPage;
