import React from "react";
import CommonView from "@/views/Common";

export type Breadcrumb = { title: string; slug: string };

export type BreadcrumbsProps = { breadcrumbs: Breadcrumb[] };

const Breabcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({ breadcrumbs }: BreadcrumbsProps) => {
    return (
        <CommonView.SectionContainer className="breadcrumbs">
            <div className="breadcrumbs__items">
                {breadcrumbs.map((breadcrumb, i) => {
                    const isLast = breadcrumbs.length - 1 === i;
                    return (
                        <React.Fragment key={i}>
                            {!isLast && (
                                <a className="breadcrumbs__item" href={`/${breadcrumb.slug}`}>
                                    {breadcrumb.title}
                                </a>
                            )}
                            {isLast && <span> {breadcrumb.title}</span>}
                            {!isLast && <div className="breadcrumbs__sep">/</div>}
                        </React.Fragment>
                    );
                })}
            </div>
        </CommonView.SectionContainer>
    );
};

export default Breabcrumbs;
