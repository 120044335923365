import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";

type useTagsOptions = Partial<{
    initialTag: string | number;
    tagQueryParam: string;
}>;

const useTags = (options: useTagsOptions = {}) => {
    const { initialTag = "", tagQueryParam = "tag" } = options;

    const { search } = useLocation();

    const queryTag = parse(search)[tagQueryParam] as string;

    const [activeTag, setActiveTag] = useState<number | string>(queryTag || initialTag);

    const handleChangeTag = useCallback((value: number | string) => {
        setActiveTag(value);
    }, []);

    useEffect(() => {
        setActiveTag(queryTag || initialTag);
    }, [queryTag, initialTag]);

    return {
        activeTag,
        handleChangeTag,
    };
};

export default useTags;
