import BaseApiService from "@/api/base";
import { CustomerTokenInterceptor, TokenInterceptor } from "@/api/http/interceptors";
import { CreateAddressDto, UpdateAddressDto, CreateUserDto, UpdateUserDto } from "@/api/services/user/dto";
import { User, Address, Shop, Customer } from "@/api/services/user/models";
import { Config } from "@/modules/constants";

class UserApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
        this.http.interceptors.request.use(CustomerTokenInterceptor);
    }

    register = async (createUserDto: CreateUserDto): Promise<User> => {
        createUserDto.username = createUserDto.email;
        const { data } = await this.http.post("/user/", createUserDto);

        return this.plainToClass(User, data);
    };

    restorePassword = async (params): Promise<any> => {
        const { data } = await this.http.post("/user/restore_password/", params);

        return data;
    };

    restorePasswordSetPassword = async (params): Promise<any> => {
        const { data } = await this.http.post("/user/restore_password_set_password/", params);

        return data;
    };

    updateUser = async (id: number, updateUserDto: UpdateUserDto): Promise<User> => {
        const { data } = await this.http.patch(`/user/${id}/`, updateUserDto);

        return this.plainToClass(User, data);
    };

    getCurrentUser = async (): Promise<Custom.Maybe<User>> => {
        const { data } = await this.http.get("/user/current/");

        return this.plainToClass(User, data);
    };

    getUserAddresses = async (): Promise<Address[]> => {
        const { data } = await this.http.get(`/user/address/`);

        return this.plainToClassArray(Address, data.results);
    };

    createAddress = async (createAddressDto: CreateAddressDto): Promise<Address> => {
        const { data } = await this.http.post(`/user/address/`, createAddressDto);

        return this.plainToClass(Address, data);
    };

    updateAddress = async (addressId: number, updateAddressDto: UpdateAddressDto): Promise<Address> => {
        const { data } = await this.http.patch(`/user/address/${addressId}/`, updateAddressDto);

        return this.plainToClass(Address, data);
    };

    removeAddress = async (addressId: number): Promise<Address> => {
        const { data } = await this.http.delete(`/user/address/${addressId}/`);

        return this.plainToClass(Address, data);
    };

    getShops = async (): Promise<Shop[]> => {
        const { data } = await this.http.get("/multisite/shop/");

        return this.plainToClassArray(Shop, data.results);
    };

    getShop = async (id: number): Promise<Shop> => {
        const { data } = await this.http.get(`/multisite/shop/${id}/`);

        return this.plainToClass(Shop, data);
    };

    createCustomer = async (): Promise<Customer> => {
        const { data } = await this.http.post(`/user/customer/create_customer/`);

        return this.plainToClass(Customer, data.customer);
    };

    associateCustomer = async (): Promise<{ status: string }> => {
        const { data } = await this.http.post(`/user/customer/user_associate_customer/`);

        return data;
    };
}

export default new UserApiService({
    baseURL: Config.CMS_API_URL,
});
