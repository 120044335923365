import { Exclude, Expose, Type } from "class-transformer";

@Exclude()
class Customer {
    @Expose() id: number;

    @Type(() => Date)
    @Expose({ name: "last_access" })
    lastAccess: Date;

    @Expose() number: string;

    @Expose() recognized: number;

    @Expose({ name: "user" }) userId: number | null;
}

export default Customer;
