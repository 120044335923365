import React, { PropsWithChildren } from "react";
import Modal from "@/components/Common/Modal";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Breadcrumbs, { BreadcrumbsProps } from "@/views/Breadcrumbs";
import clsx from "clsx";
import { Helmet } from "react-helmet-async";
import { WithPageContextProps } from "@/modules/pages";
import { BackToTop } from "@/components/Controls";
import useTheme from "@/modules/hooks/useTheme";
import { ThemeFaviconPath } from "@/modules/theme";

type LayoutProps = PropsWithChildren<
    Partial<
        { wrap: boolean; hideBreadcrumbs: boolean; fullWidth: boolean } & Pick<BreadcrumbsProps, "breadcrumbs"> &
            WithPageContextProps
    >
>;

const Layout: React.FC<LayoutProps> = ({
    children,
    wrap = false,
    hideBreadcrumbs = false,
    breadcrumbs = [],
    fullWidth = false,
    ...ctx
}: LayoutProps) => {
    const { currentTheme } = useTheme();

    const child: React.ReactNode = (
        <>
            {!hideBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            {children}
        </>
    );

    const faviconPath: string = ThemeFaviconPath[currentTheme];

    return (
        <>
            <Helmet>
                <title>{ctx.title}</title>
                <link rel="apple-touch-icon" sizes="76x76" href={`${faviconPath}/apple-touch-icon.png`} />
                <link rel="icon" type="image/png" sizes="32x32" href={`${faviconPath}/favicon-32x32.png`} />
                <link rel="shortcut icon" type="image/x-icon" href={`${faviconPath}/favicon.ico`} />
                <meta name="description" content={ctx.seoDescription} />
                <meta name="keywords" content={ctx.seoKeywords} />
                <meta property="og:type" content={ctx.seoOgType || "website"} />
                <meta property="og:title" content={ctx.seoTitle || ctx.title} />
                <meta property="og:description" content={ctx.seoDescription} />
                {ctx.seoImage ? <meta property="og:image" content={ctx.seoImage} /> : null}
            </Helmet>
            <Header />
            <main className={clsx({ mainfull: fullWidth }, { main: !fullWidth })}>
                {wrap && <div className="mainwrap">{child}</div>}
                {!wrap && <>{child}</>}
                <BackToTop />
            </main>

            <Footer />
            <Modal />
        </>
    );
};

export default Layout;
