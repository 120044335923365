import { Button, Input } from "@/components/Core";
import React from "react";
import { usePage } from "@/modules/hooks";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";

export type SearchProps = Partial<unknown>;

const Search: React.FunctionComponent<SearchProps> = () => {
    const { page: currentPage } = usePage();
    const config = currentPage?.config;
    const { search } = useLocation();

    const q = parse(search, { parseNumbers: true })["q"] as string;
    return (
        <form method="get" action={config?.pages?.searchUrl || ""} className="header-search">
            <Input defaultValue={q} name="q" className="header-search__input" placeholder="Более 10000 товаров" />
            <Button className="header-search__btn" type="submit" />
        </form>
    );
};

export default Search;
