import * as React from "react";

function SvgAccount(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={18}
            height={20}
            viewBox="0 0 18 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0)">
                <path
                    d="M8.9936 0C5.90944 0 3.40259 2.52684 3.40259 5.6356C3.40259 8.74436 5.90944 11.2712 8.9936 11.2712C12.0778 11.2712 14.5846 8.74436 14.5846 5.6356C14.5846 2.52684 12.0778 0 8.9936 0ZM8.9936 9.31456C6.97882 9.31456 5.33988 7.66254 5.33988 5.6317C5.33988 3.60085 6.98269 1.95274 8.9936 1.95274C11.0045 1.95274 12.6473 3.60475 12.6473 5.6356C12.6473 7.66645 11.0084 9.31456 8.9936 9.31456Z"
                    fill="currentColor"
                />
                <path
                    d="M17.9054 16.5828C17.4017 14.7121 16.3478 13.0679 14.8561 11.8338C14.4416 11.4901 13.8332 11.5526 13.4923 11.9665C13.1513 12.3844 13.2133 12.9976 13.624 13.3413C14.8019 14.3216 15.6388 15.6182 16.034 17.0944C16.1154 17.403 15.9875 17.6295 15.9061 17.7427C15.7589 17.9341 15.5381 18.0474 15.2978 18.0474C14.7631 18.0474 14.3292 18.4848 14.3292 19.0237C14.3292 19.5627 14.7631 20.0001 15.2978 20.0001C16.1464 20.0001 16.929 19.6096 17.4443 18.93C17.9558 18.2544 18.1263 17.3991 17.9054 16.5828Z"
                    fill="currentColor"
                />
                <path
                    d="M11.8184 18.0433H2.69379C2.36832 18.0433 2.17847 17.8558 2.09323 17.7425C1.94599 17.5473 1.8995 17.3012 1.96537 17.0669C2.36445 15.6062 3.19361 14.3174 4.35985 13.3489C4.77056 13.0052 4.83255 12.392 4.49159 11.9742C4.15063 11.5563 3.54232 11.4977 3.12774 11.8414C1.65153 13.0716 0.601516 14.6963 0.0978208 16.5514C-0.126904 17.3793 0.0397021 18.2463 0.555021 18.9298C1.06259 19.6055 1.84525 19.996 2.69379 19.996H11.8184C12.3531 19.996 12.7871 19.5586 12.7871 19.0196C12.7871 18.4807 12.3531 18.0433 11.8184 18.0433Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={18} height={20} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

const MemoSvgAccount = React.memo(SvgAccount);
export default MemoSvgAccount;
