import BaseApiService from "@/api/base";
import { CustomerTokenInterceptor, TokenInterceptor } from "@/api/http/interceptors";
import { Config } from "@/modules/constants";
import { ProductModel } from "@/api/services/common/models/Product.model";
import { PaginatedQueryResult } from "@/api/shared/models";
import { Comparison } from "./models";

class CompareApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
        this.http.interceptors.request.use(CustomerTokenInterceptor);
    }

    getAll = async (): Promise<PaginatedQueryResult<Comparison>> => {
        const { data } = await this.http.get(`/catalog/comparison/`);
        return this.plainToClassFromExist(new PaginatedQueryResult<Comparison>(Comparison), data);
    };

    toggle = async (productId: number): Promise<{ count: number; status: string }> => {
        const { data } = await this.http.post(`/catalog/comparison/toggle/${productId}/`);
        return data;
    };

    getCompareProducts = async (ids_list: number[]): Promise<ProductModel[]> => {
        const { data } = await this.http.get("/catalog/compare-product/", {
            params: { ids_list: ids_list.length > 0 ? ids_list.join(",") : undefined },
        });

        return this.plainToClassArray(ProductModel, data.result.items);
    };

    getCompareAttrs = async (): Promise<string[]> => {
        const { data } = await this.http.get("/multisite/comparison_attr/");
        return data.results.map((item) => item.title);
    };
}

export default new CompareApiService({
    baseURL: Config.CMS_API_URL,
});
