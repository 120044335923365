import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { QueryParams } from "@/api/types";
import { Config } from "@/modules/constants";
import { CartItem } from "./models";
import { ICart, CartProductSKU } from "./models/CartProduct.model";
import { PaginatedQueryResult } from "@/api/shared/models";

class CartApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    getCart = async (params: QueryParams = {}): Promise<CartItem[]> => {
        const { data } = await this.http.get("/cart/", { params: { ...params, page_size: 100 } });

        return this.plainToClassArray(CartItem, data.results);
    };

    createCart = async (params: ICart): Promise<any> => {
        const { data } = await this.http.post("/cart/add/", { data: [params] });

        return data;
    };

    updateCart = async (id: number, params: ICart): Promise<any> => {
        const { data } = await this.http.post(`/cart/${id}/update_by_product/`, {
            data: params,
        });

        return data;
    };

    removeElement = async (id): Promise<any> => {
        const params = {
            data: [id],
        };
        const { data } = await this.http.post(`/cart/remove_by_products/`, params);

        return data;
    };

    getProductsInBasket = async (): Promise<PaginatedQueryResult<CartProductSKU>> => {
        const { data } = await this.http.get(`/cart/`);

        return this.plainToClassFromExist(new PaginatedQueryResult(CartProductSKU), data);
    };
}

export default new CartApiService({
    baseURL: Config.CMS_API_URL,
});
