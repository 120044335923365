import BaseApiService from "@/api/base";
import { Config } from "@/modules/constants";
import { QueryParams } from "@/api/types";
import { FeedbackDto } from "./dto";
import { Page, Feedback, Recommendation } from "./models";
import { HttpOptions } from "@/api/http";

class CommonApiService extends BaseApiService {
    getPage = async (url: string, params: QueryParams = {}, config?: HttpOptions): Promise<Page> => {
        const target = url.startsWith("/") ? url.substring(1) : url;

        const { data } = await this.http
            .get(`/multisite/pages/${target.split("?")[0]}/`, { params, ...config })
            .catch(({ response }) => response);
        return this.plainToClass(Page, data);
    };

    createFeedback = async (feedbackDto: FeedbackDto): Promise<Feedback> => {
        const { data } = await this.http.post("/multisite/feedback/", feedbackDto);

        return this.plainToClass(Feedback, data);
    };

    getRecommendations = async (params: QueryParams<Recommendation> = {}): Promise<Recommendation[]> => {
        const { data } = await this.http.get("/multisite/recommendations/", { params });

        return this.plainToClassArray(Recommendation, data.results);
    };
}

export default new CommonApiService({
    baseURL: Config.CMS_API_URL,
});
