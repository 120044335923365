import React from "react";
import FooterView from "@/views/Footer";
import useFooterControls from "./useFooterControls";
import usePage from "@/modules/hooks/usePage";

export type FooterProps = {};

const Footer: React.FunctionComponent<FooterProps> = () => {
    const { page } = usePage();
    const { handleFeedbackClick } = useFooterControls();

    const menu = page?.menu;
    const config = page?.config;
    const social = config?.social;
    const contacts = config?.contacts;

    const linksTree = menu?.toTree("footer") || [];

    return (
        <FooterView>
            <FooterView.Content>
                <FooterView.Row>
                    {linksTree.map((parent) => (
                        <FooterView.Col key={parent.id} colSize={2}>
                            <FooterView.Title>{parent.title}</FooterView.Title>
                            <FooterView.Menu>
                                {parent.children.map((link) => (
                                    <FooterView.Menu.Item
                                        key={link.id}
                                        href={link.url}
                                        target={link.targetBlank ? "_blank" : "_self"}
                                    >
                                        {link.title}
                                    </FooterView.Menu.Item>
                                ))}
                            </FooterView.Menu>
                        </FooterView.Col>
                    ))}
                    <FooterView.Col colSize={3}>
                        <FooterView.Title>КОНТАКТЫ</FooterView.Title>
                        <FooterView.Contacts>
                            <FooterView.Contacts.Item
                                href={`tel:${contacts?.phone}`}
                                label="Звонок по России бесплатный"
                            >
                                {page?.config.contacts.phone}
                            </FooterView.Contacts.Item>
                            <FooterView.Contacts.Item
                                href={`mailto:${contacts?.email}`}
                                label="Для вопросов и пожеланий"
                            >
                                {contacts?.email}
                            </FooterView.Contacts.Item>
                            <FooterView.Contacts.Item label="Режим работы">
                                {contacts?.workTime}
                            </FooterView.Contacts.Item>
                        </FooterView.Contacts>
                        <FooterView.Buttons>
                            <FooterView.Buttons.Button onClick={handleFeedbackClick}>
                                Напишите нам
                            </FooterView.Buttons.Button>
                        </FooterView.Buttons>
                    </FooterView.Col>
                    <FooterView.Col colSize={4}>
                        <FooterView.Subscribe>
                            <FooterView.Title>ПОДПИСКА НА «ДЕНЬ СУРКА»</FooterView.Title>
                            <FooterView.Subscribe.Label>
                                Будьте в курсе всех новостей, событий и акций
                            </FooterView.Subscribe.Label>
                            <FooterView.Subscribe.Form />
                        </FooterView.Subscribe>
                        <FooterView.Social>
                            <FooterView.Title>«ДЕНЬ СУРКА» В СОЦСЕТЯХ</FooterView.Title>
                            <FooterView.Social.Items>
                                {/* <FooterView.Social.Link href={social?.facebookUrl} type="fb" /> */}
                                <FooterView.Social.Link href={social?.vkUrl} type="vk" />
                                {/* <FooterView.Social.Link href={social?.instagramUrl} type="in" /> */}
                                <FooterView.Social.Link href={social?.youtubeUrl} type="yt" />
                            </FooterView.Social.Items>
                        </FooterView.Social>
                    </FooterView.Col>
                </FooterView.Row>
            </FooterView.Content>
            <FooterView.Copyrights />
        </FooterView>
    );
};

export default Footer;
