import clsx from "clsx";
import React from "react";
import { Range as RangeSlider, RangeProps as RangeSliderProps } from "rc-slider";
import { CommonComponentProps } from "../types";

export type RangeProps = RangeSliderProps & Partial<{}>;

const Range: React.FunctionComponent<CommonComponentProps<RangeProps>> = ({
    className,
    style,
    ...other
}: CommonComponentProps<RangeProps>) => {
    const classes = clsx({
        [className ?? ""]: !!className,
    });

    return <RangeSlider className={classes} style={style} min={0} max={5} defaultValue={[0, 5]} {...other} />;
};

export default Range;
