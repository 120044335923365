import React from "react";
import { CommonComponentProps } from "../../types";
import InputMask from "react-input-mask";
import LockedInput, { LockedInputProps } from "../LockedInput";

export type PhoneInputProps = React.InputHTMLAttributes<HTMLInputElement> & LockedInputProps;

const PhoneInput: React.FunctionComponent<CommonComponentProps<PhoneInputProps>> = ({
    className,
    style,
    ...other
}: CommonComponentProps<PhoneInputProps>) => {
    return (
        <InputMask mask="+9 (999) 999-99-99" value={other.value} onChange={other.onChange} {...other}>
            {(inputProps) => (
                <LockedInput {...inputProps} type="tel" disableUnderline className={className} style={style} />
            )}
        </InputMask>
    );
};

export default PhoneInput;
