import { Exclude, Expose } from "class-transformer";

@Exclude()
class Breadcrumb {
    @Expose() id: number;

    @Expose() title: string;

    @Expose() slug: string;
}

export default Breadcrumb;
