import React from "react";
import useTheme from "@/modules/hooks/useTheme";
import { ThemeLogo } from "@/modules/theme";

const Loader = () => {
    const { currentTheme } = useTheme();

    const LogoComponent = ThemeLogo[currentTheme];

    return (
        <>
            <div className="loader-overlay" />
            <div className="loader">
                <LogoComponent />
            </div>
        </>
    );
};

export default Loader;
