import React, { PropsWithChildren } from "react";

type ShowAllProps = PropsWithChildren<Partial<{ href: string }>>;

const ShowAll: React.FC<ShowAllProps> = ({ children, href }: ShowAllProps) => {
    return (
        <a className="popmenu-showall btnlink" href={href}>
            {children}
        </a>
    );
};

export default ShowAll;
