import { Favorite } from "@/api/services/favorite/models";
import { StoreonModule } from "storeon";

export interface IFavouriteItem {
    entityId: number;
}

export interface IFavouriteModuleState {
    favouriteItems: Favorite[];
}

export interface IFavouriteModuleEvents {
    "favourite/set": Favorite[];
}

export const favouriteModule: StoreonModule<IFavouriteModuleState, IFavouriteModuleEvents> = (store) => {
    store.on("@init", () => ({
        favouriteItems: [],
    }));

    store.on("favourite/set", (state, favouriteItems) => ({
        favouriteItems,
    }));
};
