import { Page } from "@/api/services/common/models";
import { StoreonModule } from "storeon";

export interface PageModuleState {
    page: Page | null;
}

export interface PageModuleEvents {
    "page/set": Page | null;
}

export const pageModule: StoreonModule<PageModuleState, PageModuleEvents> = (store) => {
    store.on("@init", () => ({
        page: null,
    }));

    store.on("page/set", (state, page) => ({
        page,
    }));
};
