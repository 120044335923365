import React, { PropsWithChildren } from "react";

import Size from "./Size";
import Label from "./Label";
import Row from "./Row";
import Link from "./Link";
import Items from "./Items";

const Sizes: React.FC<unknown> = ({ children }: PropsWithChildren<unknown>) => {
    return <div className="product-sizes">{children}</div>;
};

export default Object.assign(Sizes, { Size, Label, Row, Link, Items });
