import { StoreonModule } from "storeon";
import { CartProductSKU } from "@/api/services/cart/models/CartProduct.model";

export interface ICartItem {
    quantity?: number;
    sku: number;
}

export interface ICartModuleState {
    cartItems: {
        [key: string]: ICartItem;
    };
    productItems: CartProductSKU[];
    defaultCartItems: CartProductSKU[];
}

export interface ICartModuleEvents {
    "cart/set": any;
    "cart/remove": any;
    "cart/load": any;
    "cart/clear": any;
    "product/set": any;
    "defaultProduct/set": any;
}

export const cartModule: StoreonModule<ICartModuleState, ICartModuleEvents> = (store) => {
    store.on("@init", (state) => {
        return {
            defaultCartItems: [],
            productItems: [],
            cartItems: {},
            promo: 0,
        };
    });
    store.on("cart/clear", (state) => ({
        ...state,
        cartItems: {},
    }));
    store.on("cart/load", (state, newCartItems: { [key: string]: ICartItem }) => {
        return {
            ...state,
            cartItems: newCartItems,
        };
    });
    store.on("cart/set", (state, { sku, ...obj }: ICartItem) => {
        return {
            ...state,
            cartItems: {
                ...state.cartItems,
                [sku]: { ...state.cartItems[sku], ...obj, sku },
            },
        };
    });
    store.on("cart/remove", (state, entityId: number) => {
        delete state.cartItems[entityId];
        return {
            ...state,
            cartItems: { ...state.cartItems },
            productItems: state.productItems.filter((product) => product.id !== entityId),
        };
    });
    store.on("product/set", (state, payload: CartProductSKU[]) => {
        return {
            ...state,
            productItems: [...payload],
        };
    });
    store.on("defaultProduct/set", (state, payload: CartProductSKU[]) => {
        return {
            ...state,
            defaultCartItems: [...payload],
        };
    });
};
