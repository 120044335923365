import React, { useCallback } from "react";
import ProductCategoryView from "@/views/ProductCategory";
import { useQuery } from "react-query";
import { catalogApiService } from "@/api/services";
import ProductCard from "../Catalog/ProductCard";

export type ProductRecommendationsProps = { id: number };

const ProductRecommendations: React.FC<ProductRecommendationsProps> = ({
    id: entityId,
}: ProductRecommendationsProps) => {
    const { data: product } = useQuery(["product", entityId], () => catalogApiService.getProduct(entityId), {
        enabled: !!entityId,
    });

    const { data: products, isFetching } = useQuery(
        ["recommendations", product?.attr.relatedProducts?.id],
        () => catalogApiService.getProducts(/*{ ids_list: product?.recommendationsIds.join(",") }*/),
        { enabled: !!product?.attr.relatedProducts?.id.length }
    );

    const getRecommendProducts = useCallback(() => {
        if (products && product) {
            const productsResults = products.results;
            const mask: Array<string | number> | undefined = product.attr.relatedProducts?.id;
            if (productsResults) {
                return productsResults.filter((item) => mask && mask.includes(String(item.idCore)));
            }
        }
    }, [product, products]);

    if (isFetching || !products) return null;

    return (
        <ProductCategoryView>
            <ProductCategoryView.Header title="С этим товаром покупают" />

            <ProductCategoryView.Slider>
                <ProductCategoryView.Carousel
                    items={getRecommendProducts() || []}
                    renderSlide={(product) => <ProductCard product={product} />}
                />
            </ProductCategoryView.Slider>
        </ProductCategoryView>
    );
};

export default ProductRecommendations;
