import React, { useEffect, useState } from "react";
import useTabs from "@/modules/hooks/useTabs";
import ProductDetailView from "@/views/ProductDetail";
import TechnologiesView from "@/views/Technologies";
import { InnerHtml, Pagination, Tab, TabPanel, Tabs, TabsContent, TabsGroup } from "../Core";
import { useQuery } from "react-query";
import { catalogApiService, entitiesApiService } from "@/api/services";
import { TechnologySchema } from "@/api/services/entities/schemas";
import { ColorEntity, TechnologyEntity } from "@/api/services/entities/entities";
import { EntityFetcher } from "../Controls";
import { getMinimalDetailPath } from "@/modules/utils";
import { useAuth, useModal, usePage, usePagination } from "@/modules/hooks";
import { ProductReview } from "@/api/services/catalog/models";
import useQuantities from "@/modules/hooks/useQuantities";

export type ProductDetailProps = { id: number };

const ProductDetail: React.FC<ProductDetailProps> = ({ id: entityId }: ProductDetailProps) => {
    const { page: currentPage } = usePage();
    const config = currentPage?.config;
    const [colorMask, setColorMask] = useState<{ [key: string]: string }>();

    const { isAuthenticated } = useAuth();
    const { handleOpenModal } = useModal();
    const { balanceTree, availableInShop } = useQuantities({ entityId });

    const { activeTab, handleChangeTab } = useTabs<["specs", "reviews", "sizes", "techs", "availability", "info"]>({
        initialTab: "specs",
    });

    const { data: product } = useQuery(["product", entityId], () => catalogApiService.getProduct(entityId), {
        enabled: !!entityId,
    });

    const { page, pageSize, handleChangePage, params } = usePagination({ initialPageSize: 10 });
    const { data: reviews } = useQuery(
        ["reviews", entityId, page, pageSize],
        () =>
            catalogApiService.getProductReviews({ product: entityId, page, page_size: pageSize, moderate: "approve" }),
        { enabled: !!entityId }
    );

    const { data: colors } = useQuery(
        ["colors"],
        () => {
            if (product) {
                const colorIds = product && product.skus.map((sku) => String(sku.attr.color?.id));
                return entitiesApiService.getTransformedEntities(ColorEntity, {
                    et_id: entityId,
                    ids_list: colorIds.join(",") || "",
                });
            }
        },
        { enabled: !!product }
    );
    const openReviewModal = () => handleOpenModal("REVIEW", { entityId });

    useEffect(() => {
        if (colors) {
            const mask = colors.items.reduce((acc: any, item) => {
                acc[item.title] = item.hex;
                return acc;
            }, {});
            setColorMask(mask);
        }
    }, [colors]);

    if (!product) {
        return null;
    }

    return (
        <ProductDetailView>
            <Tabs fullWidth>
                <TabsGroup variants={["btabs", "producttabs"]}>
                    {product.attr.html?.value && (
                        <Tab id="specs" variant="producttab" activeTab={activeTab} onChange={handleChangeTab} wrap>
                            описание и характеристики
                        </Tab>
                    )}
                    <Tab id="reviews" variant="producttab" activeTab={activeTab} onChange={handleChangeTab} wrap>
                        отзывы {reviews ? `(${reviews.count})` : null}
                    </Tab>
                    {product.attr.sizeTable?.value && (
                        <Tab id="sizes" variant="producttab" activeTab={activeTab} onChange={handleChangeTab} wrap>
                            размерная таблица
                        </Tab>
                    )}
                    {!!product.attr.technologies?.id.length && (
                        <Tab id="techs" variant="producttab" activeTab={activeTab} onChange={handleChangeTab} wrap>
                            технологии
                        </Tab>
                    )}
                    {!!availableInShop?.length && (
                        <Tab
                            id="availability"
                            variant="producttab"
                            activeTab={activeTab}
                            onChange={handleChangeTab}
                            wrap
                        >
                            наличие в розничной сети
                        </Tab>
                    )}
                    {product.attr.usefulInformation && (
                        <Tab id="info" variant="producttab" activeTab={activeTab} onChange={handleChangeTab} wrap>
                            полезная информация
                        </Tab>
                    )}
                </TabsGroup>
                <TabsContent>
                    <TabPanel id="specs" activeTab={activeTab}>
                        {product?.attr.html?.value && (
                            <ProductDetailView.Description>
                                <ProductDetailView.Description.Title>Описание</ProductDetailView.Description.Title>
                                <ProductDetailView.Description.Text>
                                    <InnerHtml html={product?.attr.html?.value} />
                                </ProductDetailView.Description.Text>
                            </ProductDetailView.Description>
                        )}
                    </TabPanel>
                    <TabPanel id="reviews" activeTab={activeTab}>
                        {isAuthenticated ? <ProductDetailView.Review.Add onClick={openReviewModal} /> : null}

                        {reviews?.results.map((review: ProductReview) => (
                            <ProductDetailView.Review.Card
                                key={review.id}
                                name={review.user.firstName}
                                city={review.user.address?.city}
                                date={review.createdAt}
                                stars={review.rates}
                                text={review.text}
                            />
                        ))}

                        <Pagination
                            count={reviews?.count}
                            page={page}
                            pageSize={pageSize}
                            onChange={handleChangePage}
                            params={params}
                            pageQueryParam=""
                            pageSizeQueryParam=""
                        />
                    </TabPanel>
                    <TabPanel id="sizes" activeTab={activeTab}>
                        {/*<ProductDetailView.Availability>*/}
                        {/*    <ProductDetailView.Availability.Table />*/}
                        {/*</ProductDetailView.Availability>*/}
                        <InnerHtml html={product?.attr.sizeTable?.value} />
                    </TabPanel>
                    <TabPanel id="techs" activeTab={activeTab}>
                        <EntityFetcher<TechnologySchema, TechnologyEntity>
                            Model={TechnologyEntity}
                            queryKey="techs"
                            idsList={product?.attr.technologies?.id.map((id) => String(id)) || []}
                        >
                            {({ items }) => (
                                <>
                                    {items.map((technology) => {
                                        const href = getMinimalDetailPath(
                                            config?.pages.technologyDetailUrl ?? "",
                                            technology.id
                                        );

                                        return (
                                            <TechnologiesView.Card
                                                key={technology.id}
                                                href={href}
                                                title={technology.title}
                                                image={technology.image}
                                                text={technology.summary}
                                                tag={technology.tag}
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </EntityFetcher>
                    </TabPanel>
                    <TabPanel id="availability" activeTab={activeTab}>
                        {balanceTree && (
                            <ProductDetailView.Availability>
                                {Object.keys(balanceTree).map((key) => {
                                    return (
                                        <ProductDetailView.Availability.WebTable
                                            key={key}
                                            skus={balanceTree[key]}
                                            colorMask={colorMask}
                                            title={key}
                                        />
                                    );
                                })}
                            </ProductDetailView.Availability>
                        )}
                    </TabPanel>
                    <TabPanel id="info" activeTab={activeTab}>
                        {product.attr.usefulInformation?.value.map((html, i) => (
                            <InnerHtml html={html} key={product?.attr.usefulInformation?.id[i]} />
                        ))}
                    </TabPanel>
                </TabsContent>
            </Tabs>
        </ProductDetailView>
    );
};

export default ProductDetail;
