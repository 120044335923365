import React from "react";

type TitleProps = Partial<{
    title: string;
}>;

const Title: React.FC<TitleProps> = ({ title }: TitleProps) => {
    return <div>{title}</div>;
};

export default Title;
