import clsx from "clsx";
import React from "react";
import { OverridableComponentProps, OverridableComponent } from "../types";

export type SwitchboxProps = Partial<{}>;

type SwitchboxComponent = OverridableComponent<SwitchboxProps>;

export type SwitchboxComponentProps = React.InputHTMLAttributes<HTMLInputElement> &
    OverridableComponentProps<SwitchboxComponent>;

const Switchbox: React.FunctionComponent<SwitchboxComponentProps> = ({
    id = "example",
    component: Component = "div",
    children,
    className,
    style,
    ...other
}: SwitchboxComponentProps) => {
    const classes = clsx("form-switchbox", {
        [className ?? ""]: !!className,
    });

    return (
        <Component className={classes} style={style}>
            <input className="form-switchbox__input" type="checkbox" id={id} {...other} />
            <label className="form-switchbox__label" htmlFor={id}>
                {children}
            </label>
        </Component>
    );
};

export default Switchbox;
