import { Entity } from "../models";
import { ActivitySchema } from "../schemas";
import EntityModel from "./EntityModel";

class ActivityEntity extends EntityModel<ActivitySchema> {
    readonly title: string;
    readonly image: string;
    readonly url: string;

    constructor(entity: Entity<ActivitySchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.image = entity.getFirstAttribute("image")?.getFirstValue()?.display || "";
        this.url = entity.getFirstAttribute("url")?.getFirstValue()?.value || "";
    }
}

export default ActivityEntity;
