import { Exclude, Expose, Transform, Type } from "class-transformer";
import dayjs from "@/modules/utils/dayjs";

@Exclude()
class Address {
    @Expose() city: string;
}

@Exclude()
class User {
    @Expose({ name: "first_name" }) firstName: string;

    @Expose()
    @Type(() => Address)
    address?: Address;
}

@Exclude()
class ProductReview {
    @Expose() id: number;

    @Expose({ name: "product" }) productId: number;

    @Expose() text: string;

    @Expose() moderate: "new" | "wait" | "approve";

    @Expose() rates: number;

    @Expose({ name: "user_obj" })
    @Type(() => User)
    user: User;

    @Expose({ name: "created_at" })
    @Transform(({ value }) => dayjs(value).format("D MMMM YYYY, HH:mm"))
    createdAt: string;
}

export default ProductReview;
