import React from "react";
import clsx from "clsx";
import { CommonComponentProps } from "../../types";

export type ModalContentProps = Partial<{}>;

const ModalContent: React.FC<CommonComponentProps<ModalContentProps>> = ({
    className,
    children,
}: CommonComponentProps<ModalContentProps>) => {
    const classes = clsx("modal-content", {
        [className ?? ""]: !!className,
    });

    return <div className={classes}>{children}</div>;
};

export default ModalContent;
