import { InnerHtml } from "@/components/Core";
import Modal from "@/components/Core/Modal";
import { WithAppModalComponentProps } from "@/modules/modals";
import React from "react";

export type TableModalProps = WithAppModalComponentProps<Partial<{ html: string }>>;

const TableModal: React.FunctionComponent<TableModalProps> = ({ onClose, html }: TableModalProps) => {
    return (
        <Modal.Content>
            <Modal.Header onClose={onClose} />
            <Modal.Body>
                <InnerHtml html={html} />
            </Modal.Body>
        </Modal.Content>
    );
};

export default TableModal;
