import { OverridableComponent, OverridableComponentProps } from "@/components/Core";
import clsx from "clsx";
import React from "react";

type AlertProps = {};

type AlertComponent = OverridableComponent<AlertProps>;

export type AlertComponentProps = OverridableComponentProps<AlertComponent>;

const Alert: React.FC<AlertComponentProps> = ({ children, className, style }: AlertComponentProps) => {
    const classes = clsx("alert", { [className ?? ""]: !!className });

    return (
        <div className={classes} style={style}>
            {children}
        </div>
    );
};

export default Alert;
