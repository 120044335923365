import { userApiService } from "@/api/services";
import { Button, FormGroup, FormLabel, FormHelperText, Input } from "@/components/Core";
import { Formik, Form } from "formik";
import { Modal } from "@/components/Core";
import { WithAppModalComponentProps } from "@/modules/modals";
import AuthView from "@/views/Auth";
import React from "react";
import * as Yup from "yup";
import { useModal } from "@/modules/hooks";

interface IForgotPassword {
    email: string;
    non_field?: string;
}

const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Пожалуйста, укажите ваше Email"),
});

const ForgotPassword: React.FunctionComponent<WithAppModalComponentProps> = ({
    onClose,
}: WithAppModalComponentProps) => {
    const { handleOpenModal, handleCloseModal } = useModal();

    const onSubmit = async (values, actions) => {
        try {
            await userApiService.restorePassword(values);
            actions.setStatus("success");
            actions.setFieldValue("email", "");
            actions.setFieldTouched("email", false);
        } catch (err: any) {
            const { data } = err?.response;
            actions.setErrors(data);
        }
    };

    const handleLogin = () => {
        handleOpenModal("SIGNUP_AND_SIGNIN");
        handleCloseModal("FORGOT_PASSWORD");
    };
    return (
        <Modal.Content>
            <Modal.Header onClose={onClose} />
            <AuthView>
                <AuthView.Cover />
                <AuthView.Content>
                    <Formik<IForgotPassword>
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={ForgotPasswordSchema}
                        onSubmit={onSubmit}
                        validateOnMount
                    >
                        {({ touched, errors, handleChange, values, isSubmitting, status }) => {
                            return (
                                <Form className="form mlogin-tabs__form">
                                    <FormGroup error={touched.email && !!errors.email}>
                                        <FormLabel required>Email</FormLabel>
                                        <Input
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            placeholder="Введите свой Email"
                                        />
                                        <FormHelperText variant="error">{touched.email && errors.email}</FormHelperText>
                                    </FormGroup>
                                    {errors.non_field ? (
                                        <FormGroup>
                                            <FormHelperText variant="error">{errors.non_field}</FormHelperText>
                                        </FormGroup>
                                    ) : null}
                                    {status === "success" ? (
                                        <FormGroup>
                                            <FormHelperText>
                                                Операция прошла успешно! Проверьте пожалуйста почту.
                                            </FormHelperText>
                                        </FormGroup>
                                    ) : null}

                                    <FormGroup flex="rc">
                                        <a className="link" href="!#" onClick={handleLogin}>
                                            Войти
                                        </a>
                                    </FormGroup>
                                    <FormGroup end>
                                        <Button fullWidth type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? "..." : "Отправить"}
                                        </Button>
                                    </FormGroup>
                                </Form>
                            );
                        }}
                    </Formik>
                </AuthView.Content>
            </AuthView>
        </Modal.Content>
    );
};

export default ForgotPassword;
