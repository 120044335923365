import { Exclude, Type, Expose } from "class-transformer";
import ComponentProp from "./ComponentProp.model";

@Exclude()
class Component {
    @Expose() id: number;

    @Expose({ name: "entity_type_id" }) entityTypeId: number;

    @Expose() title: string;

    @Expose() type: {
        id: number;
        title: string;
    };

    @Expose() page: number;

    @Expose() sort: number;

    @Type(() => ComponentProp)
    @Expose({ name: "kids" })
    children: ComponentProp[];
}

export default Component;
