import React, { PropsWithChildren } from "react";

import Row from "./Row";
import Col from "./Col";
import Title from "./Title";
import Contacts from "./Contacts";
import Copyrights from "./Copyrights";
import Menu from "./Menu";
import Social from "./Social";
import Subscribe from "./Subscribe";
import Content from "./ContentWrapper";
import Buttons from "./Buttons";

export type WrapperProps = PropsWithChildren<{}>;

const Wrapper = ({ children }: WrapperProps) => {
    return <footer className="footer">{children}</footer>;
};

export default Object.assign(Wrapper, {
    Row,
    Col,
    Title,
    Contacts,
    Copyrights,
    Menu,
    Social,
    Subscribe,
    Content,
    Buttons,
});
