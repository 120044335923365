import React from "react";

export type ColorsProps = Partial<{ colors: string[] }>;

const Colors: React.FunctionComponent<ColorsProps> = ({ colors }: ColorsProps) => {
    return (
        <div className="catcard__colors">
            {colors?.map((color) => (
                <div key={color} className="catcard__color" style={{ background: color }} />
            ))}
        </div>
    );
};

export default Colors;
