import React, { PropsWithChildren } from "react";

export type LabelProps = PropsWithChildren<unknown>;

const Label: React.FC<LabelProps> = ({ children }: LabelProps) => {
    return (
        <div className="catlabels-new">
            <span>{children}</span>
        </div>
    );
};

export default Label;
