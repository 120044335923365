import React, { useState } from "react";
import clsx from "clsx";
import { CommonComponentProps } from "../../types";

export type LockedInputProps = React.InputHTMLAttributes<HTMLInputElement> &
    Partial<{ onChangeLockStatus(locked: boolean): void }>;

const LockedInput: React.FunctionComponent<CommonComponentProps<LockedInputProps>> = ({
    className,
    onChangeLockStatus,
    ...other
}: CommonComponentProps<LockedInputProps>) => {
    const [editing, setEditing] = useState<boolean>(false);

    const handleButtonClick = () => {
        setEditing((prev) => {
            onChangeLockStatus && onChangeLockStatus(!prev);
            return !prev;
        });
    };

    const wrapClasses = clsx("form-inputwrap", {
        [className ?? ""]: !!className,
    });

    const inputClasses = clsx("form-input", {
        [className ?? ""]: !!className,
    });

    return (
        <div className={wrapClasses}>
            <input className={inputClasses} disabled={!editing} {...other} />
            <button className="edit-button" type="button" onClick={handleButtonClick} />
        </div>
    );
};

export default LockedInput;
