import * as React from "react";

function SvgCart(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M19.6955 3.10924C19.5087 2.89439 19.2401 2.76939 18.956 2.76939H5.15341C4.61626 2.76939 4.18031 3.2069 4.18031 3.74596C4.18031 4.28503 4.61626 4.72253 5.15341 4.72253H17.8194L16.9708 10.2343C16.8774 10.8515 16.3558 11.3007 15.733 11.3007H3.54584L1.93438 0.82797C1.85263 0.292809 1.3544 -0.0704753 0.825033 0.0115566C0.291771 0.0935885 -0.0702254 0.593593 0.0115156 1.12485L2.2302 15.5312C2.31583 16.0859 2.59609 16.5937 3.02036 16.9609C3.44464 17.3281 3.98958 17.5273 4.5462 17.5273H6.07203V19.0234C6.07203 19.5625 6.50798 20 7.04514 20C7.58229 20 8.01824 19.5625 8.01824 19.0234V17.5273H13.709V19.0234C13.709 19.5625 14.1449 20 14.6821 20C15.2192 20 15.6552 19.5625 15.6552 19.0234V17.5273H17.5313C18.0685 17.5273 18.5044 17.0898 18.5044 16.5508C18.5044 16.0117 18.0685 15.5742 17.5313 15.5742H4.5462C4.34768 15.5742 4.18031 15.4297 4.14917 15.2343L3.84556 13.2539H15.733C16.496 13.2539 17.2355 12.9804 17.8155 12.4804C18.3955 11.9804 18.7769 11.289 18.8937 10.5351L19.9174 3.8944C19.9602 3.60924 19.8785 3.32408 19.6955 3.10924Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={20} height={20} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

const MemoSvgCart = React.memo(SvgCart);
export default MemoSvgCart;
