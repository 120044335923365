import { Theme } from "./types";

import { Logo as MainLogo } from "@/icons/Logo";
import { LogoBd } from "@/icons/Logo-BD";

export const DefaultTheme: Theme = Theme.MAIN;

export const ThemeLogo: Record<Theme, Custom.SvgIcon> = {
    MAIN: MainLogo,
    BLACK_DIAMOND: LogoBd,
};

export const DefaultLogo = ThemeLogo.MAIN;

export const ThemeFaviconPath: Record<Theme, string> = {
    MAIN: "",
    BLACK_DIAMOND: "black_diamond",
};
