import { groupByKey } from "@/modules/utils";
import { Exclude, Expose, Transform, Type } from "class-transformer";

@Exclude()
export class AttributeValue {
    @Expose({ name: "e_id" })
    @Transform(({ value }) => +value)
    entityId: number;

    @Expose({ name: "value_id" }) id: number;

    @Expose() display: string;

    @Expose() value: string;

    @Expose() sorting: number | null;
}

@Exclude()
export class EntityAttribute {
    @Expose({ name: "attr_id" }) id: number;

    @Expose({ name: "attr_display_name" }) displayName: string;

    @Expose({ name: "attr_name" }) name: string;

    @Expose({ name: "atype_id" }) atypeId: number;

    @Expose({ name: "composite_choice" }) isComposite: boolean;

    @Expose({ name: "multiple_choice" }) isMultiple: boolean;

    @Expose()
    @Type(() => AttributeValue)
    @Transform(({ value }) => value ?? [])
    values: AttributeValue[];

    @Expose()
    getFirstValue = (): Custom.Maybe<AttributeValue> => {
        return this.values.length ? this.values[0] : null;
    };
}

@Exclude()
class Price {
    @Expose({ name: "rrc_price" }) rrcPrice: number;
    @Expose({ name: "sale_price" }) salePrice: number;
}

@Exclude()
class Entity<Schema = any> {
    @Expose() id: number;

    @Expose({ name: "et_id" }) etypeId: number;

    @Expose() type: string;

    @Expose()
    @Type(() => EntityAttribute)
    @Transform(({ value }: { value: EntityAttribute[] }) => {
        return groupByKey(value, "displayName");
    })
    attrs: Record<keyof Schema, EntityAttribute[]>;

    @Expose() labels: any;
    @Expose() category: any;
    @Expose() attr: any;
    @Type(() => Price)
    @Expose()
    price: Price;

    @Expose()
    getFirstAttribute = (key?: keyof Schema): Custom.Maybe<EntityAttribute> => {
        const attribute = key ? this.attrs[key] : Object.values<EntityAttribute[]>(this.attrs)[0];
        return attribute ? attribute[0] : null;
    };
    @Expose()
    getAttribute = (key?: keyof Schema, number = 0): Custom.Maybe<EntityAttribute> => {
        const attribute = key ? this.attrs[key] : Object.values<EntityAttribute[]>(this.attrs)[number];
        return attribute ? attribute[number] : null;
    };
}

export default Entity;
