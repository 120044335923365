import Http, { HttpOptions, HttpResponse } from "@/api/http";
import { ClassConstructor, ClassTransformOptions, plainToInstance, plainToClassFromExist } from "class-transformer";
import "reflect-metadata";

interface IApiService {
    http: Http;
}

interface IApiServiceHelper {
    download(response: HttpResponse, fileName: string): void;
}

abstract class BaseApiService implements IApiService, IApiServiceHelper {
    http: Http;

    constructor(config: HttpOptions) {
        this.http = Http(config);
    }

    download = (response: HttpResponse, fileName: string) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    plainToClass<M, V = {}>(Model: ClassConstructor<M>, data: V, options?: ClassTransformOptions): M {
        return plainToInstance<M, V>(Model, data, options);
    }

    plainToClassArray<M, V = {}>(Model: ClassConstructor<M>, data: V[], options?: ClassTransformOptions): M[] {
        return plainToInstance<M, V>(Model, data, options);
    }

    plainToClassFromExist<M, V = unknown>(Model: M, data: V): M {
        return plainToClassFromExist<M, unknown>(Model, data);
    }

    getFirstItemOrNull = <T>(items: T[]) => {
        if (!items) return null;
        return items.length ? items[0] : null;
    };
}

export default BaseApiService;
