import * as React from "react";

function SvgComparison(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={15} height={20} viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.1641 15.5977C13.7031 15.5977 14.1406 15.1602 14.1406 14.6211V3.33203C14.1406 1.49609 12.6445 0 10.8086 0H3.33203C1.49609 0 0 1.49609 0 3.33203V19.0234C0 19.3789 0.195312 19.7109 0.507812 19.8789C0.820312 20.0508 1.20312 20.0352 1.50391 19.8438L7.07031 16.2773L12.6367 19.8438C12.8008 19.9492 12.9805 20 13.1641 20C13.4844 20 13.8008 19.8398 13.9883 19.5508C14.2812 19.0977 14.1484 18.4922 13.6914 18.2031L7.59766 14.2969C7.27734 14.0898 6.86328 14.0898 6.54297 14.2969L1.95312 17.2383V3.33203C1.95312 2.57422 2.57031 1.95312 3.33203 1.95312H10.8125C11.5703 1.95312 12.1914 2.57031 12.1914 3.33203V14.6211C12.1875 15.1602 12.625 15.5977 13.1641 15.5977Z"
                fill="currentColor"
            />
            <path
                d="M7.07037 10.2656C7.60944 10.2656 8.04694 9.82813 8.04694 9.28906V8.02734H9.30865C9.84772 8.02734 10.2852 7.58984 10.2852 7.05078C10.2852 6.51172 9.84772 6.07422 9.30865 6.07422H8.04694V4.8125C8.04694 4.27344 7.60944 3.83594 7.07037 3.83594C6.53131 3.83594 6.09381 4.27344 6.09381 4.8125V6.07422H4.83209C4.29303 6.07422 3.85553 6.51172 3.85553 7.05078C3.85553 7.58984 4.29303 8.02734 4.83209 8.02734H6.09381V9.28906C6.09381 9.82813 6.53131 10.2656 7.07037 10.2656Z"
                fill="currentColor"
            />
        </svg>
    );
}

const MemoSvgComparison = React.memo(SvgComparison);
export default MemoSvgComparison;
