import { CommonComponentProps } from "@/components/Core";
import React from "react";

type SectionContainerProps = CommonComponentProps<unknown>;

const SectionContainer: React.FC<SectionContainerProps> = ({ children, className, style }: SectionContainerProps) => {
    return (
        <section className={className} style={style}>
            <div className="container">{children}</div>
        </section>
    );
};

export default SectionContainer;
