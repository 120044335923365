import { Button, Input } from "@/components/Core";
import React from "react";

const Form: React.FunctionComponent<unknown> = () => {
    return (
        <div className="footer-subscribe__form">
            <Input className="footer-subscribe__input" placeholder="Ваш e-mail" />
            <Button className="footer-subscribe__btn">Подписаться</Button>
        </div>
    );
};

export default Form;
