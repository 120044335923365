import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { LocalStrategyAuthDto } from "./dto";
import { Config } from "@/modules/constants";
import { AuthResult, RefreshResult } from "./models";

class AuthApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    login = async (localStrategyLoginDto: LocalStrategyAuthDto): Promise<AuthResult> => {
        const { data } = await this.http.post("/auth/login/", localStrategyLoginDto);

        return this.plainToClass(AuthResult, data);
    };

    refresh = async (refreshToken: string): Promise<RefreshResult> => {
        const { data } = await this.http.post("/auth/refresh/", { refresh_token: refreshToken });

        return this.plainToClass(RefreshResult, data);
    };

    logout = async (): Promise<boolean> => {
        const { data } = await this.http.post("/auth/logout/");

        return data.result;
    };
}

export default new AuthApiService({
    baseURL: Config.CMS_API_ROOT_URL,
});
