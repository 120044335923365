import { Button, FormGroup, FormLabel, FormHelperText, Input, PasswordInput } from "@/components/Core";
import { Formik, FormikProps, Form, FormikConfig } from "formik";
import * as Yup from "yup";
import React, { forwardRef } from "react";
import useModal from "@/modules/hooks/useModal";

interface LoginUser {
    username: string;
    password: string;
    non_field_errors: string[];
}

const LoginSchema = Yup.object().shape({
    username: Yup.string().email("Указан неверный Email").required("Пожалуйста, укажите ваш Email"),
    password: Yup.string().required("Пожалуйста, укажите пароль"),
    non_field_errors: Yup.array().of(Yup.string()).optional(),
});

export type LoginFormProps = Pick<FormikConfig<LoginUser>, "onSubmit">;

const LoginForm = forwardRef<FormikProps<LoginUser>, LoginFormProps>(({ onSubmit }: LoginFormProps, ref) => {
    const { handleOpenModal, handleCloseModal } = useModal();

    const handleShowForgotPassword = (e) => {
        e.preventDefault();
        handleOpenModal("FORGOT_PASSWORD");
        handleCloseModal("SIGNUP_AND_SIGNIN");
    };
    return (
        <Formik<LoginUser>
            initialValues={{
                username: "",
                password: "",
                non_field_errors: [],
            }}
            innerRef={ref}
            validationSchema={LoginSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, isSubmitting }) => {
                return (
                    <Form className="form mlogin-tabs__form">
                        <FormGroup error={touched.username && !!errors.username}>
                            <FormLabel required>Email</FormLabel>
                            <Input
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                placeholder="Введите свой Email"
                            />
                            <FormHelperText variant="error">{touched.username && errors.username}</FormHelperText>
                        </FormGroup>
                        <FormGroup error={touched.password && !!errors.password}>
                            <FormLabel required>Пароль</FormLabel>
                            <PasswordInput
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                placeholder="Введите свой пароль"
                            />
                            <FormHelperText variant="error">{touched.password && errors.password}</FormHelperText>
                        </FormGroup>

                        {errors.non_field_errors ? (
                            <FormGroup>
                                <FormHelperText variant="error">{errors.non_field_errors}</FormHelperText>
                            </FormGroup>
                        ) : null}

                        <FormGroup flex="rc">
                            <a className="link" href="!#" onClick={handleShowForgotPassword}>
                                Забыли пароль?
                            </a>
                        </FormGroup>
                        <FormGroup end>
                            <Button fullWidth type="submit" disabled={isSubmitting}>
                                {isSubmitting ? "Входим..." : "Войти"}
                            </Button>
                        </FormGroup>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default LoginForm;
