import React from "react";
import MasterModal from "@/components/Core/Modal";
import { useStoreon } from "@/store";
import useModal from "@/modules/hooks/useModal";

const Modal: React.FC = () => {
    const { modals } = useStoreon("modals");
    const { handleCloseModal } = useModal();

    if (!modals.length) return null;

    return (
        <>
            {modals.map((modal) => {
                return (
                    <MasterModal
                        key={modal.type}
                        show={modal.open}
                        onHide={() => handleCloseModal(modal.type)}
                        {...modal.options}
                    >
                        <modal.component
                            modalType={modal.type}
                            onClose={() => handleCloseModal(modal.type)}
                            {...modal.props}
                        />
                    </MasterModal>
                );
            })}
        </>
    );
};

export default Modal;
