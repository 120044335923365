import * as React from "react";

function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M0.714039 9.24242C0.319686 9.24242 0 9.56211 0 9.95646C0 10.3493 0.317348 10.6684 0.710194 10.6705L16.8831 10.7576L13.0348 14.3493C12.7017 14.6602 12.7017 15.1883 13.0348 15.4992C13.337 15.7812 13.8059 15.7812 14.108 15.4992L19.6084 10.3655C19.8202 10.1679 19.8202 9.83214 19.6084 9.63447L14.108 4.50083C13.8059 4.21882 13.337 4.21882 13.0348 4.50083C12.7017 4.81174 12.7017 5.33978 13.0348 5.65069L16.8831 9.24242H0.714039Z"
                fill="currentColor"
            />
        </svg>
    );
}

const MemoSvgArrowRight = React.memo(SvgArrowRight);
export default MemoSvgArrowRight;
