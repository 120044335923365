import { Exclude, Expose } from "class-transformer";

@Exclude()
class Shop {
    @Expose() id: number;

    @Expose() title: string;

    @Expose() city: number;

    @Expose() address: string;

    @Expose() comment: string;

    @Expose({ name: "how_get_there" }) howGetThere: string;

    @Expose() phones: string[];

    @Expose({ name: "work_hours" }) workHours: string;

    @Expose() coordinates: number[];
}

export default Shop;
