import React, { PropsWithChildren } from "react";

import Row from "./Row";
import Col from "./Col";

export type MobilePanelProps = PropsWithChildren<unknown>;

const MobilePanel: React.FC<MobilePanelProps> = ({ children }: MobilePanelProps) => {
    return <div className="header-mobpanel">{children}</div>;
};

export default Object.assign(MobilePanel, { Row, Col });
