import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import clsx from "clsx";

SwiperCore.use([Pagination, Navigation]);

export type CarouselProps<Type extends { id: number }> = {
    items: Type[];
    renderSlide(item: Type): React.ReactNode;
    slideVariant?: "single" | "multiple";
};

const Carousel = <Type extends { id: number }>({
    items,
    renderSlide,
    slideVariant = "single",
}: CarouselProps<Type>) => {
    return (
        <>
            <Swiper
                slidesPerView={6}
                spaceBetween={12}
                mousewheel={false}
                watchOverflow
                pagination={{
                    type: "bullets",
                    el: ".catslider-slider .swiper-pagination",
                }}
                navigation={{
                    nextEl: ".catslider-slider .swiper-button-next",
                    prevEl: ".catslider-slider .swiper-button-prev",
                }}
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 6,
                    },
                    520: {
                        slidesPerView: 3,
                        spaceBetween: 6,
                    },
                    769: {
                        slidesPerView: 4,
                        spaceBetween: 12,
                    },
                    900: {
                        slidesPerView: 5,
                        spaceBetween: 12,
                    },
                    1180: {
                        slidesPerView: 4,
                        spaceBetween: 12,
                    },
                    1240: {
                        slidesPerView: 5,
                        spaceBetween: 12,
                    },
                    1641: {
                        slidesPerView: 6,
                        spaceBetween: 12,
                    },
                }}
            >
                {items.map((item, i) => (
                    <SwiperSlide
                        key={i}
                        className={clsx(
                            { ["swiper-slide--single"]: slideVariant === "single" },
                            { ["swiper-slide--multiple"]: slideVariant === "multiple" }
                        )}
                    >
                        {renderSlide(item)}
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-pagination catslider-pagination" />
            <div className="swiper-button-prev catslider-slider-prev" />
            <div className="swiper-button-next catslider-slider-next" />
        </>
    );
};

export default Carousel;
