import React, { PropsWithChildren } from "react";

import Brand from "./Brand";
import Labels from "./Labels";
import Technologies from "./Technologies";

const Name: React.FC = ({ children }: PropsWithChildren<unknown>) => {
    return <div className="product-namebrand">{children}</div>;
};

export default Object.assign(Name, { Brand, Labels, Technologies });
