import React from "react";

type TechnologyProps = Partial<{ image: string; title: string; href: string }>;

const Technology: React.FunctionComponent<TechnologyProps> = ({ image, title, href }: TechnologyProps) => {
    return (
        <a className="product-tech" href={href} title={"Технология: " + title}>
            <img className="product-tech__img" src={image} alt={title} />
        </a>
    );
};

export default Technology;
