import React from "react";
import Modal from "@/components/Core/Modal";
import { AppModalComponentProps, WithAppModalComponentProps } from "@/modules/modals";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";

const ImageModal: React.FunctionComponent<AppModalComponentProps> = ({
    onClose,
    photos = [],
    initialIndex,
}: AppModalComponentProps) => {
    const [swiperKey, setSwiperKey] = useState(0);
    SwiperCore.use([Keyboard, Mousewheel]);

    useEffect(() => {
        setSwiperKey((prevKey) => prevKey + 1);
    }, [initialIndex]);

    return (
        <Modal.Content className="imagemodal-content">
            <Modal.Header onClose={onClose} />
            {/*<Modal.Body className="imagemodal-body">*/}
            <div className="imagemodal-swiper">
                <Swiper
                    key={swiperKey}
                    initialSlide={initialIndex}
                    keyboard={true}
                    spaceBetween={30}
                    effect="fade"
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {photos.map((photo, index) => (
                        <SwiperSlide key={index} className="imagemodal-slide">
                            <img src={photo} alt={`Product ${index}`} className="imagemodal-slide__img" />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/*</Modal.Body>        */}
        </Modal.Content>
    );
};

export default ImageModal;
