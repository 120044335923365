import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { Config } from "@/modules/constants";
import { ProductModel } from "@/api/services/common/models/Product.model";

interface PaymentResponse {
    publicId: string; // Идентификатор платежа
    description: string; // Описание платежа
    amount: string; // Сумма платежа
    currency: string; // Валюта платежа
    invoiceId: string; // Идентификатор счета
    skin: string; // Тематика интерфейса платежа
}

interface PaymentErrorResponse {
    error: string;
}

interface UpdateBonusData {
    bonus_amount: number;
    paid: number;
}
// Объединение типов для метода paymentData
type PaymentApiResponse = PaymentResponse | PaymentErrorResponse;

// class PaymentsApiService extends BaseApiService {
//     constructor(config) {
//         super(config);
//         this.http.interceptors.request.use(TokenInterceptor);
//     }
//
//     paymentData = async (payment_uuid: string): Promise<ProductModel[]> => {
//         const { data } = await this.http.get("/cloudpayments/payment_data/", {
//             params: { payment_uuid },
//         });
//
//         return data;
//     };
// }

class PaymentsApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    paymentData = async (payment_uuid: string): Promise<PaymentApiResponse> => {
        const { data } = await this.http.get("/cloudpayments/payment_data/", {
            params: { payment_uuid },
        });

        return data; // Здесь возвращается объект типа PaymentResponse
    };

    // Метод для обновления бонусной суммы в платеже
    updateOrderPaymentBonus = async (payment_uuid: string, data: UpdateBonusData) => {
        const res = await this.http.patch(`/order/payment/${payment_uuid}/update-bonus/`, data);
        return res.data;
    };
}

export default new PaymentsApiService({
    baseURL: Config.CMS_API_URL,
});
