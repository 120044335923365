import { Exclude, Type, Expose } from "class-transformer";
import PageContext from "./PageContext.model";

export type PageModelType = "Category" | "Product" | "PageShop";

@Exclude()
class IMenuItem {
    @Expose() id: number;
    @Expose() title: string;
    @Expose() url: string;
    @Expose({ name: "target_blank" }) targetBlank: boolean;
    @Expose() parent: number | null;
}

@Exclude()
class Menu {
    @Expose()
    @Type(() => IMenuItem)
    header: IMenuItem[];

    @Expose()
    @Type(() => IMenuItem)
    footer: IMenuItem[];

    @Expose()
    @Type(() => IMenuItem)
    top: IMenuItem[];

    @Expose()
    @Type(() => IMenuItem)
    special: IMenuItem[];

    @Expose()
    @Type(() => IMenuItem)
    information: IMenuItem[];

    @Expose()
    toTree(
        menuType: keyof Omit<this, "toTree">,
        id: number | null = null,
        link: keyof IMenuItem = "parent"
    ): Array<IMenuItem & { children: IMenuItem[] }> {
        const items = (this[menuType] as unknown) as IMenuItem[];
        return items
            ?.filter((item) => item[link] === id)
            .map((item) => ({ ...item, children: this.toTree(menuType, item.id) }));
    }
}

@Exclude()
class SocialMedia {
    @Expose({ name: "link_vk" })
    vkUrl: string;

    @Expose({ name: "link_instagram" })
    instagramUrl: string;

    @Expose({ name: "link_youtube" })
    youtubeUrl: string;

    @Expose({ name: "link_facebook" })
    facebookUrl: string;
}

@Exclude()
class Pages {
    @Expose({ name: "page_cart_url" })
    cartUrl: string;

    @Expose({ name: "page_compare_url" })
    compareUrl: string;

    @Expose({ name: "page_favorite_url" })
    favoriteUrl: string;

    @Expose({ name: "page_personal_url" })
    personalUrl: string;

    @Expose({ name: "page_profile_url" })
    profileUrl: string;

    @Expose({ name: "page_orders_url" })
    ordersUrl: string;

    @Expose({ name: "page_shops_url" })
    shopsUrl: string;

    @Expose({ name: "page_shop_detail_url" })
    shopDetailUrl: string;

    @Expose({ name: "page_brands_url" })
    brandsUrl: string;

    @Expose({ name: "page_register_order_url" })
    registerOrderUrl: string;

    @Expose({ name: "page_product_detail_url" })
    productDetailUrl: string;

    @Expose({ name: "page_blog_url" })
    blogUrl: string;

    @Expose({ name: "page_blog_detail_url" })
    blogDetailUrl: string;

    @Expose({ name: "page_brand_detail_url" })
    brandDetailUrl: string;

    @Expose({ name: "page_news_detail_url" })
    newsDetailUrl: string;

    @Expose({ name: "page_team_detail_url" })
    teamDetailUrl: string;

    @Expose({ name: "page_technology_detail_url" })
    technologyDetailUrl: string;

    @Expose({ name: "page_review_product_detail_url" })
    reviewProductDetailUrl: string;

    @Expose({ name: "page_news_url" })
    newsUrl: string;

    @Expose({ name: "page_team_url" })
    teamUrl: string;

    @Expose({ name: "page_search" })
    searchUrl: string;
}

@Exclude()
class Contacts {
    @Expose()
    email: string;

    @Expose()
    phone: string;

    @Expose({ name: "work_time" })
    workTime: string;

    @Expose()
    entity: string;
}

@Exclude()
class Catalogs {
    @Expose({ name: "menu_et_id" })
    menuEtypeId: number;

    @Expose({ name: "news_et_id" })
    newsEtypeId: number;

    @Expose({ name: "brands_et_id" })
    brandsEtypeId: number;

    @Expose({ name: "activities_et_id" })
    activitiesEtypeId: number;

    @Expose({ name: "technologies_et_id" })
    technologiesEtypeId: number;

    @Expose({ name: "review_products_et_id" })
    reviewProductsEtypeId: number;

    @Expose({ name: "blog_et_id" })
    blogEtypeId: number;

    @Expose({ name: "team_et_id" })
    teamEtypeId: number;

    @Expose({ name: "reviews_et_id" })
    reviewsEtypeId: number;

    @Expose({ name: "carousel_et_id" })
    carouselEtypeId: number;

    @Expose({ name: "grid_et_id" })
    gridEtypeId: number;

    /** Айди справочника сайтов, но храним айди типа атрибута */
    @Expose({ name: "site_et_id" })
    siteAtypeId: number;
}

@Exclude()
class Config {
    @Expose({ name: "social_media_links" })
    @Type(() => SocialMedia)
    social: SocialMedia;

    @Expose()
    @Type(() => Pages)
    pages: Pages;

    @Expose()
    @Type(() => Contacts)
    contacts: Contacts;

    @Expose()
    @Type(() => Catalogs)
    catalogs: Catalogs;
}

@Exclude()
class Announcement {
    @Expose()
    message: string;
}

@Exclude()
class Page {
    @Expose()
    @Type(() => PageContext)
    context: PageContext;

    @Expose()
    @Type(() => Menu)
    menu: Menu;

    @Expose()
    @Type(() => Config)
    config: Config;

    @Expose()
    @Type(() => Announcement)
    announcements: Announcement[];

    @Expose() error: string;
    @Expose({ name: "page_model" }) pageModel: PageModelType;
}

export default Page;
