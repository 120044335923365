import React, { useState, useEffect, ReactNode } from "react";

type Props = { src: Promise<string>[]; children: (props: any) => ReactNode };

const AsyncImage = ({ src = [], children }: Props): any => {
    const [loadedSrc, setLoadedSrc] = useState<string[]>([]);

    useEffect(() => {
        async function resolve() {
            const result = await Promise.all(src);
            setLoadedSrc(result);
        }

        if (src.length) {
            setLoadedSrc([]);
            resolve();
        }
    }, [src]);

    return <>{children({ images: loadedSrc })}</>;
};

export default AsyncImage;
