import React from "react";
import clsx from "clsx";
import { Rating } from "@/components/Core";

export interface TopProps {
    sku: string;
    onCompare: () => void;
    inCompare: boolean;
    ratingCount: number;
    ratingAverage: number;
}

const Top = ({ sku, onCompare, inCompare, ratingCount, ratingAverage }: TopProps) => {
    return (
        <div className="product-top">
            <div className="product-reviews">
                <div className="catrating">
                    <div className="catrating__stars">
                        <Rating disabled count={5} value={ratingAverage} />
                    </div>
                    <div className="catrating__count">{ratingCount}</div>
                </div>
            </div>
            <button
                type={"button"}
                onClick={onCompare}
                className={clsx({
                    "product-tocompasion": true,
                    active: inCompare,
                })}
            >
                Сравнить
            </button>
            <div className="product-article">
                Артикул: <span>{sku}</span>
            </div>
        </div>
    );
};

export default Top;
