import React from "react";
import { ProductAttrValueType, Sku } from "@/api/services/catalog/models/Product.model";

export type ColorsProps = {
    activeColor: string;
    colorTitle: string | undefined;
    setColor(attr: ProductAttrValueType | null): void;
    items: Sku[] | undefined;
};

const Colors: React.FunctionComponent<ColorsProps> = ({
    items,
    activeColor,
    setColor,
    colorTitle,
    ...restProps
}: ColorsProps) => {
    return (
        <div className="product-colors">
            <div className="product-colors__label">
                <span>Цвет:</span> {colorTitle}
            </div>
            <div className="product-colors__items">
                {items &&
                    items.map((sku) => {
                        return (
                            <div
                                className={`${
                                    sku.attr.color?.value === activeColor ? "product-colors__item--active" : ""
                                } product-colors__item`}
                                key={sku.attr.color?.id}
                            >
                                <img
                                    className="product-colors__img"
                                    alt={sku.attr.color?.value}
                                    src={sku.attr.images && sku.attr.images.display[0]}
                                    onClick={() => setColor(sku.attr.color !== undefined ? sku.attr.color : null)}
                                />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default Colors;
