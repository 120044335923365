import { useState } from "react";

const useControls = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState<any>();

    return {
        thumbsSwiper,
        setThumbsSwiper,
    };
};

export default useControls;
