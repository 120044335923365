import { arrayToSubArrays } from "@/modules/utils";
import { Entity } from "../models";
import { ProductSchema } from "../schemas";
import EntityModel from "./EntityModel";

interface Composition {
    label: string;
    value: string;
}

interface Colors {
    id: string;
    value: string;
}

interface Price {
    salePrice: number | null;
    rrcPrice: number | null;
}

interface ProductListAttrType<I, V> {
    id: I;
    value: V;
}

interface Attr {
    html?: ProductListAttrType<number, string>;
    brand?: ProductListAttrType<string, string>;
    title?: ProductListAttrType<number, string>;
    category?: ProductListAttrType<string[], string[]>;
    technologies?: ProductListAttrType<string[], string[]>;
}

interface Labels {
    id: number[];
    value: string[];
    display: string[];
}

interface Category {
    id: string[];
    value: string[];
    display: string[];
}

class ProductEntity extends EntityModel<ProductSchema> {
    readonly slug: string;
    readonly sku: string;
    readonly skuId: number;
    readonly title: string;
    readonly composition: Composition[];
    readonly description: string;
    readonly features: string[];
    readonly image: string[] | null;
    readonly marks: string[];
    readonly color: string;
    readonly colorId: number;
    readonly size: string;
    readonly sizeId: number;
    readonly colors: Colors[];
    readonly availableSizes: string[];
    readonly availableSizesIds: number[];
    readonly technologiesIds: number[];
    readonly newsIds: number[];
    readonly recommendationsIds: number[];

    readonly labels: Labels;
    readonly category: Category;
    readonly attr: Attr;
    readonly price: Price;

    constructor(entity: Entity<ProductSchema>) {
        super(entity);
        this.sku = entity.getFirstAttribute("sku")?.getFirstValue()?.value || "";
        this.skuId = entity.getFirstAttribute("sku")?.getFirstValue()?.entityId || -1;
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.composition = arrayToSubArrays(
            entity.getFirstAttribute("composition")?.values.filter((el) => !!el.value) || [],
            2
        ).map((el) => ({ label: el[0].value, value: el[1].value }));
        this.description = entity.getFirstAttribute("description")?.getFirstValue()?.value || "";
        this.features = entity.getFirstAttribute("features")?.values.map((el) => el.value) || [];
        this.image = entity.getFirstAttribute("image")?.values.map((el) => el.display) || [];
        this.marks =
            entity
                .getFirstAttribute("marks")
                ?.values.map((el) => el.value)
                .filter((el) => !!el) || [];
        this.color = entity.getFirstAttribute("color")?.getFirstValue()?.value || "";
        this.colorId = entity.getFirstAttribute("color")?.getFirstValue()?.entityId || -1;
        this.size = entity.getFirstAttribute("size")?.getFirstValue()?.value || "";
        this.sizeId = entity.getFirstAttribute("size")?.getFirstValue()?.entityId || -1;
        this.availableSizes = entity.getFirstAttribute("available_sizes")?.values.map((el) => el.value) || [];
        this.availableSizesIds = entity.getFirstAttribute("available_sizes")?.values.map((el) => el.entityId) || [];
        this.technologiesIds = entity.getFirstAttribute("technologies")?.values.map((el) => el.entityId) || [];
        this.newsIds = entity.getFirstAttribute("news")?.values.map((el) => el.entityId) || [];
        this.recommendationsIds = entity.getFirstAttribute("recommendations")?.values.map((el) => el.entityId) || [];

        this.colors = arrayToSubArrays(
            entity.getFirstAttribute("colors")?.values.filter((el) => !!el.value) || [],
            2
        ).map((el) => ({ id: el[0].value, value: el[1].value }));

        this.labels = entity.labels;
        this.category = entity.category;
        this.attr = entity.attr;
        this.price = entity.price;
    }
}

export default ProductEntity;
