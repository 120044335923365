import { Exclude, Type, Expose } from "class-transformer";
import { Product } from "@/api/services/catalog/models";

@Exclude()
class PaginatedQueryResult<DataType> {
    @Exclude()
    private type: () => void;

    @Expose() count: number;
    @Expose() items: Product[];

    @Expose() next: null;

    @Expose() previous: null;

    @Expose()
    @Type((options) => {
        return (options?.newObject as PaginatedQueryResult<DataType>).type;
    })
    results: DataType[];

    constructor(type: any) {
        this.type = type;
    }
}

export default PaginatedQueryResult;
