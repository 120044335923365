import { Entity } from "../models";
import { MainGridSchema } from "../schemas";
import EntityModel from "./EntityModel";

class MainGridEntity extends EntityModel<MainGridSchema> {
    readonly title: string;
    readonly image: string;
    readonly description: string;
    readonly url: string;

    constructor(entity: Entity<MainGridSchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.image = entity.getFirstAttribute("image")?.getFirstValue()?.display || "";
        this.description = entity.getFirstAttribute("description")?.getFirstValue()?.value || "";
        this.url = entity.getFirstAttribute("url")?.getFirstValue()?.value || "";
    }
}

export default MainGridEntity;
