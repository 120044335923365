import React from "react";
import HeaderView from "@/views/Header";
import useHeaderControls from "./useHeaderControls";
import { useCart, useAuth, usePage, useCompare, useFavourite } from "@/modules/hooks";
import PopupMenu from "./PopupMenu";
import { ThemeLogo } from "@/modules/theme";
import useTheme from "@/modules/hooks/useTheme";

export type HeaderProps = Partial<{ fixedOnScroll: boolean }>;

const Header: React.FunctionComponent<HeaderProps> = ({ fixedOnScroll = true }: HeaderProps) => {
    const { isAuthenticated } = useAuth();
    const { page } = usePage();

    const announcements = page?.announcements || [];

    const {
        fixed,
        menuOpen,
        catalogOpen,
        messageShow,
        headerTopRef,
        handleHideMessage,
        handleSignInClick,
        handleToggleCatalog,
        handleToggleMenu,
    } = useHeaderControls({ isMessageShow: announcements.length > 0 });
    const { totalElements } = useCart();
    const { compareItems } = useCompare();
    const { favouriteItems } = useFavourite();
    const { currentTheme } = useTheme();

    const menu = page?.menu;
    const config = page?.config;
    const contacts = config?.contacts;
    const pages = config?.pages;

    const menuHeader = menu?.header || [];
    const topMenu = menu?.toTree("top") || [];
    const specialMenu = menu?.special || [];

    return (
        <HeaderView
            withMessage={messageShow}
            fixed={fixedOnScroll && fixed}
            menuOpen={menuOpen}
            catalogOpen={catalogOpen}
        >
            <HeaderView.Top ref={headerTopRef} onToggleMenu={handleToggleMenu}>
                <HeaderView.Top.Menu>
                    {topMenu.map((parent) => (
                        <HeaderView.Top.Menu.Dropdown key={parent.id} title={parent.title}>
                            {parent.children.map((link) => (
                                <HeaderView.Top.Menu.Dropdown.Item
                                    key={link.id}
                                    href={link.url}
                                    target={link.targetBlank ? "_blank" : "_self"}
                                >
                                    {link.title}
                                </HeaderView.Top.Menu.Dropdown.Item>
                            ))}
                        </HeaderView.Top.Menu.Dropdown>
                    ))}
                </HeaderView.Top.Menu>

                <HeaderView.Top.Links>
                    <HeaderView.Top.Links.Link href={pages?.shopsUrl} type="shops">
                        Магазины
                    </HeaderView.Top.Links.Link>
                    <HeaderView.Top.Links.Link href={pages?.brandsUrl} type="brand">
                        Бренды
                    </HeaderView.Top.Links.Link>
                    <HeaderView.Top.Links.Link href={`tel:${contacts?.phone}`} type="phone">
                        {contacts?.phone}
                    </HeaderView.Top.Links.Link>
                </HeaderView.Top.Links>
            </HeaderView.Top>
            <HeaderView.Middle>
                <HeaderView.Middle.Container>
                    <HeaderView.Middle.Row>
                        <HeaderView.Middle.Logo LogoComponent={ThemeLogo[currentTheme]} />
                        <HeaderView.Middle.Catalog onToggleCatalog={handleToggleCatalog} />

                        <HeaderView.Middle.Col position="left">
                            <HeaderView.Middle.Search />
                            <HeaderView.Middle.Menu onToggleMenu={handleToggleMenu} />
                        </HeaderView.Middle.Col>
                        <HeaderView.Middle.Col position="right">
                            <HeaderView.Middle.IconButton icon="account" onClick={handleSignInClick}>
                                {isAuthenticated ? "Профиль" : "Войти"}
                            </HeaderView.Middle.IconButton>
                            {pages?.compareUrl ? (
                                <HeaderView.Middle.IconButton
                                    href={pages.compareUrl}
                                    icon="compasion"
                                    count={compareItems.length}
                                >
                                    Сравнение
                                </HeaderView.Middle.IconButton>
                            ) : null}
                            {pages?.favoriteUrl ? (
                                <HeaderView.Middle.IconButton
                                    href={pages.favoriteUrl}
                                    icon="favorite"
                                    count={favouriteItems.length}
                                >
                                    Избранное
                                </HeaderView.Middle.IconButton>
                            ) : null}
                            {pages?.cartUrl ? (
                                <HeaderView.Middle.IconButton href={pages.cartUrl} icon="cart" count={totalElements}>
                                    Корзина
                                </HeaderView.Middle.IconButton>
                            ) : null}
                        </HeaderView.Middle.Col>
                    </HeaderView.Middle.Row>
                    <PopupMenu handleToggleCatalog={handleToggleCatalog} />
                </HeaderView.Middle.Container>
            </HeaderView.Middle>
            <HeaderView.Bottom>
                {menuHeader.map((item) => {
                    return (
                        <HeaderView.Bottom.Link
                            key={item.id}
                            href={item.url}
                            target={item.targetBlank ? "_blank" : "_self"}
                        >
                            {item.title}
                        </HeaderView.Bottom.Link>
                    );
                })}
                {specialMenu.map((link) => (
                    <HeaderView.Bottom.Link
                        key={link.id}
                        primary
                        href={link.url}
                        target={link.targetBlank ? "_blank" : "_self"}
                    >
                        {link.title}
                    </HeaderView.Bottom.Link>
                ))}
            </HeaderView.Bottom>
            <HeaderView.Message
                hide={!messageShow}
                message={announcements[0]?.message}
                // timer
                // timerOptions={{ days: 13, hours: 4, minutes: 27 }}
                onHide={handleHideMessage}
            />
            <HeaderView.MobilePanel>
                <HeaderView.MobilePanel.Row>
                    <HeaderView.MobilePanel.Col>
                        <HeaderView.Middle.IconButton icon="main" href="/">
                            Главная
                        </HeaderView.Middle.IconButton>
                    </HeaderView.MobilePanel.Col>

                    <HeaderView.MobilePanel.Col>
                        <HeaderView.Middle.IconButton icon="cat" onClick={handleToggleCatalog}>
                            Каталог
                        </HeaderView.Middle.IconButton>
                    </HeaderView.MobilePanel.Col>

                    <HeaderView.MobilePanel.Col>
                        <HeaderView.Middle.IconButton
                            icon="favorite"
                            count={favouriteItems.length}
                            href={pages?.favoriteUrl}
                        >
                            Избранное
                        </HeaderView.Middle.IconButton>
                    </HeaderView.MobilePanel.Col>

                    <HeaderView.MobilePanel.Col>
                        <HeaderView.Middle.IconButton icon="cart" count={totalElements} href={pages?.cartUrl}>
                            Корзина
                        </HeaderView.Middle.IconButton>
                    </HeaderView.MobilePanel.Col>

                    <HeaderView.MobilePanel.Col>
                        <HeaderView.Middle.IconButton icon="account" onClick={handleSignInClick}>
                            {isAuthenticated ? "Профиль" : "Войти"}
                        </HeaderView.Middle.IconButton>
                    </HeaderView.MobilePanel.Col>
                </HeaderView.MobilePanel.Row>
            </HeaderView.MobilePanel>
        </HeaderView>
    );
};

export default Header;
