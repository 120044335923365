import React from "react";

type CategoryProps = Partial<{ heading: string; onBack(e: React.MouseEvent): void }>;

const Top: React.FC<CategoryProps> = ({ heading, onBack }: CategoryProps) => {
    return (
        <div className="popmenu-cattop">
            <div className="popmenu-back" onClick={onBack}>
                <span />
            </div>
            <div className="popmenu-heading">{heading}</div>
        </div>
    );
};

export default Top;
