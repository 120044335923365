import { Exclude, Expose } from "class-transformer";
import { CityName } from "@/modules/constants";

@Exclude()
class Address {
    @Expose() id: number;

    @Expose() user: string;

    @Expose() city: number;

    @Expose() street: string;

    @Expose() house: string;

    @Expose({ name: "apartment_office" }) apartment: string;

    @Expose({ name: "comment_to_the_courier" }) comment: string;

    @Expose({ name: "is_main" }) isMain: boolean;

    @Expose()
    get address(): string {
        return `Город ${CityName[this.city]}, ул. ${this.street} д. ${this.house} кв. ${this.apartment}`;
    }
}

export default Address;
