import { Exclude, Expose, Type } from "class-transformer";
import { ProductDetail } from "../../catalog/models/Product.model";

@Exclude()
class Comparison {
    @Expose() id: number;

    @Expose({ name: "product" })
    productId: number;

    @Type(() => ProductDetail)
    @Expose({ name: "product_obj" })
    product: ProductDetail;

    @Type(() => Date)
    @Expose({ name: "created_at" })
    createdAt: Date;

    @Type(() => Date)
    @Expose({ name: "updated_at" })
    updatedAt: Date;
}

export default Comparison;
