import * as React from "react";

function SvgGarpix(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={84} height={16} viewBox="0 0 84 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M78.667 0.235352H83.3597V4.9281H78.667V0.235352Z" fill="#FF8800" />
            <path
                d="M78.509 7.69187L73.7727 0.331543H68.3886L73.3372 7.69187L68.0674 15.6782H72.7928L75.9122 10.5336L79.0262 15.6782H83.7843L78.509 7.69187Z"
                fill="white"
            />
            <path d="M62.7969 15.6787H66.8636V0.332031H62.7969V15.6787Z" fill="white" />
            <path
                d="M54.2332 0.332031H48.1631V15.6951H52.2134V10.8717H54.2223C58.0113 10.8553 60.9075 9.6522 60.9075 5.60185C60.9021 1.55149 58.0222 0.332031 54.2332 0.332031ZM54.4292 7.35483H52.208V3.83798H54.4237C55.6595 3.83798 56.7538 4.20818 56.7538 5.59096C56.7538 6.9683 55.665 7.35483 54.4292 7.35483Z"
                fill="white"
            />
            <path
                d="M42.3925 10.1042C44.0801 9.42914 45.3322 8.04636 45.3322 5.49311C45.3322 1.44276 42.1529 0.321289 39.0335 0.321289H32.5605V15.668H36.6272V10.6867H38.3802L42.044 15.6843H46.6225L42.3925 10.1042ZM39.2132 7.38763H36.6272V3.83813H39.1968C40.3564 3.83813 41.1403 4.43153 41.1403 5.62377C41.1403 6.81057 40.3727 7.38763 39.2132 7.38763Z"
                fill="white"
            />
            <path
                d="M20.6769 0.332031L14.6504 15.6787H18.8096L19.6916 13.267H26.0502L26.9321 15.6787H31.0913L25.0485 0.332031H20.6769ZM20.9001 9.9734L22.4735 5.53652C22.664 5.04111 22.7947 4.5076 22.8763 3.97953C22.958 4.5076 23.0832 5.04111 23.2792 5.53652L24.8525 9.9734H20.9001Z"
                fill="white"
            />
            <path
                d="M9.75025 12.4178C9.41272 12.4995 9.05886 12.5322 8.705 12.5322C6.95202 12.5322 4.15924 11.8081 4.15924 8.01906C4.15924 4.86697 6.50561 3.58218 8.9772 3.58218C10.5179 3.58218 12.064 4.0177 13.2181 4.80164V0.963601C11.7101 0.337538 10.1041 9.71953e-06 8.46546 9.71953e-06C4.17557 -0.00543431 0 2.27561 0 7.99728C0 13.572 4.1429 16 8.51446 16C10.2674 15.9837 11.9823 15.6461 13.6046 14.9711V7.24056H9.75025V12.4178Z"
                fill="white"
            />
        </svg>
    );
}

const MemoSvgGarpix = React.memo(SvgGarpix);
export default MemoSvgGarpix;
