import React from "react";
import clsx from "clsx";
import { CommonComponentProps } from "../../types";

export type ModalBodyProps = Partial<{}>;

const ModalBody: React.FC<CommonComponentProps<ModalBodyProps>> = ({
    className,
    children,
}: CommonComponentProps<ModalBodyProps>) => {
    const classes = clsx("modal-body", {
        [className ?? ""]: !!className,
    });

    return <div className={classes}>{children}</div>;
};

export default ModalBody;
