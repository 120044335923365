import { Page } from "@/api/services/common/models";
import { useStoreon } from "@/store";
import { useCallback } from "react";

const usePage = () => {
    const { page, dispatch } = useStoreon("page");

    const setPage = useCallback(
        (newPage: Page | null) => {
            dispatch("page/set", newPage);
        },
        [dispatch]
    );

    return { page, setPage };
};

export default usePage;
