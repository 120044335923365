import { createContext } from "react";
import { createStoreon, StoreonModule } from "storeon";
import { storeonDevtools } from "storeon/devtools";
import { customContext } from "storeon/react";
import { modalModule } from "./StoreModules/ModalModule";
import { authModule } from "./StoreModules/AuthModule";
import { commonModule } from "./StoreModules/CommonModule";
import { pageModule } from "./StoreModules/PageModule";
import { cartModule } from "./StoreModules/CartModule";
import { favouriteModule } from "./StoreModules/FavouriteModule";
import { compareModule } from "./StoreModules/CompareModule";

import { UnionToIntersection } from "@/modules/types";

type ExtractStoreonTypes<T, R extends "state" | "events"> = UnionToIntersection<
    T extends StoreonModule<infer S, infer E> ? (R extends "state" ? S : E) : never
>;

const storeModules = [modalModule, authModule, commonModule, pageModule, cartModule, favouriteModule, compareModule];

type StoreState = ExtractStoreonTypes<typeof storeModules[0], "state">;
type StoreEvents = ExtractStoreonTypes<typeof storeModules[0], "events">;

console.log(process.env.BUILD_TARGET, "process.env.NODE_ENV");
const store = createStoreon<StoreState, StoreEvents>([
    ...storeModules,
    process.env.NODE_ENV !== "production" && storeonDevtools,
]);

const StoreContext = createContext(store);

const useStoreon = customContext(StoreContext);

export default store;
export { storeModules, StoreContext, useStoreon };
export type { StoreState, StoreEvents };
