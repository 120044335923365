import { Button, Input } from "@/components/Core";
import { usePage } from "@/modules/hooks";
import React from "react";

const Mobile: React.FC = () => {
    const { page: currentPage } = usePage();
    const config = currentPage?.config;
    return (
        <div className="popmenu-mobtop">
            <div className="popmenu-heading">Каталог</div>
            <div className="popmenu-search">
                <form method="get" action={config?.pages?.searchUrl || ""} className="header-search">
                    <Input className="header-search__input" placeholder="Более 10000 товаров" />
                    <Button className="header-search__btn" />
                </form>
            </div>
        </div>
    );
};

export default Object.assign(Mobile, {});
