import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";

SwiperCore.use([Navigation]);

export type CarouselProps = Partial<{ thumbs: string[] } & Pick<Swiper, "onSwiper">>;

const Carousel: React.FunctionComponent<CarouselProps> = ({ thumbs = [], onSwiper }: CarouselProps) => {
    return (
        <div className="product-thumbs__wrap">
            <div className="product-thumbs__btnprev swiper-button-prev" />
            <Swiper
                onSwiper={onSwiper}
                watchSlidesVisibility
                watchSlidesProgress
                slidesPerView="auto"
                direction="vertical"
                navigation={{
                    nextEl: ".product-thumbs .swiper-button-next",
                    prevEl: ".product-thumbs .swiper-button-prev",
                }}
                breakpoints={{
                    320: {
                        direction: "horizontal",
                        slidesPerView: 4.5,
                        spaceBetween: 10,
                    },
                    568: {
                        direction: "vertical",
                    },
                }}
            >
                {thumbs.map((thumb, i) => (
                    <SwiperSlide key={i} className="product-thumb">
                        <img className="product-thumb__img" src={thumb} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="product-thumbs__btnnext swiper-button-next" />
        </div>
    );
};
export default Carousel;
