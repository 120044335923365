import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { parse, ParsedQuery } from "query-string";

type UsePaginationOptions = Partial<{
    initialPage: number;
    initialPageSize: number;
    scrollOnChange: boolean;
    pageQueryParam: string;
    pageSizeQueryParam: string;
}>;

type UsePagination = {
    page: number;
    pageSize: number;
    handleChangePage(value: number): void;
    handleChangePageSize(value: number): void;
    params: ParsedQuery<string | number>;
};

const usePagination = (options: UsePaginationOptions = {}): UsePagination => {
    const {
        initialPage = 1,
        initialPageSize = 20,
        scrollOnChange = false,
        pageQueryParam = "page",
        pageSizeQueryParam = "page_size",
    } = options;

    const { search } = useLocation();

    const queryPage = parse(search, { parseNumbers: true })[pageQueryParam] as number;
    const queryPageSize = parse(search, { parseNumbers: true })[pageSizeQueryParam] as number;
    const params = parse(search, { parseNumbers: true });

    const [page, setPage] = useState<number>(queryPage || initialPage);
    const [pageSize, setPageSize] = useState<number>(queryPageSize || initialPageSize);

    const handleChangePage = useCallback(
        (value: number) => {
            setPage(value);
            if (scrollOnChange) window.scrollTo({ top: 0, behavior: "smooth" });
        },
        [scrollOnChange]
    );

    const handleChangePageSize = useCallback(
        (value: number) => {
            setPageSize(value);
            if (scrollOnChange) window.scrollTo({ top: 0, behavior: "smooth" });
        },
        [scrollOnChange]
    );

    useEffect(() => {
        if (queryPage) handleChangePage(queryPage);
    }, [handleChangePage, queryPage]);

    useEffect(() => {
        if (queryPageSize) handleChangePageSize(queryPageSize);
    }, [queryPageSize]);

    return {
        page,
        pageSize,
        handleChangePage,
        handleChangePageSize,
        params,
    };
};

export default usePagination;
