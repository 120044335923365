import React, { forwardRef, PropsWithChildren, RefAttributes } from "react";
import { MenuProps } from "../Middle/Menu";

import Menu from "./Menu";
import Links from "./Links";

export type TopProps = PropsWithChildren<Partial<RefAttributes<HTMLDivElement>> & Pick<MenuProps, "onToggleMenu">>;

const Top = forwardRef<HTMLDivElement, TopProps>(({ children, onToggleMenu }: TopProps, ref) => {
    return (
        <div ref={ref} className="header-top">
            <div className="container">
                <div className="header-topwrap">{children}</div>
                <div className="header-topclose" onClick={onToggleMenu} />
            </div>
        </div>
    );
});

export default Object.assign(Top, { Menu, Links });
