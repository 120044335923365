import { StoreonModule } from "storeon";

export interface CommonModuleState {
    ();
}

export interface CommonModuleEvents {
    ();
}

export const commonModule: StoreonModule<CommonModuleState, CommonModuleEvents> = (store) => {
    store.on("@init", () => ({}));
};
