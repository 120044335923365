import { Exclude, Expose } from "class-transformer";

@Exclude()
class QueryResult<DataType> {
    @Expose() status: boolean;

    @Expose()
    data: DataType;

    @Expose() error: string;
}

export default QueryResult;
