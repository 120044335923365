import React, { PropsWithChildren } from "react";

export type SizeProps = PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>>;

const Size: React.FC<SizeProps> = ({ children, ...other }: SizeProps) => {
    return (
        <div className="product-sizes__item">
            <input className="product-sizes__item-input" type="radio" {...other} />
            <label className="product-sizes__item-label" htmlFor={other.id}>
                {children}
            </label>
        </div>
    );
};

export default Size;
