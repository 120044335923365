import { Exclude, Expose } from "class-transformer";

@Exclude()
class AuthResult {
    @Expose({ name: "access_token" }) accessToken: string;

    @Expose({ name: "access_token_expires" }) accessTokenExpires: number;

    @Expose({ name: "refresh_token" }) refreshToken: string;

    @Expose({ name: "refresh_token_expires" }) refreshTokenExpires: number;

    @Expose({ name: "token_type" }) tokenType: string;
}

export default AuthResult;
