import { Entity } from "../models";
import { TeamMemberSchema } from "../schemas";
import EntityModel from "./EntityModel";

class TeamMemberEntity extends EntityModel<TeamMemberSchema> {
    readonly fullName: string;
    readonly image: string;
    readonly images: string[];
    readonly achievements: string[];
    readonly autobio: string;
    readonly speciality: string;
    readonly quote: string;
    readonly articlesIds: number[];
    readonly reviewsIds: number[];

    constructor(entity: Entity<TeamMemberSchema>) {
        super(entity);
        this.fullName = entity.getFirstAttribute("full_name")?.getFirstValue()?.value || "";
        this.image = entity.getFirstAttribute("image")?.getFirstValue()?.display || "";
        this.images = entity.getFirstAttribute("images")?.values.map((el) => el.display) || [];
        this.achievements = entity.getFirstAttribute("achievements")?.values.map((v) => v.value) || [];
        this.autobio = entity?.getFirstAttribute("autobio")?.getFirstValue()?.value || "";
        this.speciality = entity?.getFirstAttribute("speciality")?.getFirstValue()?.value || "";
        this.quote = entity?.getFirstAttribute("quote")?.getFirstValue()?.value || "";
        this.articlesIds = entity?.getFirstAttribute("articles")?.values.map((el) => el.entityId) || [];
        this.reviewsIds = entity?.getFirstAttribute("reviews")?.values.map((el) => el.entityId) || [];
    }
}

export default TeamMemberEntity;
