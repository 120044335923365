import React, { PropsWithChildren } from "react";

type LabelsProps = Partial<{ name: string; titlePrefix?: string }>;

const Labels: React.FC<LabelsProps> = ({ name, titlePrefix, children }: PropsWithChildren<LabelsProps>) => {
    return (
        <div className="product-namelabels">
            {titlePrefix && <span>{titlePrefix}</span>}
            <h1 className="product-name">{name}</h1>
            {children}
        </div>
    );
};

export default Labels;
