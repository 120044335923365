import { Exclude, Expose, Type } from "class-transformer";

@Exclude()
class Product {
    @Expose() id: number;
    @Expose({ name: "id_core" }) coreId: number;
}

@Exclude()
class Recommendation {
    @Expose() id: number;

    @Expose() type: "NEW" | "BESTSELLERS" | "LAST";

    @Expose() name: string;

    @Type(() => Product)
    @Expose()
    products: Product[];
}

export default Recommendation;
