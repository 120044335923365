import React, { PropsWithChildren } from "react";

import Link from "./Link";

const Bottom: React.FC<unknown> = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <div className="header-bottom">
            <div className="container">
                <div className="header-bottommenu">{children}</div>
            </div>
        </div>
    );
};

export default Object.assign(Bottom, { Link });
