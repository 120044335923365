import React, { PropsWithChildren } from "react";

import Item from "./Item";

export type DropdownProps = PropsWithChildren<
    Partial<{
        title: string;
    }>
>;

const Dropdown: React.FC<DropdownProps> = ({ children, title }: DropdownProps) => {
    return (
        <div className="header-topmenu__parent">
            <div className="header-topmenu__link">{title}</div>
            <div className="header-topmenu__submenu">{children}</div>
        </div>
    );
};

export default Object.assign(Dropdown, { Item });
