import { Button } from "@/components/Core";
import { ArrowRight } from "@/icons/ArrowRight";
import React, { useState, useEffect } from "react";

const BackToTop: React.FunctionComponent = () => {
    const [showBtn, setShowBtn] = useState<boolean>(false);

    useEffect(() => {
        const checkYOffset = () => {
            if (!showBtn && window.pageYOffset > 400) {
                setShowBtn(true);
            } else if (showBtn && window.pageYOffset <= 400) {
                setShowBtn(false);
            }
        };

        window.addEventListener("scroll", checkYOffset);

        return () => window.removeEventListener("scroll", checkYOffset);
    }, [showBtn]);

    const goToTopHandler = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    if (!showBtn) return null;

    return (
        <div className="back-to-top__container">
            <Button circle onClick={goToTopHandler}>
                <ArrowRight className="back-to-top__icon" />
            </Button>
        </div>
    );
};

export default BackToTop;
