import clsx from "clsx";
import React from "react";
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import { CommonComponentProps } from "../types";

registerLocale("ru", ru);

export type DatePickerProps = ReactDatePickerProps;

const DatePicker: React.FunctionComponent<CommonComponentProps<DatePickerProps>> = ({
    className,
    ...other
}: CommonComponentProps<DatePickerProps>) => {
    const classes = clsx("datepicker", {
        [className ?? ""]: !!className,
    });

    return <ReactDatePicker className={classes} locale="ru" {...other} />;
};

export default DatePicker;
