import { Exclude, Expose } from "class-transformer";

@Exclude()
class FeedbackDto {
    @Expose() name: string;

    @Expose() email: string;

    @Expose() message: string;
}

export default FeedbackDto;
