import { ModalStateProps, ModalType } from "@/modules/modals";
import { useCallback } from "react";
import { useStoreon } from "@/store";

type UseModal = {
    handleOpenModal(type: ModalType, props?: ModalStateProps): void;
    handleCloseModal(modalType: ModalType): void;
    handleForceCloseModals(): void;
};

const useModal = (): UseModal => {
    const { dispatch } = useStoreon();

    const handleOpenModal = useCallback(
        (type: ModalType, props: ModalStateProps = {}) => {
            dispatch("modal/toggle", { type, props });
        },
        [dispatch]
    );

    const handleCloseModal = useCallback(
        (type: ModalType) => {
            dispatch("modal/toggle", { type });
        },
        [dispatch]
    );

    const handleForceCloseModals = useCallback(() => {
        dispatch("modal/closeall");
    }, [dispatch]);

    return { handleOpenModal, handleCloseModal, handleForceCloseModals };
};

export default useModal;
