import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { Config } from "@/modules/constants";
import { PaginatedQueryResult } from "@/api/shared/models";
import { SearchProductModel } from "@/api/services/common/models/SearchProduct.model";
import { ProductList } from "@/api/services/catalog/models/Product.model";

class SearchApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    search = async (params: { q: string }): Promise<SearchProductModel[]> => {
        const { data } = await this.http.get("/search/entity/", { params });

        return this.plainToClassArray(SearchProductModel, data.data);
    };
    // search = async (params: { q: string }): Promise<PaginatedQueryResult<ProductList>> => {
    //     const { data } = await this.http.get("/search/entity/", { params });
    //
    //     // Логируем результат для отладки
    //     console.log("API response for search:", data);
    //
    //     // Приводим данные поиска к тому же типу, что и в каталоге
    //     return this.plainToClassFromExist(new PaginatedQueryResult(ProductList), data);
    // };
}

export default new SearchApiService({
    baseURL: Config.CMS_API_URL,
});
