import { Expose } from "class-transformer";

class Discount {
    sku: string;
    title: string;
    key: string;
    @Expose({ name: "at_id" }) atId: number;
    @Expose({ name: "attribute_value" }) attributeValue: string;
    @Expose({ name: "old_sale_price" }) oldSalePrice: string;
    @Expose({ name: "sale_price" }) salePrice: string;
    @Expose({ name: "rrc_price" }) rrcPrice: string;
    @Expose({ name: "is_valid" }) isValid: boolean;
}

export default Discount;
