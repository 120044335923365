import { Theme } from "../theme/types";
import { AppBaseConfig, AppConfig, AppEnvironmentConfig } from "./types";

const BaseConfig: AppBaseConfig = {
    TOKEN_TYPE_KEY: "token_type",
    TOKEN_KEY: "token",
    TOKEN_EXPIRES_KEY: "token_expires",
    REFRESH_TOKEN_KEY: "rtoken",
    CUSTOMER_TOKEN_KEY: "ctoken",
};

const isServer = typeof window === "undefined";

const EnvironmentConfig: AppEnvironmentConfig = {
    CMS_API_ROOT_URL: isServer
        ? (process.env.RAZZLE_APP_CMS_API_URL as string)
        : (window.env.CMS_API_ROOT_URL as string),
    CMS_API_URL: isServer ? (`${process.env.RAZZLE_APP_CMS_API_URL}v1/` as string) : window.env.CMS_API_URL,
    CORE_PROXY_URL: isServer
        ? (process.env.RAZZLE_APP_CORE_PROXY_API_URL as string)
        : (window.env.CORE_PROXY_URL as string),
    CAPTCHA_KEY: isServer ? (process.env.RAZZLE_APP_CAPTCHA_KEY as string) : (window.env.CAPTCHA_KEY as string),
    THEME: isServer ? (process.env.RAZZLE_APP_THEME as Theme) : (window.env.THEME as Theme),
    SITE_NAME: isServer ? (process.env.RAZZLE_APP_SITE_DOMAIN as string) : (window.env.SITE_NAME as string),
};

const Config: AppConfig = Object.assign<AppBaseConfig, AppEnvironmentConfig>(BaseConfig, EnvironmentConfig);

const Regex = {
    PHONE: /^[+][0-9]\s?\(?[0-9]{3}\)\s?[0-9]{3}-?[0-9]{2}-?[0-9]{2}$/ as RegExp,
    ONLY_LETTERS_WITH_UNDERLINE: /^[a-zA-Zа-яА-Я-]+$/ as RegExp,
} as const;

const CityName = {
    0: "Город не выбран",
    1: "Москва",
    2: "Санкт–Петербург",
    3: "Красная поляна",
};

const OrderCity: { id: number; title: string }[] = [
    { id: 0, title: CityName[0] },
    { id: 1, title: CityName[1] },
    { id: 2, title: CityName[2] },
    { id: 3, title: CityName[3] },
];

export { Config, Regex, OrderCity, CityName, EnvironmentConfig };
