import { Account } from "@/icons/Account";
import { Cart } from "@/icons/Cart";
import { Catalog } from "@/icons/Catalog";
import { Comparison } from "@/icons/Comparison";
import { Favorite } from "@/icons/Favorite";
import { Main } from "@/icons/Main";
import React, { PropsWithChildren } from "react";

type IconType = "account" | "compasion" | "favorite" | "cart" | "main" | "cat";

export type IconButtonProps = PropsWithChildren<
    Partial<{
        icon: IconType;
        href: string;
        onClick(e: React.MouseEvent<HTMLElement>): void;
        count: number;
    }> &
        React.HTMLAttributes<HTMLAnchorElement>
>;

const Icons: Record<IconType, Custom.SvgIcon> = {
    account: Account,
    compasion: Comparison,
    favorite: Favorite,
    cart: Cart,
    main: Main,
    cat: Catalog,
};

const IconButton: React.FC<IconButtonProps> = ({
    icon,
    href = "#",
    onClick,
    count,
    children,
    ...other
}: IconButtonProps) => {
    const IconComponent = icon ? Icons[icon] : null;
    return (
        <a
            className={`header-iconbtn header-iconbtn--${icon}`}
            href={onClick ? "#!" : href}
            onClick={onClick}
            {...other}
        >
            {IconComponent && <IconComponent className="header-iconbtn__icon" />}
            {count ? <div className="header-iconbtn__count">{count}</div> : null}
            <div className="header-iconbtn__label">{children}</div>
        </a>
    );
};

export default IconButton;
