import { HttpOptions } from "..";
import Cookies from "universal-cookie";
import { Config } from "@/modules/constants";

const cookies = new Cookies();

const TokenInterceptor = (config: HttpOptions): HttpOptions => {
    const token = cookies.get(Config.TOKEN_KEY);
    if (!token) return config;
    const tokenType = cookies.get(Config.TOKEN_TYPE_KEY) ?? "Bearer";

    config.headers.Authorization = tokenType + " " + token;
    return config;
};

const CustomerTokenInterceptor = (config: HttpOptions): HttpOptions => {
    const token = cookies.get(Config.CUSTOMER_TOKEN_KEY);
    if (!token) return config;

    config.headers["Customer-Token"] = token;
    return config;
};

export { TokenInterceptor, CustomerTokenInterceptor };
