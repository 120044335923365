import React, { PropsWithChildren } from "react";
import Availability from "./Availability";
import Description from "./Description";
import Review from "./Review";

export type WrapperProps = PropsWithChildren<{}>;

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children }: WrapperProps) => {
    return <div className="product-tabs">{children}</div>;
};

export default Object.assign(Wrapper, { Availability, Description, Review });
