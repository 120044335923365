import { Garpix } from "@/icons/Garpix";
import React from "react";

export type CopyrightsProps = {};

const Copyrights: React.FunctionComponent<CopyrightsProps> = () => {
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="footer-left">
                    Сеть магазинов &quot;День Сурка&quot; © Торговая компания MounTech | 2003-2021 | Все права защищены
                </div>
                <div className="footer-right">
                    <a className="garpix" target="_blank" rel="noreferrer" href="http://garpix.com/">
                        <span>Разработано в</span>
                        <Garpix />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Copyrights;
