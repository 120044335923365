import { Exclude, Expose, Transform } from "class-transformer";
import dayjs from "@/modules/utils/dayjs";

export type Gender = "UNDEFINED" | "MAN" | "WOMAN";

@Exclude()
class User {
    @Expose() id: number;

    @Expose() email: string;

    @Expose() phone: string;

    @Expose() sex: Gender;

    @Expose()
    @Transform(({ value }) => (value ? dayjs(value, "DD.MM.YYYY").toDate() : new Date()))
    birthday: Date;

    @Expose() username: string;

    @Expose({ name: "first_name" }) firstName: string;

    @Expose({ name: "last_name" }) lastName: string;

    @Expose({ name: "vip_status" }) vipStatus: boolean;

    @Expose({ name: "loyalty_card" }) loyaltyCard: string;

    @Expose({ name: "address" }) addressId: number;

    @Expose()
    getFullName(): string {
        return `${this.firstName} ${this.lastName}`.trim();
    }
}

export default User;
