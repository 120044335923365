import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
require("dayjs/locale/ru");

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.locale("ru");

export default dayjs;
