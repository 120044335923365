import { catalogApiService, entitiesApiService } from "@/api/services";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

const useQuantities = ({ entityId }) => {
    const [balanceTree, setBalanceTree] = useState<{ [key: string]: [] }>({});

    const { data: product } = useQuery(["product-qty", entityId], () => catalogApiService.getProduct(entityId), {
        enabled: !!entityId,
    });

    // const skuList: string | undefined = product?.skus.map((sku) => sku.attr.sku?.value).join(",");
    // vma 11/03/2024 - чтобы в артикулах проходила запятая - ставим двойные кавычки в СКУ
    const skuList: string | undefined = product?.skus.map((sku) => '"' + sku.attr.sku?.value + '"').join(",");

    const { data: balances } = useQuery(
        ["quantity", entityId],
        () => entitiesApiService.getAvailable({ sku_list: `{${skuList}}` }),
        {
            enabled: !!product,
        }
    );

    useEffect(() => {
        if (balances && product) {
            const balancesFlat = balances.flat().filter((available) => !!available);
            const balancesWithSaleAttrs = balancesFlat.map((available) => {
                let copy = { ...available };
                const sku = product.skus.find((sku) => {
                    return sku.attr.sku?.value === available.sku;
                });
                if (sku) {
                    const skusSaleAttrs = sku && sku.saleAttrs;
                    const attrs = skusSaleAttrs?.reduce((acc: any, attr) => {
                        if (sku.attr[attr]) {
                            acc[attr] = sku.attr[attr].value;
                            acc.saleAttrs = sku.saleAttrs;
                        }
                        return acc;
                    }, {});
                    copy = { ...copy, ...attrs };

                    return copy;
                }
            });

            const balancesHash = balancesWithSaleAttrs.reduce((acc, item) => {
                if (item && item.isShop) {
                    acc[item.storeName]
                        ? (acc[item.storeName] = [...acc[item.storeName], item])
                        : (acc[item.storeName] = [item]);
                }
                return acc;
            }, {});
            setBalanceTree(balancesHash);
        }
    }, [product, balances]);

    const availableInShop = useMemo(
        () => balances?.flat()?.filter((store) => Boolean(store) && store.isShop && Boolean(store.quantity)) ?? [],
        [balances]
    );
    const availableInStorehouse = useMemo(
        () => balances?.flat().filter((store) => Boolean(store) && !store.isShop && Boolean(store.quantity)) ?? [],
        [balances]
    );

    return { balanceTree, availableInShop, availableInStorehouse };
};

export default useQuantities;
