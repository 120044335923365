import React from "react";
import { Modal as BsModal, ModalProps as BsModalProps } from "react-bootstrap";
import clsx from "clsx";
import { CommonComponentProps } from "../types";
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import ModalBody from "./ModalBody";

export type ModalProps = BsModalProps;

const Modal: React.FC<CommonComponentProps<ModalProps>> = (props: CommonComponentProps<ModalProps>) => {
    const { children, className, ...other } = props;

    const classes = clsx({
        [className ?? ""]: !!className,
    });

    return (
        <BsModal className={classes} {...other}>
            {children}
        </BsModal>
    );
};

export default Object.assign(Modal, {
    Header: ModalHeader,
    Content: ModalContent,
    Body: ModalBody,
});
