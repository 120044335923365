import { Exclude, Expose } from "class-transformer";

@Exclude()
class ComponentProp {
    @Expose({ name: "title" })
    name: string;

    @Expose({ name: "content" })
    value: string;
}

export default ComponentProp;
