import React, { PropsWithChildren } from "react";

export type TitleProps = PropsWithChildren<Partial<{ item: boolean }>>;

const Title: React.FunctionComponent<TitleProps> = ({ children, item }: TitleProps) => {
    if (item) return <p className="product-desc__item_title">{children}</p>;
    return <p className="product-desc__title">{children}</p>;
};

export default Title;
