import { Entity } from "../models";
import { TechnologySchema } from "../schemas";
import EntityModel from "./EntityModel";

class TechnologyEntity extends EntityModel<TechnologySchema> {
    readonly title: string;
    readonly image: string;
    readonly date: string;
    readonly summary: string;
    readonly html: string;
    readonly tag: string;

    constructor(entity: Entity<TechnologySchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.image = entity.getFirstAttribute("image")?.getFirstValue()?.display || "";
        this.summary = entity.getFirstAttribute("summary")?.getFirstValue()?.value || "";
        this.tag = entity.getFirstAttribute("tag")?.getFirstValue()?.value || "";
        this.html = entity?.getFirstAttribute("html")?.getFirstValue()?.value || "";
    }
}

export default TechnologyEntity;
