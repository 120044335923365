import React from "react";

type TimerOptions = Partial<{
    days: number | string;
    hours: number | string;
    minutes: number | string;
}>;

export type HeaderMessageProps = Partial<{
    hide: boolean;
    message: string;
    timer: boolean;
    timerOptions: TimerOptions;
    onHide(): void;
}>;

const HeaderMessage: React.FunctionComponent<HeaderMessageProps> = ({
    hide = false,
    message = "",
    onHide,
    timer = false,
    timerOptions = {},
}: HeaderMessageProps) => {
    const { days, hours, minutes } = timerOptions;

    const splittedDays = days?.toString().padStart(2, "0").split("");
    const splittedHours = hours?.toString().padStart(2, "0").split("");
    const splittedMinutes = minutes?.toString().padStart(2, "0").split("");

    return (
        <div className="header-message" hidden={hide}>
            <div className="container">
                <div className="header-message__text">{message}</div>
                {timer && timerOptions && (
                    <div className="header-timer">
                        {splittedDays && (
                            <div className="header-timer__col">
                                <div className="header-timer__label">Дней</div>
                                {splittedDays.map((number, i) => (
                                    <div key={i} className="header-timer__value">
                                        {number}
                                    </div>
                                ))}
                            </div>
                        )}
                        {splittedHours && (
                            <div className="header-timer__col">
                                <div className="header-timer__label">Часов</div>
                                {splittedHours.map((number, i) => (
                                    <div key={i} className="header-timer__value">
                                        {number}
                                    </div>
                                ))}
                            </div>
                        )}
                        {splittedMinutes && (
                            <div className="header-timer__col">
                                <div className="header-timer__label">Минут</div>
                                {splittedMinutes.map((number, i) => (
                                    <div key={i} className="header-timer__value">
                                        {number}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="header-message__close" onClick={onHide} />
        </div>
    );
};

export default HeaderMessage;
