import React, { useState } from "react";
import clsx from "clsx";
import { CommonComponentProps } from "../../types";
import Input, { InputProps } from "..";

export type PasswordInputProps = InputProps & Partial<{}>;

const PasswordInput: React.FC<CommonComponentProps<PasswordInputProps>> = ({
    className,
    style,
    ...other
}: CommonComponentProps<PasswordInputProps>) => {
    const [type, setType] = useState<"text" | "password">("password");

    const classes = clsx({
        [className ?? ""]: !!className,
    });

    const handleChangeType = () => {
        setType((prev) => (prev === "text" ? "password" : "text"));
    };

    return (
        <div className="form-inputwrap">
            <Input className={classes} style={style} type={type} {...other} />
            <div className="form-input__showpass" onClick={handleChangeType}>
                {type === "password" ? "Показать" : "Скрыть"}
            </div>
        </div>
    );
};

export default PasswordInput;
