import { Exclude, Expose } from "class-transformer";

@Exclude()
class RefreshResult {
    @Expose({ name: "access_token" }) accessToken: string;

    @Expose({ name: "access_token_expires" }) accessTokenExpires: number;

    @Expose() result: boolean;
}

export default RefreshResult;
