import { Exclude, Expose, Transform } from "class-transformer";

@Exclude()
export class CreateOrderDto {
    @Expose({ name: "paymentMethod" })
    payment_method: number;

    @Expose({ name: "promoCode" })
    promo_code: string;

    @Expose({ name: "fullName" })
    full_name: string;

    @Expose() phone: string;
    @Expose() email: string;
    @Expose() city: number;
    @Expose() street: string;
    @Expose() house: string;

    @Expose({ name: "apartment" })
    apartment_office: string;

    @Expose({ name: "comment" })
    comment_to_the_courier: string;

    @Expose()
    @Transform(({ obj }) => obj?.shop?.id)
    shop: number;

    @Expose({ name: "obtain" })
    delivery_method: number;

    @Expose({ name: "courierService" })
    delivery_services: number;
}
