import React from "react";
import { catalogApiService } from "@/api/services";
import { CreateProductReviewDto } from "@/api/services/catalog/dto";
import Modal from "@/components/Core/Modal";
import ReviewForm, { ReviewFormProps } from "@/components/Forms/ReviewForm";
import { useModal } from "@/modules/hooks";
import { WithAppModalComponentProps } from "@/modules/modals";

export type ReviewModalProps = WithAppModalComponentProps<Partial<{ entityId: number }>>;

const ReviewModal: React.FunctionComponent<ReviewModalProps> = ({ onClose, entityId }: ReviewModalProps) => {
    const { handleOpenModal } = useModal();

    const handleSendReview: ReviewFormProps["onSubmit"] = async (values) => {
        if (!entityId) return;

        try {
            const dto = catalogApiService.plainToClass(CreateProductReviewDto, { product: entityId, ...values });
            await catalogApiService.createProductReview(dto);
            handleOpenModal("MESSAGE", {
                title: "Отзыв отправлен!",
                message: "Ваш отзыв отправлен на модерацию.",
            });
        } catch {
            handleOpenModal("MESSAGE", {
                title: "Отзыв не отправлен.",
                message: "Увы, так бывает :(",
            });
        }
        onClose();
    };

    return (
        <Modal.Content>
            <Modal.Header onClose={onClose} />
            <Modal.Body>
                <ReviewForm onSubmit={handleSendReview} />
            </Modal.Body>
        </Modal.Content>
    );
};

export default ReviewModal;
