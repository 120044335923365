import * as React from "react";
import clsx from "clsx";
import { CommonComponentProps } from "../types";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    Partial<{
        variant: "default" | "primary" | "secondary" | "showmore" | "link";
        size: "md" | "sm";
        trans: boolean;
        accent: boolean;
        fullWidth: boolean;
        disableTextTransform: boolean;
        circle?: boolean;
    }>;

const Button: React.FC<CommonComponentProps<ButtonProps>> = ({
    children,
    className,
    style,
    trans = false,
    accent = false,
    size = "md",
    fullWidth = false,
    variant = "primary",
    disableTextTransform = false,
    circle = false,
    ...other
}: CommonComponentProps<ButtonProps>) => {
    const classes = clsx(
        { ["btn"]: variant !== "link" },
        {
            "btn-full": fullWidth,
            "btn-sm": size === "sm",
            "btn-accent": accent,
            "btn-trans": trans,
            "btn-default": variant === "default",
            "btn-second": variant === "secondary",
            showmore: variant === "showmore",
            btnlink: variant === "link",
            "btn-ttn": disableTextTransform,
            circle: circle,
            [className ?? ""]: !!className,
        }
    );

    return (
        <button type="button" className={classes} style={style} {...other}>
            {children}
        </button>
    );
};

export default Button;
