import React from "react";
import Layout from "@/components/Common/Layout";
import { WithPageProps } from "@/modules/pages";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import asyncComponent from "@/modules/hoc/AsyncComponent";

const BlogArticleAsync = asyncComponent(() => {
    return import("@/components/BlogArticle");
});
const BlogArticleOtherAsync = asyncComponent(() => {
    return import("@/components/BlogArticleOther");
});

const BlogDetailPage: React.FunctionComponent<WithPageProps> = (props: WithPageProps) => {
    const { search } = useLocation();

    const entityTypeId = props.config.catalogs.blogEtypeId;
    const entityId = parse(search, { parseNumbers: true })["e_id"] as number;

    const blogDetailProps = { entityTypeId, entityId };

    return (
        <Layout wrap {...props?.context?.context}>
            <BlogArticleAsync {...blogDetailProps} />
            <BlogArticleOtherAsync {...blogDetailProps} />
        </Layout>
    );
};

export default BlogDetailPage;
