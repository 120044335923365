import React, { PropsWithChildren } from "react";

import Box from "./Box";
import Item from "./Item";
import Title from "./Title";
import Text from "./Text";

export type DescriptionProps = PropsWithChildren<{}>;

const Description: React.FunctionComponent<DescriptionProps> = ({ children }: DescriptionProps) => {
    return <div className="product-desc">{children}</div>;
};

export default Object.assign(Description, { Box, Item, Title, Text });
