import React, { PropsWithChildren } from "react";

import Text from "./Text";
import Label from "./Label";
import Row from "./Row";
import Items from "./Items";

const SaleAttr: React.FC<unknown> = ({ children }: PropsWithChildren<unknown>) => {
    return <div className="product-sale_attr">{children}</div>;
};

export default Object.assign(SaleAttr, { Text, Label, Row, Items });
