import { Exclude, Expose } from "class-transformer";

@Exclude()
class LocalStrategyAuthDto {
    @Expose() username: string;

    @Expose() password: string;
}

export default LocalStrategyAuthDto;
