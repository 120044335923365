import React from "react";
import { range } from "@/modules/utils";
import dayjs from "@/modules/utils/dayjs";
import { UnitType } from "dayjs";

export interface DateInputProps {
    value: Date;
    onChange(date: Date): void;
}

const DateInput: React.FunctionComponent<DateInputProps> = ({ value, onChange }: DateInputProps) => {
    const day = dayjs(value).get("date");
    const month = dayjs(value).get("month");
    const year = dayjs(value).get("year");

    const dateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;

        const date = dayjs()
            .set("year", year)
            .set("month", month)
            .set("date", day)
            .set(name as UnitType, +value);

        onChange(date.toDate());
    };

    return (
        <div className="date-input">
            <select name="date" value={day} onChange={dateChange}>
                {range(1, dayjs(value).daysInMonth()).map((day) => (
                    <option key={day} value={day}>
                        {day}
                    </option>
                ))}
            </select>
            <select name="month" value={month} onChange={dateChange}>
                {dayjs.months().map((month, i) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select name="year" value={year} onChange={dateChange}>
                {range(1950, dayjs().year()).map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DateInput;
