import clsx from "clsx";
import React from "react";
import { OverridableComponentProps, OverridableComponent } from "../types";

type TypographyProps = Partial<{
    variant: "body" | "title";
    uppercase: boolean;
}>;

type TypograpyComponent = OverridableComponent<TypographyProps>;

export type TypograpyComponentProps = OverridableComponentProps<TypograpyComponent>;

const Typography: React.FC<TypograpyComponentProps> = ({
    component: Component = "p",
    children,
    className,
    style,
    variant = "body",
    uppercase = false,
}: TypograpyComponentProps) => {
    const classes = clsx("", {
        pages__title: variant === "title",
        uppercase: uppercase,
        [className ?? ""]: !!className,
    });

    return (
        <Component className={classes} style={style}>
            {children}
        </Component>
    );
};

export default Typography;
