import * as React from "react";

function SvgYoutube(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M37.1152 0H2.88483C1.2915 0 0 1.2915 0 2.88483V37.1152C0 38.7085 1.2915 40 2.88483 40H37.1152C38.7085 40 40 38.7085 40 37.1152V2.88483C40 1.2915 38.7085 0 37.1152 0V0ZM32.4969 20.0204C32.4969 20.0204 32.4969 24.0765 31.9824 26.0324C31.694 27.103 30.8499 27.9471 29.7794 28.2352C27.8235 28.75 20 28.75 20 28.75C20 28.75 12.197 28.75 10.2206 28.2147C9.15009 27.9266 8.30597 27.0822 8.01758 26.0117C7.50275 24.0765 7.50275 20 7.50275 20C7.50275 20 7.50275 15.9442 8.01758 13.9883C8.30566 12.9178 9.17053 12.0529 10.2206 11.7648C12.1765 11.25 20 11.25 20 11.25C20 11.25 27.8235 11.25 29.7794 11.7853C30.8499 12.0734 31.694 12.9178 31.9824 13.9883C32.5177 15.9442 32.4969 20.0204 32.4969 20.0204V20.0204Z"
                fill="currentColor"
            />
            <path d="M17.509 23.7468L24.0148 19.9999L17.509 16.2529V23.7468Z" fill="currentColor" />
        </svg>
    );
}

const MemoSvgYoutube = React.memo(SvgYoutube);
export default MemoSvgYoutube;
