import { AuthResult, RefreshResult } from "@/api/services/auth/models";
import { Customer, User } from "@/api/services/user/models";
import { Config } from "@/modules/constants";
import { useStoreon } from "@/store";
import Cookies from "universal-cookie";

const useAuth = () => {
    const { user, isAuthenticated, dispatch } = useStoreon("user", "isAuthenticated");
    const cookies = new Cookies();

    const authenticate = (authResult: AuthResult) => {
        cookies.set(Config.TOKEN_TYPE_KEY, authResult.tokenType, { path: "/" });
        cookies.set(Config.TOKEN_KEY, authResult.accessToken, { path: "/" });
        cookies.set(Config.TOKEN_EXPIRES_KEY, authResult.accessTokenExpires, { path: "/" });
        cookies.set(Config.REFRESH_TOKEN_KEY, authResult.refreshToken, { path: "/" });
        dispatch("auth/set/is_authenticated", true);
    };

    const refresh = (refreshResult: RefreshResult) => {
        cookies.set(Config.TOKEN_KEY, refreshResult.accessToken, { path: "/" });
        cookies.set(Config.TOKEN_EXPIRES_KEY, refreshResult.accessTokenExpires, { path: "/" });
        dispatch("auth/set/is_authenticated", true);
    };

    const deauthenticate = () => {
        cookies.remove(Config.TOKEN_TYPE_KEY, { path: "/" });
        cookies.remove(Config.TOKEN_KEY, { path: "/" });
        cookies.remove(Config.TOKEN_EXPIRES_KEY, { path: "/" });
        cookies.remove(Config.REFRESH_TOKEN_KEY, { path: "/" });
        dispatch("auth/set/is_authenticated", false);
        window.location.href = "/";
    };

    const setUser = (user: User) => {
        dispatch("auth/set/user", user);
    };

    const getAccessToken = (): Custom.Maybe<string> => {
        return cookies.get(Config.TOKEN_KEY);
    };

    const getAccessTokenExpires = (): Custom.Maybe<number> => {
        const expires = cookies.get(Config.TOKEN_EXPIRES_KEY);
        return expires ? +expires : null;
    };

    const getRefreshToken = (): Custom.Maybe<string> => {
        return cookies.get(Config.REFRESH_TOKEN_KEY);
    };

    const setCustomerToken = (customer: Customer): void => {
        cookies.set(Config.CUSTOMER_TOKEN_KEY, customer.number, { path: "/" });
    };

    const getCustomerToken = (): Custom.Maybe<string> => {
        return cookies.get(Config.CUSTOMER_TOKEN_KEY);
    };

    const removeCustomerToken = (): void => {
        cookies.remove(Config.CUSTOMER_TOKEN_KEY, { path: "/" });
    };

    return {
        user,
        isAuthenticated,
        authenticate,
        deauthenticate,
        setUser,
        getAccessToken,
        getRefreshToken,
        getAccessTokenExpires,
        refresh,
        setCustomerToken,
        getCustomerToken,
        removeCustomerToken,
    };
};

export default useAuth;
