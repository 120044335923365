import BaseApiService from "@/api/base";
import { Config } from "@/modules/constants";
import { CoreQueryParams } from "@/api/types";
import BaseMapper from "./mappers/BaseMapper";
import { ClassConstructor } from "class-transformer";

type PersonalComponentParams = CoreQueryParams & { et_id: number };

export type PersonalComponentQueryResult<T> = { result: T[]; count: number; total_pages: number };
class ComponentsApiService extends BaseApiService {
    getPersonalComponent = async <M extends BaseMapper>(
        params: PersonalComponentParams,
        Mapper: ClassConstructor<M>
    ): Promise<PersonalComponentQueryResult<M>> => {
        const { data } = await this.http.get(`/multisite/personal_components/`, { params });

        const json: PersonalComponentQueryResult<M> = JSON.parse(data);
        const result = this.plainToClassArray(Mapper, json.result);
        json.result = result;

        return json;
    };

    getOnePersonalComponent = async <M extends BaseMapper>(
        id: number,
        entityTypeId: number,
        Mapper: ClassConstructor<M>
    ): Promise<Custom.Maybe<M>> => {
        const { data } = await this.http.get("/multisite/personal_components/", {
            params: { ids_list: id, et_id: entityTypeId, page: 1, page_size: 1 },
        });

        const json: PersonalComponentQueryResult<M> = JSON.parse(data);
        const result = this.plainToClassArray(Mapper, json.result);

        return this.getFirstItemOrNull<M>(result);
    };
}

export default new ComponentsApiService({
    baseURL: Config.CMS_API_URL,
});
