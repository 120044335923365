import React, { PropsWithChildren } from "react";

type LinkProps = PropsWithChildren<Partial<{ href: string }>>;

const Link: React.FC<LinkProps> = ({ children, href }: PropsWithChildren<LinkProps>) => {
    return (
        <div className="product-sizes__linkwrap">
            <a className="product-sizes__link" href={href}>
                {children}
            </a>
        </div>
    );
};

export default Link;
