import { Comparison } from "@/api/services/compare/models";
import { StoreonModule } from "storeon";

export interface ICompareItem {
    entityTypeId?: number;
    entityId: number;
}

export interface ICompareModuleState {
    compareItems: Comparison[];
}

export interface ICompareModuleEvents {
    "compare/set": Comparison[];
}

export const compareModule: StoreonModule<ICompareModuleState, ICompareModuleEvents> = (store) => {
    store.on("@init", () => ({
        compareItems: [],
    }));

    store.on("compare/set", (state, compareItems) => ({
        compareItems,
    }));
};
