import React, { PropsWithChildren } from "react";

export type WrapProps = PropsWithChildren<Partial<{ type: "top" | "bot"; href: string }>>;

const Wrap: React.FC<WrapProps> = ({ children, href, type }: WrapProps) => {
    const Component: keyof React.ReactHTML = type === "top" ? "a" : "div";

    return (
        <Component className={`catcard__${type}wrap`} href={href}>
            {children}
        </Component>
    );
};

export default Wrap;
