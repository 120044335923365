import { Exclude, Expose, Transform } from "class-transformer";

@Exclude()
class TypeSchemaAttribute {
    @Expose({ name: "at_id" }) atypeId: number;

    @Expose({ name: "a_name" }) name: string;

    @Expose({ name: "a_notes" }) note: string;

    @Expose({ name: "composite_choice" }) isComposite: boolean;

    @Expose({ name: "multiple_choice" }) isMultiple: boolean;

    @Expose({ name: "is_active" }) isActive: boolean;

    @Expose() sorting: number;

    @Expose()
    @Transform(({ obj }) => {
        return obj.items.map((item) => {
            const mofify = { ...item };
            mofify.restrictions = mofify.restrictions ? mofify.restrictions[0] : {};
            return mofify;
        });
    })
    items: any[];
}

export default TypeSchemaAttribute;
