import React, { PropsWithChildren } from "react";
import AuthContent from "./AuthContent";
import AuthCover from "./AuthCover";

export type WrapperProps = PropsWithChildren<Partial<{}>>;

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children }: WrapperProps) => {
    return (
        <div className="mlogin">
            <div className="mlogin__row">{children}</div>
        </div>
    );
};

export default Object.assign(Wrapper, {
    Cover: AuthCover,
    Content: AuthContent,
});
