import { InnerHtml } from "@/components/Core";
import React from "react";

export type Technology = { image: string; href: string; title: string; tag: string; text: string };

export type CardProps = Partial<Technology>;

const Card: React.FunctionComponent<CardProps> = ({ href, image, title, tag, text = "" }: CardProps) => {
    return (
        <a className="technologies-item" href={href}>
            <div className="technologies-item__imagewrap">
                <img className="technologies-item__image" src={image} alt={title} />
            </div>
            <div className="technologies-item__content">
                <p className="technologies-item__tag">{tag}</p>
                <h3 className="mainnews-item__title">{title}</h3>
                <InnerHtml html={text} className="mainnews-item__text-nomargin" />
            </div>
        </a>
        /*
        <a className="technologies-item" href={href}>
            <div className="technologies-item-column">
                <div className="technologies-item-column__imagewrap">
                    <img className="technologies-item-column__image" src={image} alt={title}/>
                </div>
            </div>
            <div className="technologies-item-column">
                <div className="technologies-item-column__content">
                    <p className="technologies-item-column__tag">{tag}</p>
                    <h3 className="mainnews-item__title">{title}</h3>
                    <InnerHtml html={text} className="mainnews-item__text-nomargin"/>
                </div>
            </div>
        </a>

          */
    );
};

export default Card;
