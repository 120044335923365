import React from "react";
import clsx from "clsx";
import { OverridableComponent, OverridableComponentProps } from "../types";

export type TabsContentProps = React.HTMLAttributes<HTMLElement>;

type TabsContentComponent = OverridableComponent<TabsContentProps>;

export type TabsContentComponentProps = OverridableComponentProps<TabsContentComponent>;

const TabsContent: React.FC<TabsContentComponentProps> = ({
    component: Component = "div",
    className,
    children,
    ...other
}: TabsContentComponentProps) => {
    const classes = clsx("btabs__content", {
        [className ?? ""]: !!className,
    });

    return (
        <Component className={classes} {...other}>
            {children}
        </Component>
    );
};

export default TabsContent;
