import { Exclude, Expose } from "class-transformer";

@Exclude()
export class ProductGroupAttrs {
    @Expose() other: any;
    @Expose() title: any;
    @Expose() description: any;
    @Expose() size: any;
    @Expose() color: any;
    @Expose() brands: any;
    @Expose() sku: any;
    @Expose() images: any;
}

@Exclude()
export class ProductModel {
    @Expose() type: string;
    @Expose() id: number;
    @Expose({ name: "et_id" }) entityTypeId: number;
    @Expose({ name: "group_attrs" }) groupAttrs: ProductGroupAttrs;
    @Expose({ name: "rrc_price" }) oldPrice: number;
    @Expose({ name: "sale_price" }) salePrice: number;
    @Expose() slug: string;
    @Expose() title: string;
}
