import clsx from "clsx";
import React, { PropsWithChildren } from "react";

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
    Partial<{
        primary: boolean;
    }>;

const Link: React.FC<LinkProps> = ({ children, primary, ...other }: PropsWithChildren<LinkProps>) => {
    return (
        <a className={clsx("header-bottommenu__link", { ["header-bottommenu__link--blog"]: primary })} {...other}>
            {children}
        </a>
    );
};

export default Link;
