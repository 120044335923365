import * as React from "react";

function SvgCatalog(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.8599 10.265L15.813 10.1786L15.7259 10.224L15.0195 10.5914L15.0195 10.5914C14.1408 11.0487 13.1272 11.1937 12.1514 11.0013C11.1757 10.809 10.2987 10.2914 9.66954 9.53725C9.04046 8.78314 8.69811 7.8391 8.70038 6.86601C8.70266 5.89292 9.04941 4.95041 9.68201 4.19912C10.3146 3.44778 11.1941 2.93411 12.1707 2.74615C13.1474 2.55819 14.1603 2.70768 15.0368 3.16895C15.9133 3.6302 16.5988 4.3745 16.9771 5.27461C17.3553 6.17434 17.4032 7.17445 17.1129 8.10482C17.0754 8.21641 17.0609 8.33427 17.0704 8.45148C17.0799 8.56909 17.1133 8.68357 17.1685 8.78821C17.2238 8.89284 17.2997 8.98545 17.3919 9.0607C17.484 9.13594 17.5904 9.19231 17.7048 9.22659C17.8193 9.26088 17.9395 9.27242 18.0585 9.26056C18.1775 9.2487 18.293 9.21368 18.3981 9.15746C18.5033 9.10125 18.596 9.02495 18.6709 8.93295C18.7455 8.84125 18.8008 8.73581 18.8334 8.62275C19.0676 7.87242 19.1487 7.08424 19.0719 6.30318C18.9952 5.52178 18.762 4.76291 18.3858 4.06991C18.0095 3.37692 17.4977 2.76342 16.8795 2.26436C16.2614 1.7653 15.549 1.39044 14.7831 1.16109C13.2365 0.69792 11.5652 0.855314 10.1369 1.59884C8.70849 2.34239 7.63961 3.61148 7.16614 5.12749L7.14789 5.18591L7.14895 5.18694C6.83341 6.23541 6.81741 7.34901 7.10333 8.40643C7.39458 9.48358 7.98744 10.4593 8.81471 11.2235C9.64196 11.9878 10.6707 12.5102 11.7847 12.7321C12.8704 12.9483 13.9953 12.8707 15.0386 12.5083L17.2778 16.6321C17.3902 16.8391 17.5817 16.9934 17.8095 17.0616C18.0372 17.1298 18.2833 17.1066 18.4938 16.997C18.7044 16.8874 18.8625 16.7 18.9326 16.4754C19.0027 16.2508 18.9788 16.0082 18.8663 15.8011L18.79 15.8425L18.8663 15.8011L15.8599 10.265Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth={0.2}
            />
            <path
                d="M0.9 18.8333C0.9 19.0809 0.998333 19.3183 1.17337 19.4933C1.3484 19.6683 1.5858 19.7667 1.83333 19.7667H14.3617C14.6092 19.7667 14.8466 19.6683 15.0216 19.4933C15.1967 19.3183 15.295 19.0809 15.295 18.8333C15.295 18.5858 15.1967 18.3484 15.0216 18.1734C14.8466 17.9983 14.6092 17.9 14.3617 17.9H1.83333C1.5858 17.9 1.3484 17.9983 1.17337 18.1734C0.998333 18.3484 0.9 18.5858 0.9 18.8333Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth={0.2}
            />
            <path
                d="M0.9 14.8333C0.9 15.0809 0.998333 15.3183 1.17337 15.4933C1.3484 15.6683 1.5858 15.7667 1.83333 15.7667H7.44583C7.69337 15.7667 7.93077 15.6683 8.1058 15.4933C8.28083 15.3183 8.37917 15.0809 8.37917 14.8333C8.37917 14.5858 8.28083 14.3484 8.1058 14.1734C7.93077 13.9983 7.69337 13.9 7.44583 13.9H1.83333C1.5858 13.9 1.3484 13.9983 1.17337 14.1734C0.998333 14.3484 0.9 14.5858 0.9 14.8333Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth={0.2}
            />
            <path
                d="M1.17337 11.4933C1.3484 11.6683 1.5858 11.7667 1.83333 11.7667H4.33333C4.58087 11.7667 4.81827 11.6683 4.9933 11.4933C5.16833 11.3183 5.26667 11.0809 5.26667 10.8333C5.26667 10.5858 5.16833 10.3484 4.9933 10.1734C4.81827 9.99833 4.58087 9.9 4.33333 9.9H1.83333C1.5858 9.9 1.3484 9.99833 1.17337 10.1734C0.998333 10.3484 0.9 10.5858 0.9 10.8333C0.9 11.0809 0.998333 11.3183 1.17337 11.4933Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth={0.2}
            />
        </svg>
    );
}

const MemoSvgCatalog = React.memo(SvgCatalog);
export default MemoSvgCatalog;
