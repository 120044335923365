import * as React from "react";

function SvgFavorite(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M21.4452 6.4419C21.4033 4.76232 20.7499 3.17489 19.6064 1.97279C18.4504 0.758135 16.9132 0.0586588 15.2797 0.00839705C13.5666 -0.0502416 11.9373 0.603161 10.7227 1.7885C9.54571 0.645046 7.9834 -0.00416834 6.34152 2.01422e-05C4.68707 0.0083971 3.12477 0.674365 1.93105 1.88065C0.691264 3.13719 -0.0124 4.8796 0.000165457 6.66389C0.0127309 8.40211 0.670322 10.0356 1.85566 11.2712L9.96874 19.6817C10.174 19.8953 10.4462 20 10.7227 20C10.9824 20 11.2462 19.9037 11.4515 19.7068C11.8661 19.3047 11.8787 18.6429 11.4766 18.2241L3.36351 9.822C2.55514 8.97593 2.10278 7.84923 2.0944 6.64713C2.08603 5.41572 2.5677 4.21782 3.41796 3.35499C4.21377 2.54661 5.25671 2.09845 6.3499 2.09426H6.36665C7.74885 2.09426 9.05147 2.8063 9.85147 3.99583C10.0441 4.28483 10.3708 4.45656 10.7185 4.45656C11.0661 4.45656 11.3928 4.28483 11.5855 3.99583C12.419 2.76023 13.7719 2.04819 15.2043 2.09426C16.285 2.12777 17.307 2.59688 18.0818 3.40944C18.8693 4.23457 19.3174 5.32776 19.3426 6.48378C19.3719 7.71938 18.9363 8.88378 18.1195 9.76755C18.1195 9.77174 18.1153 9.77174 18.1153 9.77593L12.8378 15.2586C12.4358 15.6733 12.4483 16.3393 12.8672 16.7372C13.2818 17.1393 13.9478 17.1267 14.3457 16.7079L19.6399 11.2042L19.6734 11.1707C20.8588 9.89739 21.4871 8.21781 21.4452 6.4419Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={22} height={20} fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

const MemoSvgFavorite = React.memo(SvgFavorite);
export default MemoSvgFavorite;
