import React, { PropsWithChildren } from "react";

export type ShowAllProps = PropsWithChildren<
    Partial<{
        href: string;
    }>
>;

const ShowAll: React.FC<ShowAllProps> = ({ children = "Смотреть все", href = "#" }: ShowAllProps) => {
    return (
        <a className="catslider-showall btnlink" href={href}>
            {children}
        </a>
    );
};

export default ShowAll;
