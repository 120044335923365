import React, { PropsWithChildren } from "react";

export type TextProps = PropsWithChildren<React.InputHTMLAttributes<HTMLInputElement>>;

const Text: React.FC<TextProps> = ({ children, ...other }: TextProps) => {
    return (
        <div className="product-sale_attr__item">
            <input className="product-sale_attr__item-input" type="radio" {...other} />
            <label className="product-sale_attr__item-label" htmlFor={other.id}>
                {children}
            </label>
        </div>
    );
};

export default Text;
