import { compareApiService } from "@/api/services";
import { useStoreon } from "@/store";
import { ICompareItem } from "@/store/StoreModules/CompareModule";
import { useQuery } from "react-query";
import useAuth from "../useAuth";

interface UseCompareOptions {
    autoLoad?: boolean;
}

const useCompare = (options: UseCompareOptions = {}) => {
    const { autoLoad = false } = options;
    const { dispatch, compareItems } = useStoreon("compareItems");
    const { isAuthenticated } = useAuth();

    const { refetch } = useQuery(["comparison", autoLoad, isAuthenticated], compareApiService.getAll, {
        enabled: autoLoad,
        onSuccess: (data) => {
            dispatch("compare/set", data.results);
        },
    });

    const toggleCompare = async (obj: ICompareItem) => {
        await compareApiService.toggle(obj.entityId);
        await refetch();
    };

    const isCompare = (obj: ICompareItem) => {
        return compareItems.findIndex((item) => item.productId === Number(obj.entityId)) !== -1;
    };

    const getProductIds = () => compareItems.map((item) => item.productId);

    return {
        toggleCompare,
        isCompare,
        getProductIds,
        compareItems,
    };
};

export default useCompare;
