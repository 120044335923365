import React from "react";
import clsx from "clsx";
import { OverridableComponent, OverridableComponentProps } from "../types";

export type TabsProps = Partial<{
    variant: "ambatabs" | "cattabs" | "btabs";
    fullWidth: boolean;
}>;

type TabsComponent = OverridableComponent<TabsProps>;

export type TabsComponentProps = OverridableComponentProps<TabsComponent>;

const Tabs: React.FC<TabsComponentProps> = ({
    component: Component = "div",
    className,
    variant = "btabs",
    fullWidth = false,
    children,
}: TabsComponentProps) => {
    const classes = clsx({
        btabs: variant === "btabs",
        amba_tabs: variant === "ambatabs",
        cattabs: variant === "cattabs",
        "btabs-full": fullWidth,
        [className ?? ""]: !!className,
    });

    return <Component className={classes}>{children}</Component>;
};

export default Tabs;
