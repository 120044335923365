import React from "react";
import clsx from "clsx";
import { OverridableComponent, OverridableComponentProps } from "../types";

export type TabProps = Partial<{
    /** also `true` if `activeTab` equal to `id` */
    active: boolean;
    activeTab: string;
    id: string;
    onChange(e: React.MouseEvent<HTMLAnchorElement>, tab: string): void;
    wrap: boolean;
    variant: "btab" | "cattab" | "ambatab" | "producttab";
}>;

type TabComponent = OverridableComponent<TabProps>;

export type TabComponentProps = OverridableComponentProps<TabComponent>;

const Tab: React.FC<TabComponentProps> = ({
    component: Component = "li",
    className,
    id,
    wrap = false,
    active = false,
    activeTab = "",
    onChange,
    children,
    variant = "btab",
}: TabComponentProps) => {
    const show = active || activeTab === id;

    const wrapClasses = clsx("btabs__item", {
        amba_tabs__item: variant === "ambatab",
        btabs__item: variant === "btab",
        "product-tabs__item": variant === "producttab",
        [className ?? ""]: !!className,
    });

    const itemClasses = clsx("btabs__link", {
        cattabs__link: variant === "cattab",
        "cattabs__link--active": variant === "cattab" && show,
        active: show,
        show,
        [className ?? ""]: !!className,
    });

    const handleChange = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const tab = e.currentTarget.getAttribute("data-tab") || "";
        onChange && onChange(e, tab);
    };

    if (wrap) {
        return (
            <Component className={wrapClasses}>
                <a className={itemClasses} data-toggle="tab" data-tab={id} role="tab" onClick={handleChange}>
                    {children}
                </a>
            </Component>
        );
    }
    return (
        <a className={itemClasses} data-toggle="tab" data-tab={id} role="tab" onClick={handleChange}>
            {children}
        </a>
    );
};

export default Tab;
