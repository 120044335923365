import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateAddressDto {
    @Expose({ name: "userId" }) readonly user: number;

    @Expose() readonly address: string;
}

@Exclude()
export class UpdateAddressDto extends CreateAddressDto {}
