import React from "react";

export type AuthCoverProps = Partial<{}>;

const AuthCover: React.FunctionComponent<AuthCoverProps> = () => {
    return (
        <div className="mlogin__left">
            <img className="mlogin__img" src="/static/images/login-image.jpg" />
        </div>
    );
};

export default AuthCover;
