import { useStoreon } from "@/store";
import { ICartItem } from "@/store/StoreModules/CartModule";
import { useState } from "react";
import { cartApiService, discountApiService } from "@/api/services";
import { convertArrayToObject, debounce } from "@/modules/utils";
import { CartProductSKU } from "@/api/services/cart/models/CartProduct.model";
import { HttpError } from "@/api/http";

interface CartError {
    size?: string;
    unauthorized?: string;
}

// TODO обработать ошибки
const updateApiCart = debounce(cartApiService.updateCart, 500);

const useCart = () => {
    const { cartItems, productItems, dispatch } = useStoreon("cartItems", "productItems");
    const [isLoad, setLoad] = useState(false);
    const [error, setError] = useState<CartError | undefined>();
    const addToCart = async (obj: ICartItem) => {
        const { quantity = 1, sku } = obj;
        if (isLoad) {
            return;
        }
        setError({});
        setLoad(true);
        try {
            await cartApiService.createCart({
                count: quantity,
                sku,
            });
            dispatch("cart/set", { ...obj, quantity });
        } catch (err: unknown) {
            if ((err as HttpError).response?.status === 401) {
                setError({ unauthorized: "Вам необходимо авторизоваться на сайте" });
            }
        }

        setLoad(false);
    };
    const removeFromCart = async (entityId: number) => {
        try {
            await cartApiService.removeElement(entityId);
            dispatch("cart/remove", entityId);
        } catch (e) {
            console.log(e);
        }
    };
    const updateCart = ({ sku, ...obj }: ICartItem) => {
        if (cartItems[sku]) {
            const newObj = { ...cartItems[sku], ...obj };
            dispatch("cart/set", newObj);
            updateApiCart(sku, {
                count: newObj.quantity,
            });
        }
    };
    const removeUnit = (entityId: number) => {
        if (cartItems[entityId]) {
            const quantity = cartItems[entityId]?.quantity;
            if (quantity && quantity > 1) {
                updateCart({ ...cartItems[entityId], quantity: quantity - 1 });
            }
        }
    };
    const addUnit = (entityId: number) => {
        if (cartItems[entityId]) {
            const quantity = cartItems[entityId]?.quantity || 1;
            updateCart({ ...cartItems[entityId], quantity: quantity + 1 });
        }
    };
    const loadCart = async () => {
        const res = await cartApiService.getCart();
        const cartItems = convertArrayToObject(res, "sku");
        dispatch("cart/load", cartItems);
    };
    const clearCartClient = () => {
        dispatch("cart/clear");
    };
    const getPromo = async (skus: string[], promo: string[]) => {
        if (isLoad) {
            return;
        }
        setLoad(true);
        const response = await discountApiService.getDiscount(skus, promo);
        let updatedItems = [...productItems];
        if (response && response.length) {
            response.forEach((discountItem) => {
                if (discountItem) {
                    updatedItems = updatedItems.map((item) =>
                        item.sku.attr.sku?.value === discountItem?.sku
                            ? {
                                  ...item,
                                  sku: {
                                      ...item.sku,
                                      salePrice: Number(discountItem.salePrice),
                                      rrcPrice: Number(discountItem.rrcPrice),
                                  },
                              }
                            : item
                    );
                }
            });
            dispatch("product/set", updatedItems);
        }
        setLoad(false);
        return response;
    };
    const calcCost = (data: CartProductSKU[], cartItems) => {
        return data.reduce(
            (pValue, cValue) => {
                const oldPrice = cValue.sku.rrcPrice ? cValue.sku.rrcPrice : cValue.sku.salePrice;
                return {
                    fullCost: pValue.fullCost + (cartItems?.[cValue.sku.id]?.quantity || 0) * cValue.sku.salePrice,
                    oldCost: pValue.oldCost + (cartItems?.[cValue.sku.id]?.quantity || 0) * oldPrice,
                };
            },
            {
                fullCost: 0,
                oldCost: 0,
            }
        );
    };
    const calcDiscount = (data: CartProductSKU[], currentCost) => {
        const defaultCost = data.reduce(
            (pValue, cValue) => {
                const oldPrice = cValue.sku.rrcPrice ? cValue.sku.rrcPrice : cValue.sku.salePrice;
                return {
                    fullCost: pValue.fullCost + (cartItems?.[cValue.sku.id]?.quantity || 0) * cValue.sku.salePrice,
                    oldCost: pValue.oldCost + (cartItems?.[cValue.sku.id]?.quantity || 0) * oldPrice,
                };
            },
            {
                fullCost: 0,
                oldCost: 0,
            }
        );
        return defaultCost.fullCost - defaultCost.oldCost - currentCost;
    };
    const totalElements = Object.keys(cartItems).length;

    return {
        addToCart,
        removeFromCart,
        updateCart,
        addUnit,
        removeUnit,
        loadCart,
        isLoad,
        clearCartClient,
        cartItems,
        totalElements,
        calcCost,
        getPromo,
        calcDiscount,
        cartError: error,
    };
};

export default useCart;
