import BaseApiService from "@/api/base";
import { Config } from "@/modules/constants";
import { Discount } from "./models";

class DiscountApiService extends BaseApiService {
    getDiscount = async (skuList: string[], keys: string[]): Promise<Custom.Maybe<Array<Discount | null>>> => {
        const response = await this.http.get("/store/discount", {
            params: {
                sku_list: `{${skuList.join(",")}}`,
                keys: `{${keys.join(",")}}`,
            },
        });
        const data = response.data.result instanceof Array ? response.data.result : [response.data.result];
        return data.map((item) => this.plainToClassArray(Discount, item));
    };
}

export default new DiscountApiService({
    baseURL: Config.CORE_PROXY_URL,
});
