import BaseApiService from "@/api/base";
import { CustomerTokenInterceptor, TokenInterceptor } from "@/api/http/interceptors";
import { PaginatedQueryResult } from "@/api/shared/models";
import { Config } from "@/modules/constants";
import { Favorite } from "./models";

class FavoriteApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
        this.http.interceptors.request.use(CustomerTokenInterceptor);
    }

    getAll = async (): Promise<PaginatedQueryResult<Favorite>> => {
        const { data } = await this.http.get(`/catalog/favourite/`);
        return this.plainToClassFromExist(new PaginatedQueryResult<Favorite>(Favorite), data);
    };

    toggle = async (productId: number): Promise<{ count: number; status: string }> => {
        const { data } = await this.http.post(`/catalog/favourite/toggle/${productId}/`);
        return data;
    };
}

export default new FavoriteApiService({
    baseURL: Config.CMS_API_URL,
});
