import { arrayToSubArrays, groupByKey } from "@/modules/utils";
import { Entity } from "../models";
import { AttributeValue } from "../models/Entity.model";
import { CatalogMenuSchema } from "../schemas";
import EntityModel from "./EntityModel";

interface Subcat {
    title: string;
    url: string;
    groupNumber: number;
    isHead: boolean;
    sorting: number | null;
}

type Subcats = Record<string, Subcat[]>;

class CatalogMenuEntity extends EntityModel<CatalogMenuSchema> implements Readonly<CatalogMenuEntity> {
    title: string;
    subcats: Subcats;
    brandsIds: number[];
    bannerId: number | null;
    tags: AttributeValue[][];

    constructor(entity: Entity<CatalogMenuSchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.subcats = this.getSubcats(entity.getFirstAttribute("subcats")?.values.filter((el) => !!el.value) || []);
        this.brandsIds = entity.getFirstAttribute("brands")?.values.map((el) => el.entityId) || [];
        this.bannerId = entity.getFirstAttribute("banner")?.getFirstValue()?.entityId || null;
        this.tags = arrayToSubArrays(
            entity.getFirstAttribute("tags_with_links")?.values.filter((el) => !!el.value) || [],
            2
        );
    }

    private getSubcats(values: AttributeValue[]): Subcats {
        const subcats: Subcats = groupByKey(
            arrayToSubArrays(values, 4).map(
                (el) =>
                    ({
                        title: el[0] ? el[0].value : "",
                        url: el[1] ? el[1].value : "",
                        isHead: el[2] ? el[2].value === "true" : false,
                        groupNumber: el[3] ? Number(el[3].value) : 1,
                        sorting: el[0] ? el[0].sorting : 0,
                    } as Subcat)
            ),
            "groupNumber"
        );

        for (const group in subcats) {
            subcats[group].sort((a, b) => {
                return Number(b.sorting !== null) - Number(a.sorting !== null) || Number(a.sorting) - Number(b.sorting);
            });
        }

        return subcats;
    }
}

export default CatalogMenuEntity;
