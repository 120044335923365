import React from "react";

type ItemProps = Partial<{ href: string; image: string }>;

const Item: React.FC<ItemProps> = ({ href, image }: ItemProps) => {
    return (
        <a className="popmenu-brands__item" href={href}>
            <img className="popmenu-brands__img" src={image} />
        </a>
    );
};

export default Item;
