import clsx from "clsx";
import React from "react";
import { OverridableComponentProps, OverridableComponent } from "../types";

export type RadioProps = Partial<{ inputClassName: string; labelClassName: string }>;

type RadioComponent = OverridableComponent<RadioProps>;

export type RadioComponentProps = React.InputHTMLAttributes<HTMLInputElement> &
    OverridableComponentProps<RadioComponent>;

const Radio: React.FunctionComponent<RadioComponentProps> = ({
    id = "example",
    component: Component = "div",
    children,
    className,
    style,
    inputClassName,
    labelClassName,
    ...other
}: RadioComponentProps) => {
    const classes = clsx("form-radio", {
        [className ?? ""]: !!className,
    });

    const inputClasses = clsx("form-radio__input", {
        [inputClassName ?? ""]: !!inputClassName,
    });

    const labelClasses = clsx("form-radio__label", {
        [labelClassName ?? ""]: !!labelClassName,
    });

    return (
        <Component className={classes} style={style}>
            <input className={inputClasses} type="radio" id={id} {...other} />
            <label className={labelClasses} htmlFor={id}>
                {children}
            </label>
        </Component>
    );
};

export default Radio;
