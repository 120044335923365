import clsx from "clsx";
import React, { PropsWithChildren } from "react";

type CategoryProps = PropsWithChildren<Partial<{ active: boolean; onSelect(e: React.MouseEvent<HTMLElement>): void }>>;

const Category: React.FC<CategoryProps> = ({ children, active, onSelect }: CategoryProps) => {
    return (
        <div className={clsx("popmenu-catlink", { ["popmenu-catlink--active"]: active })} onClick={onSelect}>
            {children}
        </div>
    );
};

export default Category;
