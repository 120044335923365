import { Exclude, Expose, Type } from "class-transformer";
import { ICartItem } from "@/store/StoreModules/CartModule";
import { SkuOrder } from "@/api/services/catalog/models/Product.model";

@Exclude()
class OrderItem {
    @Expose() id: number;

    @Expose({ name: "product" }) productId: number;

    @Expose() count: number;

    @Expose({ name: "order" }) orderId: number;

    @Expose({ name: "user" }) userId: number;

    @Expose({ name: "sale_price" }) salePrice: string;

    @Expose()
    get fullCost(): number {
        return Number(this.salePrice) * this.count;
    }

    @Expose() params: ICartItem;

    @Type(() => SkuOrder)
    @Expose()
    sku: SkuOrder;

    @Expose({ name: "bonus_amount" })
    bonusAmount: number;
}

export default OrderItem;
