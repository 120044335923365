import { Entity } from "../models";
import { ColorSchema } from "../schemas";
import EntityModel from "./EntityModel";

class ColorEntity extends EntityModel<ColorSchema> {
    readonly title: string;
    readonly hex: string;
    readonly null: string;
    readonly colorForFiltres: string;
    readonly colorBrand: string; //vma 13/02/2024

    constructor(entity: Entity<ColorSchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.hex = entity.getFirstAttribute("hex")?.getFirstValue()?.value || "";
        this.null = entity.getAttribute("null", 1)?.getFirstValue()?.value || "";
        this.colorForFiltres = entity.getAttribute("color_for_filtres")?.getFirstValue()?.value || "";
        this.colorBrand = entity.getAttribute("color_brand")?.getFirstValue()?.value || ""; //vma 13/02/2024
    }
}

export default ColorEntity;
