import { WithPageProps } from "./types";
import asyncComponent from "../hoc/AsyncComponent";
import { Locator } from "../locator";
import HomePage from "@/pages/HomePage";
import ProductPage from "@/pages/ProductPage";
import CatalogPage from "@/pages/CatalogPage";
import BlogDetailPage from "@/pages/BlogDetailPage";

const importPage = (pageName: string) => () => {
    return import(`@/pages/${pageName}`);
};

export type PageType =
    | "HOME"
    | "DEFAULT"
    | "CONTACTS"
    | "ABOUT"
    | "LK_PERSONAL"
    | "LK_ORDERS"
    | "LK_WISHLIST"
    | "CART"
    | "COMPARISON"
    | "PRODUCT"
    | "ORDERING"
    | "PAYMENT"
    | "CATALOG"
    | "Category"
    | "Product"
    | "NEWS"
    | "NEWS_DETAIL"
    | "BRANDS"
    | "BRAND_DETAIL"
    | "TECHNOLOGIES"
    | "TECHNOLOGY_DETAIL"
    | "REVIEW_PRODUCTS"
    | "REVIEW_PRODUCT_DETAIL"
    | "BLOG"
    | "BLOG_DETAIL"
    | "TEAM"
    | "TEAM_DETAIL"
    | "SHOPS"
    | "SHOP_DETAIL"
    | "INFO_GUARANTEE"
    | "INFO_PAYMENT"
    | "INFO_EXCHANGE"
    | "INFO_DELIVERY"
    | "INFO_DISCOUNTS"
    | "SEARCH"
    | "CONTENT_UNIVERSAL"
    | "SET_PASSWORD"
    | "404"
    | "500";

const pageRegistry = new Locator<PageType, React.ComponentType<WithPageProps>>();

pageRegistry.register("HOME", HomePage);
pageRegistry.register("DEFAULT", asyncComponent(importPage("DefaultPage")));
pageRegistry.register("CONTACTS", asyncComponent(importPage("ContactsPage")));
pageRegistry.register("ABOUT", asyncComponent(importPage("AboutPage")));
pageRegistry.register("LK_PERSONAL", asyncComponent(importPage("CabinetPersonalPage")));
pageRegistry.register("LK_ORDERS", asyncComponent(importPage("CabinetOrdersPage")));
pageRegistry.register("LK_WISHLIST", asyncComponent(importPage("CabinetWishlistPage")));
pageRegistry.register("CART", asyncComponent(importPage("CartPage")));
pageRegistry.register("COMPARISON", asyncComponent(importPage("ComparisonPage")));
pageRegistry.register("PRODUCT", ProductPage);
pageRegistry.register("Product", ProductPage);
pageRegistry.register("ORDERING", asyncComponent(importPage("OrderingPage")));
pageRegistry.register("PAYMENT", asyncComponent(importPage("PaymentPage")));
pageRegistry.register("CATALOG", CatalogPage);
pageRegistry.register("Category", CatalogPage);
pageRegistry.register("NEWS", asyncComponent(importPage("NewsPage")));
pageRegistry.register("NEWS_DETAIL", asyncComponent(importPage("NewsDetailPage")));
pageRegistry.register("BRANDS", asyncComponent(importPage("BrandsPage")));
pageRegistry.register("BRAND_DETAIL", asyncComponent(importPage("BrandDetailPage")));
pageRegistry.register("TECHNOLOGIES", asyncComponent(importPage("TechnologiesPage")));
pageRegistry.register("TECHNOLOGY_DETAIL", asyncComponent(importPage("TechnologyDetailPage")));
pageRegistry.register("REVIEW_PRODUCTS", asyncComponent(importPage("ReviewProductsPage")));
pageRegistry.register("REVIEW_PRODUCT_DETAIL", asyncComponent(importPage("ReviewProductDetailPage")));
pageRegistry.register("BLOG", asyncComponent(importPage("BlogPage")));
pageRegistry.register("BLOG_DETAIL", BlogDetailPage);
pageRegistry.register("TEAM", asyncComponent(importPage("TeamPage")));
pageRegistry.register("TEAM_DETAIL", asyncComponent(importPage("TeamDetailPage")));
pageRegistry.register("SHOPS", asyncComponent(importPage("ShopsPage")));
pageRegistry.register("SHOP_DETAIL", asyncComponent(importPage("ShopDetailPage")));
pageRegistry.register("INFO_GUARANTEE", asyncComponent(importPage("InformationalGuaranteePage")));
pageRegistry.register("INFO_DELIVERY", asyncComponent(importPage("InformationalDeliveryPage")));
pageRegistry.register("INFO_DISCOUNTS", asyncComponent(importPage("InformationalDiscountsPage")));
pageRegistry.register("INFO_EXCHANGE", asyncComponent(importPage("InformationalExchangePage")));
pageRegistry.register("INFO_PAYMENT", asyncComponent(importPage("InformationalPaymentPage")));
pageRegistry.register("SEARCH", asyncComponent(importPage("SearchPage")));
pageRegistry.register("SET_PASSWORD", asyncComponent(importPage("SetPassword")));
pageRegistry.register("CONTENT_UNIVERSAL", asyncComponent(importPage("ContentUniversalPage")));
pageRegistry.register("404", asyncComponent(importPage("Error404Page")));
pageRegistry.register("500", asyncComponent(importPage("Error500Page")));

export default pageRegistry;
