import React from "react";
import { WithPageProps } from "@/modules/pages";
import asyncComponent from "@/modules/hoc/AsyncComponent";
import Layout from "../components/Common/Layout";

const Component = asyncComponent(() => {
    return import("@/components/Home");
});

const HomePage: React.FunctionComponent<WithPageProps> = (props: WithPageProps) => {
    const { context } = props;

    const activitiesTypeId = props.config.catalogs.activitiesEtypeId;
    const blogTypeId = props.config.catalogs.blogEtypeId;
    const brandsTypeId = props.config.catalogs.brandsEtypeId;
    const newsTypeId = props.config.catalogs.newsEtypeId;
    const teamTypeId = props.config.catalogs.teamEtypeId;
    const carouselEtypeId = props.config.catalogs.carouselEtypeId;
    const gridEtypeId = props.config.catalogs.gridEtypeId;
    const siteAtypeId = props.config.catalogs.siteAtypeId;

    return (
        <Layout hideBreadcrumbs {...context}>
            <Component
                activitiesTypeId={activitiesTypeId}
                blogTypeId={blogTypeId}
                brandsTypeId={brandsTypeId}
                newsTypeId={newsTypeId}
                teamTypeId={teamTypeId}
                carouselEtypeId={carouselEtypeId}
                gridEtypeId={gridEtypeId}
                siteAtypeId={siteAtypeId}
            />
        </Layout>
    );
};

export default HomePage;
