import DOMPurify from "dompurify";

const shuffle = <T>(a: Array<T>) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
};

const isEveryTrue = (...items: boolean[]): boolean => {
    return items.every((item) => item === true);
};

const compose = (...rest) => (x) => rest.reduceRight((y, f) => f(y), x);

const cleanHtml = (rawHtml: string): string => DOMPurify.sanitize(rawHtml);

const randomIntFromInterval = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

const getDetailPath = (detail: string, entityTypeId: number, entityId: number): string => {
    return `${detail}?et_id=${entityTypeId}&e_id=${entityId}`;
};

const getMinimalDetailPath = (detail: string, entityId: number): string => {
    return `${detail}?e_id=${entityId}`;
};

const convertArrayToObject = (array: any[], key: string) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

const groupByKey = <T extends unknown>(list: T[], key: keyof T) => {
    return list.reduce(
        (hash, obj) => ({ ...hash, [obj[key as string]]: (hash[obj[key as string]] || []).concat(obj) }),
        {} as Record<string, T[]>
    );
};

const debounce = (callback: (...params: any[]) => any, delay: number) => {
    let inDebounce: ReturnType<typeof setTimeout>;

    return function (this: any, ...args: any[]) {
        clearTimeout(inDebounce);
        inDebounce = setTimeout(() => callback.apply(this, args), delay);
    };
};

const arrayToSubArrays = <T extends Record<string, any>>(items: T[], elementsPerSubArray: number): T[][] => {
    const arr: T[][] = [];

    for (let i = 0, k = -1; i < items.length; i++) {
        if (i % elementsPerSubArray === 0) {
            k++;
            arr.push([]);
        }

        arr[k].push(items[i]);
    }

    return arr;
};

const range = (from: number, to: number): number[] => Array.from({ length: 1 + to - from }, (v, k) => k + from);

export {
    shuffle,
    isEveryTrue,
    compose,
    cleanHtml,
    randomIntFromInterval,
    getDetailPath,
    getMinimalDetailPath,
    convertArrayToObject,
    debounce,
    groupByKey,
    arrayToSubArrays,
    range,
};
