import React, { PropsWithChildren } from "react";

import Label from "./Label";
import Form from "./Form";

const Subscribe: React.FC<unknown> = ({ children }: PropsWithChildren<unknown>) => {
    return <div className="footer-subscribe">{children}</div>;
};

export default Object.assign(Subscribe, { Label, Form });
