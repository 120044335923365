import { Formik, FormikProps, Form, FormikConfig } from "formik";
import * as Yup from "yup";
import React, { forwardRef } from "react";
import { FormGroup, FormLabel, Button, Textarea, Checkbox, FormHelperText, Rating } from "@/components/Core";

interface Review {
    text: string;
    rates: number | null;
    pdp: boolean;
}

const ReviewSchema = Yup.object().shape({
    text: Yup.string().required("Пожалуйста, напишите сообщение"),
    rates: Yup.number().nullable().required("Пожалуйста, укажите оценку"),
    pdp: Yup.boolean().required().oneOf([true], "Вы должны согласиться с условиями!"),
});

export type ReviewFormProps = Pick<FormikConfig<Review>, "onSubmit">;

const ReviewForm = forwardRef<FormikProps<Review>, ReviewFormProps>(({ onSubmit }: ReviewFormProps, ref) => {
    return (
        <Formik<Review>
            initialValues={{
                text: "",
                rates: null,
                pdp: false,
            }}
            innerRef={ref}
            validationSchema={ReviewSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue, isSubmitting }) => {
                return (
                    <Form className="contacts-form" noValidate>
                        <h2 className="contacts-heading">Написать отзыв</h2>

                        <FormGroup error={touched.rates && !!errors.rates}>
                            <FormLabel required>Ваша оценка</FormLabel>
                            <Rating value={values.rates ?? 0} onChange={(value) => setFieldValue("rates", value)} />
                            <div />
                            <FormHelperText variant="error">{touched.rates && errors.rates}</FormHelperText>
                        </FormGroup>

                        <FormGroup error={touched.text && !!errors.text}>
                            <FormLabel required>Текст отзыва</FormLabel>
                            <Textarea
                                required
                                name="text"
                                value={values.text}
                                placeholder="Введите свое сообщение"
                                onChange={handleChange}
                            />
                            <FormHelperText variant="error">{touched.text && errors.text}</FormHelperText>
                        </FormGroup>

                        <FormGroup error={touched.pdp && !!errors.pdp}>
                            <Checkbox
                                checkboxSize="sm"
                                id="pdp"
                                name="pdp"
                                checked={values.pdp}
                                onChange={(e) => setFieldValue(e.target.name, e.target.checked)}
                            >
                                Даю согласие на обработку <a href="#">персональных данных</a>
                            </Checkbox>
                            <FormHelperText variant="error">{touched.pdp && errors.pdp}</FormHelperText>
                        </FormGroup>

                        <FormGroup>
                            <Button type="submit" disabled={isSubmitting}>
                                {isSubmitting ? "Отправляем..." : "Отправить отзыв"}
                            </Button>
                        </FormGroup>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default ReviewForm;
