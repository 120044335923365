import clsx from "clsx";
import React from "react";
import { OverridableComponentProps, OverridableComponent } from "../types";

export type ChipProps = Partial<{ variant: "primary" | "secondary" }>;

type ChipComponent = OverridableComponent<ChipProps>;

export type ChipComponentProps = React.HTMLAttributes<HTMLElement> & OverridableComponentProps<ChipComponent>;

const Chip: React.FunctionComponent<ChipComponentProps> = ({
    component: Component = "a",
    children,
    className,
    style,
    variant = "primary",
    ...other
}: ChipComponentProps) => {
    const classes = clsx({
        "catalog-sorttags__item": variant === "primary",
        "catalog-sorttags__reset": variant === "secondary",
        [className ?? ""]: !!className,
    });

    return (
        <Component className={classes} style={style} href="#" {...other}>
            <span>{children}</span>
        </Component>
    );
};

export default Chip;
