import React from "react";
import clsx from "clsx";
import { AppModalComponentProps } from "@/modules/modals";
import { CommonComponentProps } from "../../types";

export type ModalHeaderProps = Partial<Pick<AppModalComponentProps, "onClose">>;

const ModalHeader: React.FC<CommonComponentProps<ModalHeaderProps>> = ({
    className,
    onClose,
}: CommonComponentProps<ModalHeaderProps>) => {
    const classes = clsx("modal-header", {
        [className ?? ""]: !!className,
    });

    return (
        <div className={classes}>
            {onClose && (
                <button
                    className="modal-close"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onClose}
                />
            )}
        </div>
    );
};

export default ModalHeader;
