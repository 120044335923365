import React, { PropsWithChildren } from "react";

import Thumbs from "./Thumbs";
import Carousel from "./Carousel";

export type WrapperProps = PropsWithChildren<{}>;

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children }: WrapperProps) => {
    return <div className="product-images">{children}</div>;
};

export default Object.assign(Wrapper, { Thumbs, Carousel });
