import { Button } from "@/components/Core";
import React from "react";

export type CatalogProps = Partial<{
    onToggleCatalog(e: React.MouseEvent<HTMLElement>): void;
}>;

const Catalog: React.FC<CatalogProps> = ({ onToggleCatalog }: CatalogProps) => {
    return (
        <Button className="header-catbtn" onClick={onToggleCatalog}>
            <i />
            <span>Каталог</span>
        </Button>
    );
};

export default Catalog;
