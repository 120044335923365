import { Exclude, Expose } from "class-transformer";

@Exclude()
export class CreateOrderItemDto {
    @Expose() readonly user: number;

    @Expose() readonly order: number;

    @Expose() readonly product: number;

    @Expose() readonly count: number;

    @Expose() readonly params?: Record<string, unknown>;

    @Expose({ name: "bonus_amount" }) readonly bonusAmount?: number;
}
