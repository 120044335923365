import { Config } from "@/modules/constants";
import { DefaultTheme } from "@/modules/theme";
import { Theme } from "@/modules/theme/types";
import { useEffect } from "react";

const useTheme = (options: { autoSetup?: boolean } = {}) => {
    const { autoSetup = false } = options;

    const theme: Theme | undefined = Config.THEME;

    const setupTheme = (theme: Theme) => {
        document.documentElement.setAttribute("data-theme", theme);
    };

    useEffect(() => {
        if (autoSetup && theme) {
            setupTheme(theme);
        }
    }, [autoSetup, theme]);

    return {
        currentTheme: theme ?? DefaultTheme,
        setupTheme,
    };
};

export default useTheme;
