import { Entity } from "../models";
import { ArticleSchema } from "../schemas";
import EntityModel from "./EntityModel";

class ArticleEntity extends EntityModel<ArticleSchema> {
    readonly title: string;
    readonly date: string;
    readonly image: string;
    readonly summary: string;
    readonly html: string;
    readonly tags: string[];
    readonly tagsIds: number[];
    readonly otherArticlesIds: number[];

    constructor(entity: Entity<ArticleSchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.date = entity.getFirstAttribute("date")?.getFirstValue()?.value || "";
        this.image = entity.getFirstAttribute("image")?.getFirstValue()?.display || "";
        this.summary = entity.getFirstAttribute("summary")?.getFirstValue()?.value || "";
        this.html = entity.getFirstAttribute("html")?.getFirstValue()?.value || "";
        this.tags = entity.getFirstAttribute("tags")?.values.map((item) => item.value) || [];
        this.tagsIds = entity.getFirstAttribute("tags")?.values.map((item) => item.entityId) || [];
        this.otherArticlesIds = entity.getFirstAttribute("other_articles")?.values.map((item) => item.entityId) || [];
    }
}

export default ArticleEntity;
