import { Exclude, Expose, Transform } from "class-transformer";

@Exclude()
class PaginatedQueryResult<DataType> {
    @Exclude()
    private type: () => void;

    @Expose() status: boolean;

    @Expose()
    @Transform(({ obj }) => {
        return obj.result?.count ?? 0;
    })
    count: number;

    @Expose()
    @Transform(({ obj }) => {
        return obj.result?.items ?? [];
    })
    items: DataType[];

    @Expose()
    @Transform(({ obj }) => {
        return obj.result?.total_pages ?? 0;
    })
    totalPages: number;

    @Expose() error: string;

    constructor(type: any) {
        this.type = type;
    }
}

export default PaginatedQueryResult;
