import { Exclude, Expose } from "class-transformer";

@Exclude()
class ValueTypeBind {
    @Expose() id: number;

    @Expose({ name: "type_id" }) typeId: string;

    @Expose({ name: "type_name" }) name: string;
}

export default ValueTypeBind;
