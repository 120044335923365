import { DefaultLogo } from "@/modules/theme";
import React, { PropsWithChildren } from "react";

export type AuthContentProps = PropsWithChildren<Partial<{ LogoComponent?: Custom.SvgIcon }>>;

const AuthContent: React.FunctionComponent<AuthContentProps> = ({
    LogoComponent = DefaultLogo,
    children,
}: AuthContentProps) => {
    return (
        <div className="mlogin__right">
            <div className="mlogin__content">
                <div className="mlogin__logo">
                    <LogoComponent width={164} height={164} />
                </div>
                <div className="mlogin-tabs">{children}</div>
            </div>
        </div>
    );
};

export default AuthContent;
