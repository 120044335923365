import React, { PropsWithChildren } from "react";

export type ItemProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Item: React.FC<ItemProps> = ({ children, ...other }: PropsWithChildren<ItemProps>) => {
    return (
        <div className="header-topmenu__item">
            <a className="header-topmenu__link" {...other}>
                {children}
            </a>
        </div>
    );
};

export default Item;
