import React, { PropsWithChildren } from "react";
import CommonView from "@/views/Common";
import Card from "./Card";
import Box from "./Box";

const Wrapper: React.FC = ({ children }: PropsWithChildren<unknown>) => {
    //return <CommonView.SectionContainer className="brands">{children}</CommonView.SectionContainer>;
    return <CommonView.SectionContainer className="technologies">{children}</CommonView.SectionContainer>;
};

export default Object.assign(Wrapper, { Card, Box });
