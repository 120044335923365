import React, { PropsWithChildren } from "react";

import Title from "./Title";
import Brand from "./Brand";
import Colors from "./Colors";
import Labels from "./Labels";
import Prices from "./Prices";
import Features from "./Features";
import Image from "./Image";
import Wrap from "./Wrap";
import Rating from "./Rating";
import Favorite from "./Favourite";

const Card: React.FC<unknown> = ({ children }: PropsWithChildren<unknown>) => {
    return <div className="catcard">{children}</div>;
};

export default Object.assign(Card, { Wrap, Image, Prices, Labels, Features, Colors, Title, Brand, Rating, Favorite });
