import React from "react";

export type PriceProps = Partial<{
    normal: string | number | null;
    old: string | number | null;
    sale: string | number | null;
}>;

const Price: React.FunctionComponent<PriceProps> = ({ normal, old, sale }: PriceProps) => {
    const formatPrice = (value: string | number | null) => {
        return value ? value.toLocaleString("ru-RU") : null; // Используем локаль "ru-RU" для разделения тысяч
    };
    return (
        <div className="product-prices">
            {normal ? (
                <div className="product-price">
                    {formatPrice(normal)} <span>₽</span>
                </div>
            ) : null}
            {!normal && sale ? (
                <div className="product-pricesale">
                    {formatPrice(sale)} <span>₽</span>
                </div>
            ) : null}
            {!normal && old ? (
                <div className="product-priceold">
                    {formatPrice(old)} <span>₽</span>
                </div>
            ) : null}

            {!normal && !sale ? <div className="product-price">Цена не указана</div> : null}
        </div>
    );
};

export default Price;
