import React, { PropsWithChildren } from "react";

export type TitleProps = PropsWithChildren<Partial<{ href: string }>>;

const Title: React.FC<TitleProps> = ({ children, href }: TitleProps) => {
    return (
        <a className="catcard__title" href={href}>
            {children}
        </a>
    );
};

export default Title;
