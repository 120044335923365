import * as React from "react";
import clsx from "clsx";
import { OverridableComponent, OverridableComponentProps } from "../types";
import { cleanHtml } from "@/modules/utils";

export type InnerHtmlProps = React.HTMLAttributes<HTMLElement> & Partial<{ html: string }>;

type InnerHtmlComponent = OverridableComponent<InnerHtmlProps>;

export type InnerHtmlComponentProps = OverridableComponentProps<InnerHtmlComponent>;

const InnerHtml: React.FC<InnerHtmlComponentProps> = ({
    component: Component = "div",
    children,
    className,
    style,
    html,
    ...other
}: InnerHtmlComponentProps) => {
    const classes = clsx("inner-html", { [className ?? ""]: !!className });

    return (
        <Component
            className={classes}
            style={style}
            dangerouslySetInnerHTML={{ __html: cleanHtml(html || "") }}
            {...other}
        >
            {children}
        </Component>
    );
};

export default InnerHtml;
