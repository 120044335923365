import Button, { ButtonProps } from "@/components/Core/Button";
import React from "react";

export type AddProps = Partial<Pick<ButtonProps, "onClick">>;

const Add: React.FunctionComponent<AddProps> = ({ onClick }: AddProps) => {
    return (
        <Button className="reviews__btn" disableTextTransform onClick={onClick}>
            Написать отзыв
        </Button>
    );
};

export default Add;
