import React from "react";
import clsx from "clsx";
import { OverridableComponent, OverridableComponentProps } from "../types";

export type TabPanelProps = React.HTMLAttributes<HTMLDivElement> &
    Partial<{
        id: string;
        /** also `true` if `activeTab` equal to `id` */
        active: boolean;
        activeTab: string;
        unmountOnExit: boolean;
    }>;

type TabPanelComponent = OverridableComponent<TabPanelProps>;

export type TabPanelComponentProps = OverridableComponentProps<TabPanelComponent>;

const TabPanel: React.FC<TabPanelComponentProps> = ({
    className,
    active = false,
    children,
    id,
    activeTab = "",
    unmountOnExit = false,
    ...other
}: TabPanelComponentProps) => {
    const show = active || activeTab === id;

    const classes = clsx("btabs__pane", {
        active: show,
        show,
        [className ?? ""]: !!className,
    });

    if (unmountOnExit && !show) return null;

    return (
        <div className={classes} role="tabpanel" hidden={show} {...other} data-tab={id}>
            {children}
        </div>
    );
};

export default TabPanel;
