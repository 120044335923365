import React, { PropsWithChildren } from "react";

type ItemProps = PropsWithChildren<Partial<{ href: string; type: "link" | "name" }>>;

const Item: React.FC<ItemProps> = ({ children, href, type = "link" }: ItemProps) => {
    return (
        <div className="popmenu-subcats__item">
            <a className={`popmenu-subcats__${type}`} href={href}>
                {children}
            </a>
        </div>
    );
};

export default Item;
