import { authApiService, userApiService } from "@/api/services";
import { LocalStrategyAuthDto } from "@/api/services/auth/dto";
import { Modal, Tab, TabPanel, Tabs, TabsContent, TabsGroup } from "@/components/Core";
import LoginForm, { LoginFormProps } from "@/components/Forms/LoginForm";
import RegisterForm, { RegisterFormProps } from "@/components/Forms/RegisterForm";
import useAuth from "@/modules/hooks/useAuth";
import useTabs from "@/modules/hooks/useTabs";
import { WithAppModalComponentProps } from "@/modules/modals";
import AuthView from "@/views/Auth";
import React from "react";
import { CreateUserDto } from "@/api/services/user/dto";
import useModal from "@/modules/hooks/useModal";
import { ThemeLogo } from "@/modules/theme";
import useTheme from "@/modules/hooks/useTheme";

const AuthModal: React.FunctionComponent<WithAppModalComponentProps> = ({ onClose }: WithAppModalComponentProps) => {
    const { activeTab, handleChangeTab } = useTabs<["login", "register"]>({ initialTab: "login" });
    const { authenticate } = useAuth();
    const { handleOpenModal, handleCloseModal } = useModal();
    const { currentTheme } = useTheme();

    const handleLogin: LoginFormProps["onSubmit"] = async (values, actions) => {
        try {
            const dto = authApiService.plainToClass(LocalStrategyAuthDto, values);
            const authResult = await authApiService.login(dto);
            authenticate(authResult);
            handleCloseModal("SIGNUP_AND_SIGNIN");
        } catch (err) {
            const { data } = err.response;
            actions.setErrors(data);
        }
    };

    const handleRegister: RegisterFormProps["onSubmit"] = async (values, actions) => {
        try {
            const dto = userApiService.plainToClass(CreateUserDto, values);
            await userApiService.register(dto);
            handleOpenModal("MESSAGE", {
                title: "Регистрация завершена",
                message: "Вы теперь можете войти в учетную запись",
            });
            handleCloseModal("SIGNUP_AND_SIGNIN");
        } catch (err) {
            const { data } = err.response;
            actions.setErrors(data);
        }
    };

    return (
        <Modal.Content>
            <Modal.Header onClose={onClose} />
            <AuthView>
                <AuthView.Cover />
                <AuthView.Content LogoComponent={ThemeLogo[currentTheme]}>
                    <Tabs fullWidth>
                        <TabsGroup>
                            <Tab id="login" activeTab={activeTab} onChange={handleChangeTab}>
                                Вход
                            </Tab>
                            <Tab id="register" activeTab={activeTab} onChange={handleChangeTab}>
                                Регистрация
                            </Tab>
                        </TabsGroup>
                        <TabsContent>
                            <TabPanel id="login" activeTab={activeTab}>
                                <LoginForm onSubmit={handleLogin} />
                            </TabPanel>
                            <TabPanel id="register" activeTab={activeTab}>
                                <RegisterForm onSubmit={handleRegister} />
                            </TabPanel>
                        </TabsContent>
                    </Tabs>
                </AuthView.Content>
            </AuthView>
        </Modal.Content>
    );
};

export default AuthModal;
