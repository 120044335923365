import { Exclude, Expose, Type } from "class-transformer";
import { ProductList } from "../../catalog/models/Product.model";

@Exclude()
class Favorite {
    @Expose() id: number;

    @Expose({ name: "product" })
    productId: number;

    @Type(() => ProductList)
    @Expose({ name: "product_obj" })
    product: ProductList;

    @Type(() => Date)
    @Expose({ name: "created_at" })
    createdAt: Date;

    @Type(() => Date)
    @Expose({ name: "updated_at" })
    updatedAt: Date;
}

export default Favorite;
