import clsx from "clsx";
import React, { memo } from "react";
import { OverridableComponent, OverridableComponentProps } from "../types";

export type DividerProps = React.HTMLAttributes<HTMLElement> & Partial<{}>;

type DividerComponent = OverridableComponent<DividerProps>;

export type DividerComponentProps = OverridableComponentProps<DividerComponent>;

const Divider: React.FC<DividerComponentProps> = ({
    component: Component = "hr",
    className,
    ...other
}: DividerComponentProps) => {
    const classes = clsx({
        branddetails__line: true,
        [className ?? ""]: !!className,
    });

    return <Component className={classes} {...other} />;
};

export default memo(Divider);
