import { Exclude, Expose } from "class-transformer";

@Exclude()
class Feedback {
    @Expose() name: string;

    @Expose() email: string;

    @Expose() message: string;
}

export default Feedback;
