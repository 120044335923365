import { FeedbackDto } from "@/api/services/common/dto";
import { commonApiService } from "@/api/services";
import Modal from "@/components/Core/Modal";
import FeedbackForm, { FeedbackFormProps } from "@/components/Forms/FeedbackForm";
import useModal from "@/modules/hooks/useModal";
import { WithAppModalComponentProps } from "@/modules/modals";
import React from "react";

const FeedbackModal: React.FunctionComponent<WithAppModalComponentProps> = ({
    onClose,
}: WithAppModalComponentProps) => {
    const { handleOpenModal } = useModal();

    const handleSendFeedback: FeedbackFormProps["onSubmit"] = async (values) => {
        try {
            const dto = commonApiService.plainToClass(FeedbackDto, values);
            await commonApiService.createFeedback(dto);
            handleOpenModal("MESSAGE", {
                title: "Спасибо за обращение",
                message: "Ваше сообщение успешно отправлено!",
            });
        } catch (err) {
            handleOpenModal("MESSAGE", {
                title: "Что-то пошло не так",
                message: "Упс, произошла ошибка при отправке сообщения",
            });
        }
        onClose();
    };

    return (
        <Modal.Content>
            <Modal.Header onClose={onClose} />
            <Modal.Body>
                <FeedbackForm onSubmit={handleSendFeedback} />
            </Modal.Body>
        </Modal.Content>
    );
};

export default FeedbackModal;
