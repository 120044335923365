import { ProductEntity } from "@/api/services/entities/entities";
import { ProductSchema } from "../../entities/schemas";
import { Entity } from "../../entities/models";
import { Exclude, Expose, Transform, Type } from "class-transformer";
import { Sku } from "@/api/services/catalog/models/Product.model";

export interface ICart {
    sku: number;
    count: number;
}

@Exclude()
export class ModifiedCartEntity extends Entity<ProductSchema> {
    @Expose()
    cart: ICart;
}

class CartProduct extends ProductEntity {
    cart: ICart;
    // Для исправление ошибки удалить!!!
    salePrice: number;
    oldPrice: number;

    constructor(modifiedCartEntity: ModifiedCartEntity) {
        super(modifiedCartEntity);

        this.cart = modifiedCartEntity.cart;
    }
}

@Exclude()
export class CartMiniProduct {
    @Expose() id: number;
    @Expose() image: string[];
    @Expose() slug: string;
    @Expose() title: string;
}

@Exclude()
export class CartProductSKU {
    @Expose() id: number;
    @Expose() count: number;

    @Type(() => Sku)
    @Expose({ name: "sku_obj" })
    sku: Sku;

    @Type(() => CartMiniProduct)
    @Expose({ name: "product_obj" })
    @Transform(({ obj }) => obj.sku_obj.product_obj)
    product: CartMiniProduct;
}

export default CartProduct;
