import React, { PropsWithChildren } from "react";

import Container from "./Container";
import IconButton from "./IconButton";
import Logo from "./Logo";
import Catalog from "./Catalog";
import Row from "./Row";
import Col from "./Col";
import Search from "./Search";
import Menu from "./Menu";

export type MiddleProps = PropsWithChildren<unknown>;

const Middle: React.FC<MiddleProps> = ({ children }: MiddleProps) => {
    return (
        <div className="header-middle">
            <div className="header-middlewrap">{children}</div>
        </div>
    );
};

export default Object.assign(Middle, { Container, IconButton, Logo, Row, Catalog, Col, Search, Menu });
