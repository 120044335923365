import React, { PropsWithChildren } from "react";
import clsx from "clsx";

import Top from "./Top";
import Cols from "./Cols";
import Col from "./Col";
import Subcats from "./Subcats";
import Item from "./Item";
import ShowAll from "./ShowAll";
import Banner from "./Banner";
import Brands from "./Brands";

const Menu: React.FC<{ active: boolean }> = ({ active = false, children }: PropsWithChildren<{ active: boolean }>) => {
    const classes = clsx("popmenu-catmenu", {
        "popmenu-catmenu--active": active,
    });
    return <div className={classes}>{children}</div>;
};

export default Object.assign(Menu, { Top, Cols, Col, Item, Subcats, ShowAll, Banner, Brands });
