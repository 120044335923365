import useModal from "@/modules/hooks/useModal";

import { useCallback } from "react";

const useFooterControls = () => {
    const { handleOpenModal } = useModal();

    const handleFeedbackClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            handleOpenModal("FEEDBACK");
        },
        [handleOpenModal]
    );

    return {
        handleFeedbackClick,
    };
};

export default useFooterControls;
