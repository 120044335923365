import React, { PropsWithChildren } from "react";

type ItemProps = PropsWithChildren<Partial<{ href: string; label: string }>>;

const Item: React.FC<ItemProps> = ({ href, label, children }: ItemProps) => {
    const Component: keyof React.ReactHTML = href ? "a" : "div";

    return (
        <div className="footer-conts__item">
            <div className="footer-conts__icon" />
            <div className="footer-conts__content">
                <Component className="footer-conts__value" href={href}>
                    {children}
                </Component>
                <div className="footer-conts__label">{label}</div>
            </div>
        </div>
    );
};

export default Item;
