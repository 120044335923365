import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from "axios";

type Http = AxiosInstance;
export type HttpOptions = AxiosRequestConfig;
export type HttpResponse = AxiosResponse;
export type HttpError = AxiosError;

const Http = (config: HttpOptions = {}) => axios.create(config);

export default Http;
