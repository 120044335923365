import React, { PropsWithChildren } from "react";

import Link from "./Link";
import Items from "./Items";

const Social: React.FC<unknown> = ({ children }: PropsWithChildren<unknown>) => {
    return <div className="footer-social">{children}</div>;
};

export default Object.assign(Social, { Link, Items });
