import React, { PropsWithChildren } from "react";
import Table from "./Table";
import WebTable from "./WebTable";

export type WrapperProps = PropsWithChildren<{}>;

const Wrapper: React.FunctionComponent<WrapperProps> = ({ children }: WrapperProps) => {
    return <div className="product-availability__wrap">{children}</div>;
};

export default Object.assign(Wrapper, { Table, WebTable });
