import clsx from "clsx";
import React from "react";
import { CommonComponentProps } from "../types";
import Rate, { RateProps } from "rc-rate/lib/Rate";
import "rc-rate/assets/index.css";

export type RatingProps = RateProps & Partial<{ size: "sm" | "md" }>;

const Rating: React.FunctionComponent<CommonComponentProps<RatingProps>> = ({
    className,
    style,
    size = "md",
    ...other
}: CommonComponentProps<RatingProps>) => {
    const classes = clsx({
        "size--md": size === "md",
        "size--sm": size === "sm",
        [className ?? ""]: !!className,
    });

    return <Rate className={classes} style={style} count={5} {...other} />;
};

export default Rating;
