import useAuth from "@/modules/hooks/useAuth";
import useModal from "@/modules/hooks/useModal";
import usePage from "@/modules/hooks/usePage";
import { createRef, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

type UseHeaderControlsProps = Partial<{ isMessageShow: boolean }>;

const useHeaderControls = ({ isMessageShow = false }: UseHeaderControlsProps = {}) => {
    const { user } = useAuth();
    const { page } = usePage();

    const history = useHistory();

    const [fixed, setFixed] = useState<boolean>(false);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [catalogOpen, setCatalogOpen] = useState<boolean>(false);
    const [messageShow, setMessageShow] = useState<boolean>(isMessageShow);

    const { handleOpenModal } = useModal();

    const headerTopRef = createRef<HTMLDivElement>();

    const onScroll = () => {
        const scroll = window.pageYOffset;
        const wtop = headerTopRef.current?.clientHeight || 0;

        if (scroll >= wtop) {
            setFixed(true);
        } else {
            setFixed(false);
        }
    };

    useEffect(() => {
        if (headerTopRef.current) window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [headerTopRef, onScroll]);

    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add("ovhm");
        } else {
            document.body.classList.remove("ovhm");
        }
    }, [menuOpen]);

    useEffect(() => {
        if (catalogOpen) {
            document.body.classList.add("ovh");
        } else {
            document.body.classList.remove("ovh");
        }
    }, [catalogOpen]);

    const handleHideMessage = useCallback(() => {
        setMessageShow(false);
    }, []);

    const handleToggleMenu = useCallback(() => {
        setMenuOpen((prev) => !prev);
    }, []);

    const handleToggleCatalog = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setCatalogOpen((prev) => !prev);
    }, []);

    const handleSignInClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            if (page && user) {
                history.push(page.config.pages.personalUrl);
            } else {
                handleOpenModal("SIGNUP_AND_SIGNIN");
            }
        },
        [handleOpenModal, user, page, history]
    );

    return {
        fixed,
        headerTopRef,
        menuOpen,
        catalogOpen,
        messageShow,
        handleHideMessage,
        handleToggleMenu,
        handleToggleCatalog,
        handleSignInClick,
    };
};

export default useHeaderControls;
