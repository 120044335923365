import React, { PropsWithChildren } from "react";

const ContentWrapper: React.FC<unknown> = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <div className="footer-top">
            <div className="container">{children}</div>
        </div>
    );
};

export default ContentWrapper;
