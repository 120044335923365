import React from "react";

import Title from "./Title";

export type WebTableProps = Partial<{
    skus: any[];
    title: string;
    colorMask: { [key: string]: string };
}>;

const WebTable: React.FunctionComponent<WebTableProps> = ({ skus, title, colorMask }: WebTableProps) => {
    return (
        <div className="product-web_availability__container">
            <div className="product-web_availability__title">{title}</div>
            {skus?.map((sku) => (
                <div key={sku.sku}>
                    <div className="product-web_availability__wrapper">
                        <div className="product-web_availability__info product-web_availability__block">
                            <div className="product-web_availability__info-title">{sku.sku}</div>
                            <div className="product-web_availability__sale_attrs">
                                {sku.saleAttrs?.map((attr) =>
                                    attr === "color" ? (
                                        <div key={sku.sku + attr} className={"product-web_availability__color_wrap"}>
                                            <div
                                                className={
                                                    "product-web_availability__color product-web_availability__cell"
                                                }
                                                style={{ background: colorMask && colorMask[sku[attr]] }}
                                            />
                                            <div className={"product-web_availability__cell"}>{sku[attr]}</div>
                                        </div>
                                    ) : (
                                        <div key={sku.sku + attr} className={"product-web_availability__cell"}>
                                            {sku[attr]}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                        <div className="product-web_availability__quantity product-web_availability__block">
                            {sku.quantity}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Object.assign(WebTable, { Title });
