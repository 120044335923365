import FormGroup from "@/components/Core/FormGroup";
import FormLabel from "@/components/Core/FormLabel";
import Input from "@/components/Core/Input";
import { Formik, FormikProps, Form, FormikConfig } from "formik";
import * as Yup from "yup";
import React, { forwardRef } from "react";
import Textarea from "@/components/Core/Input/Textarea";
import Button from "@/components/Core/Button";
import Checkbox from "@/components/Core/Checkbox";
import { FormHelperText } from "@/components/Core";
import { Regex } from "@/modules/constants";

interface Feedback {
    name: string;
    email: string;
    message: string;
    pdp: boolean;
}

const FeedbackSchema = Yup.object().shape({
    name: Yup.string()
        .required("Пожалуйста, укажите ваше имя")
        .min(2, "Введенное значение слишком короткое")
        .max(25, "Превышено макс. кол-во символов")
        .matches(Regex.ONLY_LETTERS_WITH_UNDERLINE, 'Запрещены все спецсимволы, кроме "-"'),
    email: Yup.string()
        .email("Пожалуйста, введите корректный адрес эл.почты")
        .required("Пожалуйста, укажите вашу эл. почту"),
    message: Yup.string().required("Пожалуйста, оставьте ваше сообщение"),
    pdp: Yup.boolean().required().oneOf([true], "Вы должны согласиться с условиями!"),
});

export type FeedbackFormProps = Pick<FormikConfig<Feedback>, "onSubmit">;

const FeedbackForm = forwardRef<FormikProps<Feedback>, FeedbackFormProps>(({ onSubmit }: FeedbackFormProps, ref) => {
    return (
        <Formik<Feedback>
            initialValues={{
                name: "",
                email: "",
                message: "",
                pdp: false,
            }}
            innerRef={ref}
            validationSchema={FeedbackSchema}
            onSubmit={onSubmit}
            validateOnMount
        >
            {({ touched, errors, handleChange, values, setFieldValue, isSubmitting }) => {
                return (
                    <Form className="contacts-form" noValidate>
                        <h2 className="contacts-heading">Обратная связь</h2>

                        <FormGroup error={touched.name && !!errors.name}>
                            <FormLabel required>Имя</FormLabel>
                            <Input
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                placeholder="Введите свое имя"
                            />
                            <FormHelperText variant="error">{touched.name && errors.name}</FormHelperText>
                        </FormGroup>
                        <FormGroup error={touched.email && !!errors.email}>
                            <FormLabel required>Электронная почта</FormLabel>
                            <Input
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                placeholder="Введите свою электронную почту"
                            />
                            <FormHelperText variant="error">{touched.email && errors.email}</FormHelperText>
                        </FormGroup>

                        <FormGroup error={touched.message && !!errors.message}>
                            <FormLabel required>Сообщение</FormLabel>
                            <Textarea
                                required
                                name="message"
                                value={values.message}
                                placeholder="Введите свое сообщение"
                                onChange={handleChange}
                            />
                            <FormHelperText variant="error">{touched.message && errors.message}</FormHelperText>
                        </FormGroup>

                        <FormGroup error={touched.pdp && !!errors.pdp}>
                            <Checkbox
                                checkboxSize="sm"
                                id="pdp"
                                name="pdp"
                                checked={values.pdp}
                                onChange={(e) => setFieldValue(e.target.name, e.target.checked)}
                            >
                                Даю согласие на обработку <a href="!#">персональных данных</a>
                            </Checkbox>
                            <FormHelperText variant="error">{touched.pdp && errors.pdp}</FormHelperText>
                        </FormGroup>

                        <FormGroup>
                            <Button type="submit" disabled={isSubmitting}>
                                {isSubmitting ? "Отправляем..." : "Отправить"}
                            </Button>
                        </FormGroup>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default FeedbackForm;
