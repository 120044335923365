class Locator<Key extends string, Type> {
    protected locator: Map<Key, Type> = new Map();

    public register = (key: Key, payload: Type) => {
        this.locator.set(key, payload);
    };

    public get = (key: Key) => {
        return this.locator.get(key);
    };

    public getAll = () => {
        return [...this.locator.values()];
    };
}

export default Locator;
