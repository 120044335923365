import clsx from "clsx";
import React from "react";
import { OverridableComponentProps, OverridableComponent } from "../types";

type FormHelperTextProps = Partial<{ variant: "default" | "success" | "error" }>;

type FormHelperTextComponent = OverridableComponent<FormHelperTextProps>;

export type FormHelperTextComponentProps = OverridableComponentProps<FormHelperTextComponent>;

const FormHelperText: React.FunctionComponent<FormHelperTextComponentProps> = ({
    component: Component = "span",
    children,
    variant = "default",
    className,
    ...other
}: FormHelperTextComponentProps) => {
    const classes = clsx("form-help-text", {
        "form-help-text--success": variant === "success",
        "form-help-text--error": variant === "error",
        [className ?? ""]: !!className,
    });

    return (
        <Component className={classes} {...other}>
            {children}
        </Component>
    );
};

export default FormHelperText;
