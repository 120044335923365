import React, { useEffect } from "react";
import { EntityFetcher } from "@/components/Controls";
import ProductCategoryView from "@/views/ProductCategory";
import { BrandEntity, ColorEntity } from "@/api/services/entities/entities";
import { BrandSchema, ColorSchema } from "@/api/services/entities/schemas";
import { useFavourite } from "@/modules/hooks";
import { ProductList, SkuGroup } from "@/api/services/catalog/models/Product.model";
import { plainToClass } from "class-transformer";

export type ProductCardProps = {
    product: ProductList;
    skus?: SkuGroup[]; // Передаем список skus как отдельный пропс
};

const ProductCard: React.FunctionComponent<ProductCardProps> = ({ product, skus }: ProductCardProps) => {
    const { isFavorite } = useFavourite();
    const isFav = isFavorite(product.id);

    const colorIds = product?.colors?.map((color) => color?.id) || [];
    const brandId = [String(product.attr.brand?.id)];

    // console.log("Color IDs:", colorIds);
    // console.log("Brand ID:", brandId);
    // console.log("SKU List:", skus);

    const getAvailableColorsWithSkus = (skus: SkuGroup[]) => {
        // Проверяем, передан ли список SKU
        if (!skus || skus.length === 0) return [];

        // Фильтруем SKU с количеством > 0 и наличием цвета
        const availableColorsWithSkus = skus
            .filter((sku) => sku.quantity > 0 && sku.attr.color) // Убедитесь, что есть количество и цвет
            .map((sku) => ({ color: sku.attr.color!, sku })) // Создаём массив объектов с цветами и SKU
            .filter((item, index, self) => self.findIndex((c) => c.color.id === item.color.id) === index); // Убираем дубликаты по цвету

        // Если есть SKU с цветами, возвращаем их, иначе возвращаем первый SKU без цвета
        // return availableColorsWithSkus;
        return availableColorsWithSkus.length > 0
            ? availableColorsWithSkus
            : [{ sku: skus.find((sku) => sku.quantity > 0) }]; // Возвращаем первый SKU с количеством > 0
    };

    // Преобразуем данные в экземпляры класса SkuGroup
    const transformedSkus = skus?.map((sku) => plainToClass(SkuGroup, sku));

    // Теперь у вас будет корректно работать доступ к `salePrice` и `rrcPrice`
    const availableColorsWithSkus = getAvailableColorsWithSkus(transformedSkus || []);

    // Получаем доступные цвета
    const firstAvailableSkuImage = availableColorsWithSkus[0]?.sku?.attr?.images?.display?.[0] ?? "";

    const firstAvailableSkuPrice =
        availableColorsWithSkus[0]?.sku?.salePrice ?? availableColorsWithSkus[0]?.sku?.rrcPrice ?? "Цена не указана";

    return (
        <ProductCategoryView.Card>
            <ProductCategoryView.Card.Wrap href={product.slug} type="top">
                <ProductCategoryView.Card.Image
                    src={firstAvailableSkuImage ? firstAvailableSkuImage : product.image ? product.image[0] : ""}
                    alt={product.title}
                />
                <ProductCategoryView.Card.Labels>
                    {product.attr.labels?.value.map((label, index) => {
                        return (
                            <ProductCategoryView.Card.Labels.Label key={product.attr.labels?.id[index]}>
                                {label}
                            </ProductCategoryView.Card.Labels.Label>
                        );
                    })}
                </ProductCategoryView.Card.Labels>
                <EntityFetcher<ColorSchema, ColorEntity>
                    Model={ColorEntity}
                    queryKey="colors"
                    idsList={
                        availableColorsWithSkus.length > 0
                            ? availableColorsWithSkus
                                  .map(({ sku }) => (sku ? String(sku.attr.color?.id) : null)) // Проверяем наличие sku и color
                                  .filter((id): id is string => id !== null) // Удаляем все null значения и оставляем только строки
                            : []
                    }
                >
                    {({ items }) => {
                        return <ProductCategoryView.Card.Colors colors={items.map((item) => item.hex)} />;
                    }}
                </EntityFetcher>
                {isFav ? <ProductCategoryView.Card.Favorite /> : null}
            </ProductCategoryView.Card.Wrap>
            <ProductCategoryView.Card.Wrap type="bot">
                <ProductCategoryView.Card.Features>
                    {product.attr.marks?.value.map((mark, index) => (
                        <ProductCategoryView.Card.Features.Feature key={product.attr.marks?.id[index]}>
                            {mark}
                        </ProductCategoryView.Card.Features.Feature>
                    ))}
                </ProductCategoryView.Card.Features>
                <ProductCategoryView.Card.Prices
                    oldPrice={availableColorsWithSkus[0]?.sku?.rrcPrice}
                    price={availableColorsWithSkus[0]?.sku?.salePrice}
                />
                <EntityFetcher<BrandSchema, BrandEntity>
                    Model={BrandEntity}
                    queryKey="brand"
                    idsList={[String(product.attr.brand?.id)]}
                >
                    {({ items }) => (
                        <>
                            {items.map((brand) => {
                                return (
                                    <ProductCategoryView.Card.Brand
                                        key={brand.id}
                                        src={brand.image}
                                        alt={brand.title}
                                        brandTitle={brand.title} // добавляем сюда название бренда
                                    />
                                );
                            })}
                        </>
                    )}
                </EntityFetcher>
                {product.attr.titlePrefix && (
                    <span className="catcard__titlePrefix">{product.attr.titlePrefix.value}</span>
                )}
                <ProductCategoryView.Card.Title href={product.slug}>{product.title}</ProductCategoryView.Card.Title>
                <ProductCategoryView.Card.Rating count={product?.review?.count ?? 0} avg={product?.review?.avg ?? 0} />
            </ProductCategoryView.Card.Wrap>
        </ProductCategoryView.Card>
    );
};

export default ProductCard;
