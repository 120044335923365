import { Vk } from "@/icons/Vk";
import { Youtube } from "@/icons/Youtube";
import clsx from "clsx";
import React, { PropsWithChildren } from "react";

type IconType = "vk" | "in" | "fb" | "yt";

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
    Partial<{ href: string; type: IconType; variant: "light" | "dark" }>;

const Icons = {
    vk: Vk,
    yt: Youtube,
};

const Link: React.FC<LinkProps> = ({ type, variant = "light", ...other }: PropsWithChildren<LinkProps>) => {
    const IconComponent = type ? Icons[type] : null;

    return (
        <a
            className={clsx(
                `footer-social__item--${type}`,
                { ["footer-social__item "]: variant === "light" },
                { ["contacts_social__item"]: variant === "dark" }
            )}
            target="_blank"
            rel="noreferrer"
            {...other}
        >
            <IconComponent />
        </a>
    );
};

export default Link;
