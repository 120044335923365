import React from "react";
import { Rating as CoreRating } from "@/components/Core";

export type RatingProps = Partial<{ count: number; avg: number }>;

const Rating: React.FunctionComponent<RatingProps> = ({ count, avg }: RatingProps) => {
    return (
        <div className="catcard__rating">
            <div className="catrating">
                <div className="catrating__stars">
                    <CoreRating value={avg} count={5} disabled size="sm" />
                </div>
                <div className="catrating__count">{count}</div>
            </div>
        </div>
    );
};

export default Rating;
