import React, { PropsWithChildren } from "react";
import { Button as CoreButton } from "@/components/Core";
import { ButtonProps as CoreButtonProps } from "@/components/Core/Button";

const Button: React.FC<CoreButtonProps> = ({ children, ...other }: PropsWithChildren<CoreButtonProps>) => {
    return (
        <CoreButton className="footer-formbtn" {...other}>
            {children}
        </CoreButton>
    );
};

export default Button;
