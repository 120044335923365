import { Entity } from "../models";

abstract class EntityModel<Schema> {
    id: number;
    etypeId: number;
    type: string;
    attrs: Entity["attrs"];

    constructor(entity: Entity<Schema>) {
        this.id = entity.id;
        this.etypeId = entity.etypeId;
        this.type = entity.type;
        this.attrs = entity.attrs;
    }
}

export default EntityModel;
