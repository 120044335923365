import { DefaultLogo } from "@/modules/theme";
import React from "react";

export type LogoProps = Partial<{ LogoComponent?: Custom.SvgIcon; href: string }>;

const Logo: React.FunctionComponent<LogoProps> = ({ LogoComponent = DefaultLogo, href = "/" }: LogoProps) => {
    return (
        <a className="header-logo" href={href}>
            <LogoComponent />
        </a>
    );
};

export default Logo;
