import React from "react";
import clsx from "clsx";
import { Button, FormHelperText } from "@/components/Core";

export type ActionsProps = Partial<{
    onAddToCart(): void;
    onAddToFavorite(): void;
    onAddUnit(): void;
    onRemoveUnit(): void;
    qtyInCart: number;
    inFavourite: boolean;
    canAddToCart: boolean;
    canAddUnit: boolean;
    error?: string;
}>;

const Actions: React.FunctionComponent<ActionsProps> = ({
    onAddToCart,
    onAddUnit,
    onRemoveUnit,
    onAddToFavorite,
    qtyInCart,
    inFavourite,
    canAddToCart = false,
    canAddUnit = false,
    error,
}: ActionsProps) => {
    return (
        <div className="product-buyfav">
            <div className="product-buy">
                {error ? <FormHelperText variant="error">{error}</FormHelperText> : null}
                {!qtyInCart && (
                    <Button disabled={!canAddToCart} className="product-buybtn" onClick={onAddToCart}>
                        {canAddToCart ? "добавить в корзину" : "нет в наличии"}
                    </Button>
                )}
                {qtyInCart ? (
                    <div className="product-cartbtn">
                        <div className="product-cartbtn__minus" onClick={onRemoveUnit} />
                        <div className="product-cartbtn__count">в корзине: {qtyInCart}</div>
                        {canAddUnit && <div className="product-cartbtn__plus" onClick={onAddUnit} />}
                    </div>
                ) : null}
            </div>

            {/*active*/}
            <button
                type="button"
                className={clsx({
                    "product-fav": true,
                    ["active"]: inFavourite,
                })}
                onClick={onAddToFavorite}
            />
        </div>
    );
};

export default Actions;
