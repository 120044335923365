import React from "react";
import clsx from "clsx";
import { CommonComponentProps } from "../../types";

export type TextareaProps = React.InputHTMLAttributes<HTMLTextAreaElement>;

const Textarea: React.FC<CommonComponentProps<TextareaProps>> = ({
    className,
    style,
    ...other
}: CommonComponentProps<TextareaProps>) => {
    const classes = clsx("form-textarea", {
        [className ?? ""]: !!className,
    });

    return <textarea className={classes} style={style} {...other} />;
};

export default Textarea;
