import React from "react";
import { Helmet } from "react-helmet-async";
import Layout from "@/components/Common/Layout";
import { WithPageProps } from "@/modules/pages";
import asyncComponent from "@/modules/hoc/AsyncComponent";

const Component = asyncComponent(() => {
    return import("@/components/Catalog");
});

const CatalogPage: React.FunctionComponent<WithPageProps> = (props: WithPageProps) => {
    const context = props.context;
    const StructuredDataModel = {
        "@context": "https://schema.org/",
        "@type": "ItemList",
        name: context?.title,
        description: context?.attr?.description?.value || "",
    };
    return (
        <Layout wrap {...context}>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(StructuredDataModel)}</script>
            </Helmet>
            <Component title={props.context.title} id={context.id} />
        </Layout>
    );
};

export default CatalogPage;
