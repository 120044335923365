import { Button } from "@/components/Core";
import React from "react";

export type MenuProps = Partial<{ onToggleMenu(e: React.MouseEvent<HTMLElement>): void }>;

const Menu: React.FunctionComponent<MenuProps> = ({ onToggleMenu }: MenuProps) => {
    return (
        <Button className="header-infobtn" onClick={onToggleMenu}>
            <i />
            <span>Меню</span>
        </Button>
    );
};

export default Menu;
